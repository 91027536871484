import React, { useEffect, useRef, useState } from "react";
import { useParams } from "react-router-dom";
import Avatar from "../../assets/images/Dropdown.png";
import TopCard from "../../components/card/TopCard";
import TopMiniCard from "../../components/card/TopMiniCard";
import CustomTab from "../../components/tabs/CustomTab";
import CustomTable from "../../components/table/CustomTable";
import PaginationTab from "../../components/pagination/PaginationTab";
import InputSearch from "../../components/input/InputSearch";
import useFieldOfficer from "../../hooks/useFieldOfficer";
import useBackLink from "../../hooks/useBackLink";
import Backlink from "../../components/link/Backlink";
import Modal from "../../components/Modal";
import FieldOfficerModal from "../../components/modals/FieldOfficerModal";
import useOutsideClick from "../../hooks/useOutsideClick";
import useRetailer from "../../hooks/useRetailer";
import { CircularProgressbarWithChildren } from "react-circular-progressbar";
import useHousehold from "../../hooks/useHousehold";
import { toMoney } from "../../utils/formatCurrency";

const householdHeaders = [
  "Household",
  "Retailer",
  // "Field Officer",
  "Phone Number",
  "State",
  "Lga",
  "Date & Time",
];
const retailersHeaders = [
  // "Household",
  "Retailer",
  // "Field Officer",
  "Phone Number",
  "State",
  "Lga",
  "Date & Time",
];
const FieldOfficerDetail = () => {
  const { id } = useParams();
  const wrapperRef = useRef(null);
  const [showProfile, setShowProfile] = useOutsideClick(wrapperRef);
  const [foDetail, setFoDetail] = useState(1);
  const [retailerPage, setRetailerPage] = useState(1);
  const [householdPage, setHouseholdPage] = useState(1);
  const [retailersManaging, setRetailersManaging] = useState();
  const [householdsManaging, setHouseholdsManaging] = useState();
  const [retailersManagingTotalPage, setRetailersManagingTotalPage] =
    useState();
  const [householdsManagingTotalPage, setHouseholdsManagingTotalPage] =
    useState();
  const [fieldOfficerMetric, setFieldOfficerMetric] = useState();
  const { loading, getFoDetails, getAllfieldOfficerMetric } = useFieldOfficer();
  const { gatAllRetailers } = useRetailer();
  const { gatAllHouseHolds } = useHousehold();
  const { linkList, getPathList } = useBackLink();

  const getFoDetail = async () => {
    const res = await getFoDetails(id);
    setFoDetail(res);
  };
  const getRetailersManaging = async () => {
    const res = await gatAllRetailers({
      page: retailerPage,
      size: 10,
      fieldOfficerId: id,
    });
    setRetailersManaging(res);
    setRetailersManagingTotalPage(res?.data?.totalPages);
  };
  const getHouseholdManaging = async () => {
    const res = await gatAllHouseHolds({
      page: householdPage,
      size: 10,
      fieldOfficerId: id,
    });
    setHouseholdsManaging(res);
    setHouseholdsManagingTotalPage(res?.data?.totalPages);
  };

  const getfieldOfficerMetric = async () => {
    const res = await getAllfieldOfficerMetric(id);
    setFieldOfficerMetric(res);
  };

  const handleRetailerTabPageChange = (data) => {
    if (data === 0) {
      setRetailerPage(1);
    }
    setRetailerPage(data + 1);
  };
  const handleHouseholdTabPageChange = (data) => {
    if (data === 0) {
      setHouseholdPage(1);
    }
    setHouseholdPage(data + 1);
  };

  useEffect(() => {
    getFoDetail();
    getRetailersManaging();
    getHouseholdManaging();
    getfieldOfficerMetric();
  }, [
    id,
    retailerPage,
    householdPage,
    getRetailersManaging,
    getHouseholdManaging,
  ]);

  useEffect(() => {
    getPathList(window.location.pathname);
  }, []);

  return (
    <div className="p-[24px] flex gap-[24px]">
      <div className=" p-[16px] border border-gray-300 w-[240px] rounded-md">
        <div className="flex justify-center w-full mb-[24px]">
          <div className="h-[120px] w-[120px] rounded-full">
            <img
              src={foDetail?.data?.image}
              alt="profile"
              style={{ width: "100%", height: "100%", objectFit: "contain" }}
            />
          </div>
        </div>
        <div className="mb-[24px] border border-gray-300">
          <button
            className="h-[40px] flex items-center justify-center w-full p-[12px] rounded-[8px]"
            onClick={() => setShowProfile(true)}
          >
            Edit
          </button>
        </div>
        <div className="flex flex-col gap-[24px]">
          <div>
            <div className="mb-[16px]">
              <p className="capitalize bg-[#FFFAEB] px-[16px] py-[6px]">
                basic details
              </p>
            </div>
            <div>
              <div className="px-[16px] mb-[12px]">
                <p className="text-[12px]">First Name</p>
                <p className="text-[14px] font-bold">
                  {foDetail?.data?.firstName}
                </p>
              </div>
              <div className="px-[16px]">
                <p className="text-[12px]">Last Name</p>
                <p className="text-[14px] font-bold">
                  {foDetail?.data?.lastName}
                </p>
              </div>
            </div>
          </div>
          <div>
            <div className="mb-[16px]">
              <p className="capitalize bg-[#FFFAEB] px-[16px] py-[6px]">
                Contact details
              </p>
            </div>
            <div>
              <div className="px-[16px] mb-[12px]">
                <p className="text-[12px]">Email Address</p>
                <p className="text-[14px] font-bold">{foDetail?.data?.email}</p>
              </div>
              <div className="px-[16px] mb-[12px]">
                <p className="text-[12px]">Phone Number</p>
                <p className="text-[14px] font-bold">
                  {foDetail?.data?.phoneNumber}
                </p>
              </div>
            </div>
          </div>
          <div>
            <div className="mb-[16px]">
              <p className="capitalize bg-[#FFFAEB] px-[16px] py-[6px]">
                Location details
              </p>
            </div>
            <div>
              <div className="px-[16px] mb-[12px]">
                <p className="text-[12px]">State</p>
                <p className="text-[14px] font-bold">
                  {foDetail?.data?.state ? foDetail?.data?.state : "N/A"}
                </p>
              </div>
              <div className="px-[16px] mb-[12px]">
                <p className="text-[12px]">Lga's</p>
                {foDetail?.data?.localGovernments?.map((data, index) => {
                  return (
                    <p className="text-[14px] font-bold" key={index}>
                      {data?.name}
                    </p>
                  );
                })}
              </div>
            </div>
            <div className="px-[16px] mb-[12px]">
              <p className="text-[12px]">Date</p>
              <p className="text-[14px] font-bold">
                {foDetail?.data?.createdAt?.split(" ")[0]} |{" "}
                {foDetail?.data?.createdAt?.split(" ")[1]}
              </p>
            </div>
          </div>
        </div>
      </div>
      <div className="flex flex-col gap-[24px] w-[calc(100%-240px)]">
        <div className="flex gap-[16px]">
          <div className="basis-[50%] flex flex-wrap w-[449.5px] gap-[16px]">
            <TopMiniCard
              css={"basis-[48%] bg-[#EFF8FF]"}
              title={"Total sales value"}
              subtitle={`₦${toMoney(
                fieldOfficerMetric?.data?.totalSalesValue || 0
              )}`}
              subtlet={`Last order: ${
                fieldOfficerMetric?.data?.lastSalesDate
                  ? fieldOfficerMetric?.data?.lastSalesDate
                  : "N/A"
              }`}
              textColor={"text-[#2E90FA]"}
            />
            <TopMiniCard
              css={"basis-[48%] bg-[#F9FAFB]"}
              title={"Total Lga's"}
              subtitle={fieldOfficerMetric?.data?.noOfLga || 0}
              // subtlet={"LGA's"}
            />
            <TopMiniCard
              css={"basis-[48%] bg-[#F4F3FF]"}
              title={"Total Retailers"}
              subtitle={fieldOfficerMetric?.data?.noOfRetailer || 0}
              // subtlet={"Retailers"}
            />
            <TopMiniCard
              css={"basis-[48%] bg-[#FFFAEB]"}
              title={"Total Households"}
              subtitle={fieldOfficerMetric?.data?.noOfHouseHold || 0}
              // subtlet={"Households"}
            />
          </div>
          <div className="basis-[50%] flex flex-col gap-[16px]">
            <div className="px-[16px] py-[14px] flex justify-between items-center gap-[16px] border border-gray-300 rounded-[8px] h-[114px] basis-[50%]">
              <div>
                <p className="mb-[10px]">Product with the highest sales</p>
                <p className="flex gap-[15px] items-center text-sm">
                  product:{" "}
                  <span className="font-bold text-sm">
                    {fieldOfficerMetric?.data?.highestSalesProduct
                      ? fieldOfficerMetric?.data?.highestSalesProduct
                      : "N/A"}
                  </span>
                </p>
                <p className="flex gap-[15px] items-center text-sm">
                  Total value:{" "}
                  <span className="font-bold text-sm">
                    {`₦${toMoney(
                      fieldOfficerMetric?.data?.highestSalesProductValue || 0
                    )}`}
                  </span>
                </p>
              </div>
              <div className="w-[90px] h-[90px]">
                <CircularProgressbarWithChildren
                  value={80}
                  strokeWidth={17}
                  styles={{
                    path: {
                      // Path color
                      stroke: `#7F56D9`,
                      // Whether to use rounded or flat corners on the ends - can use 'butt' or 'round'
                      strokeLinecap: "butt",
                      // // Customize transition animation
                      // transition: "stroke-dashoffset 0.5s ease 0s",
                      // // Rotate the path
                      // transform: "rotate(0.25turn)",
                      // transformOrigin: "center center",
                    },
                  }}
                >
                  <div className="flex flex-col justify-center">
                    <p className="text-center">Sales</p>
                    <p className="text-center font-bold">
                      {fieldOfficerMetric?.data?.highestSalesProductValue || 0}
                    </p>
                  </div>
                </CircularProgressbarWithChildren>
              </div>
            </div>
            <div className="px-[16px] py-[18px] flex justify-between items-center gap-[16px border border-gray-300 rounded-[8px] h-[114px] basis-[50%]">
              <div>
                <p className="mb-[10px]">Product with the lowest sales</p>
                <p className="flex gap-[15px] items-center text-sm">
                  product:{" "}
                  <span className="font-bold text-sm">
                    {fieldOfficerMetric?.data?.lowestSalesProduct
                      ? fieldOfficerMetric?.data?.lowestSalesProduct
                      : "N/A"}
                  </span>
                </p>
                <p className="flex gap-[15px] items-center text-sm">
                  Total value:{" "}
                  <span className="font-bold text-sm">
                    {`₦${toMoney(
                      fieldOfficerMetric?.data?.lowestSalesProductValue || 0
                    )}`}
                  </span>
                </p>
              </div>
              <div className="w-[90px] h-[90px]">
                <CircularProgressbarWithChildren
                  value={10}
                  strokeWidth={17}
                  counterClockwise={true}
                  styles={{
                    path: {
                      // Path color
                      stroke: `#F79009`,
                      // Whether to use rounded or flat corners on the ends - can use 'butt' or 'round'
                      strokeLinecap: "butt",
                      // // Customize transition animation
                      // transition: "stroke-dashoffset 0.5s ease 0s",
                      // // Rotate the path
                      // transform: "rotate(0.25turn)",
                      // transformOrigin: "center center",
                    },
                  }}
                >
                  <div className="flex flex-col justify-center">
                    <p className="text-center">Sales</p>
                    <p className="text-center font-bold">
                      {" "}
                      {fieldOfficerMetric?.data?.lowestSalesProductValue || 0}
                    </p>
                  </div>
                </CircularProgressbarWithChildren>
              </div>
            </div>
          </div>
        </div>
        <div>
          <CustomTab titles={["Retailers", "Households", "Communities"]}>
            <div>
              {/* <div className="px-[16px] py-[12px]">
                <InputSearch
                  placeholder={"search by name, or phone number"}
                  css={"w-full"}
                />
              </div> */}
              <CustomTable
                headers={retailersHeaders}
                rows={retailersManaging?.data?.content?.map((data, index) => {
                  return {
                    // household: data.household ? data.household : "N/A",
                    retailer:
                      data.firstName && data.lastName
                        ? data.firstName + " " + data.lastName
                        : "",
                    phoneNumber: data.phoneNumber ? data.phoneNumber : "N/A",
                    // fieldofficer: data.fieldOfficer ? data.fieldOfficer : "N/A",
                    state: data.state ? data.state : "N/A",
                    localGovernment: data.localGovernment
                      ? data.localGovernment
                      : "N/A",
                    "date & time": data.createdAt ? data.createdAt : "N/A",
                  };
                })}
              />
              <div className="pt-[12px] pb-[16px] px-[24px] flex justify-end">
                <PaginationTab
                  pageIndex={retailerPage}
                  pageCount={retailersManaging?.data?.totalPages}
                  gotoPage={handleRetailerTabPageChange}
                  // pageSize={10}
                  // canPreviousPage={""}
                  // canNextPage={""}
                  // pageOptions,
                  setPageSize={""}
                  // useAlternatePagination={false}
                />
              </div>
            </div>
            <div>
              {/* <div className="px-[16px] py-[12px]">
                <InputSearch
                  placeholder={"search by name, or phone number"}
                  css={"w-full"}
                />
              </div> */}
              <CustomTable
                headers={householdHeaders}
                rows={householdsManaging?.data?.content.map((data, index) => {
                  return {
                    household: data?.household ? data?.household : "N/A",
                    retailer: data?.retailer ? data?.retailer : "N/A",
                    // fieldOfficer: data?.fieldOfficer
                    //   ? data?.fieldOfficer
                    //   : "N/A",
                    phoneNumber: data?.phoneNumber ? data?.phoneNumber : "N/A",
                    state: data?.state ? data?.state : "N/A",
                    localGovernment: data?.localGovernment
                      ? data?.localGovernment
                      : "N/A",
                    "date & time": data?.createdAt ? data?.createdAt : "N/A",
                  };
                })}
              />
              <div className="pt-[12px] pb-[16px] px-[24px] flex justify-end">
                <PaginationTab
                  pageIndex={householdPage}
                  pageCount={householdsManaging?.data?.totalPages}
                  gotoPage={handleHouseholdTabPageChange}
                  // pageSize={10}
                  canPreviousPage={""}
                  canNextPage={""}
                  // pageOptions,
                  setPageSize={""}
                  // useAlternatePagination={false}
                />
              </div>
            </div>
            <div>
              {/* <div className="px-[16px] py-[12px]">
                <InputSearch
                  placeholder={"search by name, or phone number"}
                  css={"w-full"}
                />
              </div> */}
              <CustomTable headers={householdHeaders} />
              {/* <div className="pt-[12px] pb-[16px] px-[24px] flex justify-end">
                <PaginationTab
                  pageIndex={1}
                  pageCount={10}
                  gotoPage={""}
                  // pageSize={10}
                  canPreviousPage={""}
                  canNextPage={""}
                  // pageOptions,
                  setPageSize={""}
                  // useAlternatePagination={false}
                />
              </div> */}
            </div>
          </CustomTab>
        </div>
      </div>
      {showProfile && (
        <Modal
          variant="default"
          refProp={wrapperRef}
          closeModal={() => setShowProfile(false)}
        >
          <FieldOfficerModal
            closeModal={() => setShowProfile(false)}
            useerData={foDetail?.data}
            requestType={"edit"}
          />
        </Modal>
      )}
    </div>
  );
};

export default FieldOfficerDetail;
