import React, { useState } from "react";
import { login } from "../ds/auth";
import { useDispatch } from "react-redux";
import { logout, setUser } from "../features/auth";
import { useNavigate } from "react-router-dom";
const useAuth = () => {
  const [loading, setLoading] = useState();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const loginUser = async ({ email, password }) => {
    try {
      setLoading(true);
      const body = {
        username: email,
        password,
      };
      const res = await login(body);
      dispatch(setUser(res?.data?.user));
      setLoading(false);
      return res;
    } catch (error) {
      throw error;
    }
  };
  const logout = () => {
    dispatch(logout());
    localStorage.clear();
    navigate("/signin");
  };
  // const changeNewUserPassword = async (data) => {
  //   return await changeUserPassword(data);
  // };
  // const resetCurrentUserPassword = async (data) => {
  //   return await resetUserPassword(data);
  // };
  return {
    loading,
    loginUser,
    logout,
  };
};

export default useAuth;
