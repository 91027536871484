import React, { useRef, useState } from "react";
import { IoCloseOutline } from "react-icons/io5";
import Modal from "../Modal";
import EditProfileModal from "./EditProfileModal";
import useOutsideClick from "../../hooks/useOutsideClick";

const ViewProfileModal = ({
  closeModal,
  useerData,
  setShowEditProfile,
  showEditProfile,
  wrapperRef,
  setShowProfile,
}) => {
  return (
    <div className="bg-white w-[562px]">
      {" "}
      <div className="flex justify-between pb-[24px] px-[24px] pt-[16px]">
        <div className="">
          <h1 className="capitalize font-bold">Your Profile Details</h1>
        </div>
        <IoCloseOutline
          style={{ width: 26, height: 26, cursor: "pointer" }}
          onClick={() => closeModal()}
        />
      </div>
      <div className="p-[24px]">
        <div className="mb-[24px] flex gap-[72px]">
          <div className="w-full">
            <p className="mb-[8px]">First Name</p>
            <span className="px-[16px] py-[6px] rounded-[24px] bg-[#EFF8FF]">
              {useerData?.firstName}
            </span>
          </div>
          <div className="w-full">
            <p className="mb-[8px]">Last Name</p>
            <span className="px-[16px] py-[6px] rounded-[24px] bg-[#EFF8FF]">
              {useerData?.lastName}
            </span>
          </div>
        </div>
        <div className="mb-[24px] flex gap-[72px]">
          <div className="w-full">
            <p className="mb-[8px]">Email Adress</p>
            <span className="px-[16px] py-[6px] rounded-[24px] bg-[#EFF8FF] w-max">
              {useerData?.email}
            </span>
          </div>
          <div className="w-full">
            <p className="mb-[8px]">Phone Number</p>
            <span className="px-[16px] py-[6px] rounded-[24px] bg-[#EFF8FF] w-max">
              {useerData?.phoneNumber}
            </span>
          </div>
        </div>
        <div className="">
          <div className="w-full">
            <p className="mb-[8px]">Joining Date and Time</p>
            <div className=" flex gap-[10px]">
              <span className="px-[16px] py-[6px] rounded-[24px] bg-[#EFF8FF] w-max">
                {useerData?.createdAt?.split("")[0] || "N/A"}
              </span>
              <span className="px-[16px] py-[6px] rounded-[24px] bg-[#EFF8FF] w-max">
                {useerData?.createdAt?.split("")[1] || "N/A"}
              </span>
            </div>
          </div>
        </div>
      </div>
      <div className="p-[24px]">
        <button
          className="w-full text-center h-[48px]"
          onClick={() => {
            setShowEditProfile(true);
            // setShowProfile();
          }}
        >
          Edit Profile
        </button>
      </div>
      {showEditProfile && (
        <Modal
          variant="default"
          refProp={wrapperRef}
          closeModal={() => setShowEditProfile(false)}
        >
          <EditProfileModal
            closeModal={() => setShowEditProfile(false)}
            useerData={useerData}
          />{" "}
        </Modal>
      )}
    </div>
  );
};

export default ViewProfileModal;
