import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Avatar from "../../assets/images/gbf-logo.jpeg";
import { IoIosArrowDown } from "react-icons/io";
import { FiUser } from "react-icons/fi";
import { GoGear } from "react-icons/go";
import useOutsideClick from "../../hooks/useOutsideClick";
import Modal from "../Modal";
import ViewProfileModal from "../modals/ViewProfileModal";
import ChangePasswordModal from "../modals/ChangePasswordModal";
const Header = ({ setShowmobileNav }) => {
  const wrapperRef = useRef(null);
  const [showProfile, setShowProfile] = useOutsideClick(wrapperRef);
  const [changePassword, setChangePassword] = useOutsideClick(wrapperRef);
  const [showEditProfile, setShowEditProfile] = useOutsideClick(wrapperRef);
  const [showdropDown, setShowdropDown] = useState(false);
  const dispatch = useDispatch();
  const { program, user } = useSelector((state) => state.auth);
  // console.log({ user });
  // const { programs, programvalue, setProgramValue } = useProgram();

  return (
    <div className="w-full flex flex-col lg:flex-row-reverse p-4 border-b border-gray-300 h-[72px] relative">
      <div className="flex items-center justify-start gap-[6px] mb-2">
        <div className="h-[60px] w-[60px] rounded-full">
          <img
            src={Avatar}
            alt="profile"
            style={{ width: "100%", height: "auto", objectFit: "contain" }}
          />
        </div>
        <IoIosArrowDown
          style={{ width: "24px", height: "24px", cursor: "pointer" }}
          onClick={() => setShowdropDown((prev) => !prev)}
        />
      </div>
      {showdropDown ? (
        <div className="w-[266px] h-max absolute top-[72px] border border-gray-300 bg-white z-50">
          <div className="px-[16px] py-[12px] w-full  border-b border-gray-300 ">
            <div className="flex gap-[12px]">
              <div className="h-[60px] w-[60px] rounded-full">
                <img
                  src={Avatar}
                  alt="profile"
                  style={{
                    width: "100%",
                    height: "auto",
                    objectFit: "contain",
                  }}
                />
              </div>
              <div>
                <p>
                  {user?.firstName} {user?.lastName}
                </p>
                <p>{user?.email}</p>
              </div>
            </div>
          </div>
          <div
            onMouseLeave={() => setShowdropDown(false)}
            onMouseEnter={() => setShowdropDown(true)}
          >
            <div className="px-[6px] py-[2px] h-[42px]">
              <div
                className="px-[10px] py-[9px] flex items-center gap-[12px] cursor-pointer"
                onClick={() => {
                  setShowProfile(true);
                  setShowdropDown(false);
                }}
              >
                <FiUser />
                <p>View Profile</p>
              </div>
            </div>
            <div className="px-[6px] py-[2px] h-[42px]">
              <div
                className="px-[10px] py-[9px] flex items-center gap-[12px] cursor-pointer"
                onClick={() => {
                  setChangePassword(true);
                  setShowdropDown(false);
                }}
              >
                <GoGear />
                <p>Change Password</p>
              </div>
            </div>
          </div>
        </div>
      ) : (
        ""
      )}
      {showProfile && (
        <Modal
          variant="default"
          refProp={wrapperRef}
          closeModal={() => setShowProfile(false)}
        >
          <ViewProfileModal
            closeModal={() => setShowProfile(false)}
            useerData={user}
            setShowEditProfile={setShowEditProfile}
            showEditProfile={showEditProfile}
            wrapperRef={wrapperRef}
            setShowProfile={() => setShowProfile(false)}
          />{" "}
        </Modal>
      )}
      {changePassword && (
        <Modal
          variant="default"
          refProp={wrapperRef}
          closeModal={() => setChangePassword(false)}
        >
          <ChangePasswordModal closeModal={() => setChangePassword(false)} />{" "}
        </Modal>
      )}
    </div>
  );
};

export default Header;
