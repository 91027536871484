import React, { useState } from "react";
import { createHousehold, getAllHousehold } from "../ds/household";
import {
  createRetailer,
  downloadRetailer,
  getAllRetailer,
  getAllRetailerList,
  getRetailerDetail,
  getRetailerSummary,
  updateRetailer,
} from "../ds/retailer";
import { downloadCsvFile } from "../utils/download";
import toast from "react-hot-toast";

const useRetailer = () => {
  const [loading, setLoading] = useState();
  const [communities, setCommunities] = useState();
  const [downloadData, setDownloadData] = useState("");

  const gatAllRetailers = async ({
    page = 1,
    size = 10,
    query,
    stateId,
    lgaId,
    fieldOfficerId,
  }) => {
    setLoading(true);
    console.log({ page, size, query, stateId, lgaId, fieldOfficerId });
    const res = await getAllRetailer({
      page,
      size,
      nameOrPhoneNumber: query || "",
      stateId: stateId || "",
      lgaId: lgaId || "",
      fieldOfficerId: fieldOfficerId || "",
    });

    setLoading(false);
    return JSON.parse(res);
  };

  const createNewRetailer = (data) => {
    const res = createRetailer(data);
    return res;
  };
  const updateNewRetailer = (data) => {
    const res = updateRetailer(data);
    return res;
  };

  const getRetailerDetails = async (id) => {
    setLoading(true);
    const res = await getRetailerDetail(id);

    setLoading(false);
    return res;
  };

  const retailerSummary = async (id) => {
    setLoading(true);
    const res = await getRetailerSummary(id);
    setLoading(false);
    return res;
  };

  const retailersList = async () => {
    setLoading(true);
    const res = await getAllRetailerList();
    setLoading(false);
    return res;
  };

  const retailerDownload = async ({
    nameOrPhoneNumber,
    stateId,
    lgaId,
    fieldOfficerId,
  }) => {
    toast.success("download...");
    const res = await downloadRetailer({
      nameOrPhoneNumber: nameOrPhoneNumber || "",
      stateId: stateId || "",
      lgaId: lgaId || "",
      fieldOfficerId: fieldOfficerId || "",
    });
    return downloadCsvFile(res, "retailers.csv", setDownloadData, toast);
  };

  return {
    loading,
    gatAllRetailers,
    createNewRetailer,
    getRetailerDetails,
    retailerSummary,
    updateNewRetailer,
    retailerDownload,
    retailersList,
  };
};

export default useRetailer;
