import React, { useState } from "react";
import { completeSignUp, createAdmin, getAllAdminList } from "../ds/admin";
import { useDispatch } from "react-redux";

const useAdmin = () => {
  const [loading, setLoading] = useState();
  const [aggregatorCount, setAggregatorCount] = useState();
  const [downloadData, setDownloadData] = useState("");
  const dispatch = useDispatch();
  // console.log({ program });

  const getAllAdmin = async (
    page = 1,
    size = 10,
    query,
    selectedState,
    program
  ) => {
    setLoading(true);
    const res = await getAllAdminList({
      page,
      size,
      nameOrEmailOrPhoneNumber: query || "",
      state: selectedState || "",
      program,
    });

    setLoading(false);
    return JSON.parse(res);
  };

  //    const resetAggregators = async (
  //      page = 1,
  //      size = 10,
  //      nameOrEmailOrPhoneNumber = "",
  //      state = "",
  //      location = ""
  //    ) => {
  //      setLoading(true);
  //      const res = await gatAllAggregator({
  //        page,
  //        size,
  //        nameOrEmailOrPhoneNumber,
  //        state,
  //        location,
  //      });

  //      setLoading(false);
  //      return JSON.parse(res);
  //    };

  //    const getSingleAggregator = async (aggregatorId) => {
  //      setLoading(true);
  //      const res = await getAggregatorDetail({ id: aggregatorId });
  //      setLoading(false);
  //      return res;
  //    };

  //    const gatAllAggregatorLists = useCallback(async (program) => {
  //      setLoading(true);
  //      const res = await getAllAggregatorList(program);
  //      // console.log({ res }, "hook");
  //      setAggregatorCount(res?.data?.length);
  //      return res;
  //    }, []);

  //    useEffect(() => {
  //      gatAllAggregatorLists();
  //    }, []);

  const createNewAdmin = async (data) => {
    const res = await createAdmin(data);
    return res;
  };

  const completeAdminSignUp = async (data) => {
    const res = await completeSignUp(data);
    return res;
  };

  return {
    loading,
    createNewAdmin,
    getAllAdmin,
    completeAdminSignUp,
  };
};

export default useAdmin;
