import React, { useState } from "react";
import Header from "../components/header/Header";
import { Outlet } from "react-router-dom";
import DashboardSidebar from "../components/sidebar/DashboardSidebar";
// import useNav from "../hooks/useNav";
import { PiUserSquareLight, PiUsers } from "react-icons/pi";
import { RiDeleteBin7Line } from "react-icons/ri";
import { TbUserHexagon } from "react-icons/tb";
import { RiHome5Line } from "react-icons/ri";
import { HiOutlineChartSquareBar } from "react-icons/hi";
import { LuBuilding2 } from "react-icons/lu";
import { GoPeople } from "react-icons/go";
import { BsColumnsGap } from "react-icons/bs";
// import ErrorBoundary from "../components/ErrorBoundary/ErrorBondary";

const navs = [
  {
    route: "/dashboard",
    name: "Dashboard",
    Icon: RiHome5Line,
    showArrow: false,
  },
  {
    route: "/salesrecords",
    name: "Sales Records",
    Icon: HiOutlineChartSquareBar,
    showArrow: false,
  },
  {
    route: "/fieldofficers",
    name: "Field Officers",
    Icon: TbUserHexagon,
    showArrow: false,
  },
  {
    route: "/retailers",
    name: "Retailers",
    Icon: RiDeleteBin7Line,
    showArrow: false,
  },
  {
    route: "/households",
    name: "Households",
    Icon: LuBuilding2,
    showArrow: false,
  },
  {
    route: "/community",
    name: "Community",
    Icon: BsColumnsGap,
    showArrow: false,
  },
  {
    route: "#",
    name: "Administrators",
    Icon: GoPeople,
    showArrow: false,
    children: [
      {
        id: 1,
        route: "/administrators",
        name: "Administrators",
        // Icon: GoTag,
      },
      {
        id: 2,
        route: "/adminaudit",
        name: "Admin Audit",
        // Icon: CgFileDocument,
      },
    ],
  },
];

const DashboardLayout = () => {
  const [closeNav, setCloseNav] = useState(false);
  const [showmobileNav, setShowmobileNav] = useState(false);
  return (
    <div>
      <aside
        className={`sm:hidden md:hidden lg:block h-screen fixed left-0 top-0 bottom-0 pt-[24px] px-[16px] pb-[32px]  bg-[#F9FAFB] ${
          closeNav ? "w-[97px]" : "w-[213px]"
        }  border-r border-gray-300 overflow-hidden`}
      >
        <DashboardSidebar
          setCloseNav={setCloseNav}
          closeNav={closeNav}
          navs={navs}
          setShowmobileNav={setShowmobileNav}
        />
      </aside>
      <main
        className={`w-full pl-[213px] ${
          closeNav ? "pl-[97px]" : "pl-[213px]"
        } `}
      >
        <Header setShowmobileNav={setShowmobileNav} />
        {/* <ErrorBoundary> */}
        <Outlet />
        {/* </ErrorBoundary> */}
      </main>
    </div>
  );
};

export default DashboardLayout;
