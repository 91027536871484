import React, { useState } from "react";
import {
  activateFieldOfficer,
  downloadFieldOfficer,
  getAllActiveFieldOfficer,
  getAllFieldOfficerList,
  getAllPendingFieldOfficer,
  getFieldOfficerDetail,
  getFieldOfficerMetric,
} from "../ds/fieldofficer";
import { downloadCsvFile } from "../utils/download";
import toast from "react-hot-toast";

const useFieldOfficer = () => {
  const [loading, setLoading] = useState();
  const [downloadData, setDownloadData] = useState("");

  //   const dispatch = useDispatch();
  // console.log({ program });

  const gatAllActiveFo = async (
    page = 1,
    size = 10,
    nameOrPhoneNumber,
    stateId,
    lgaId
  ) => {
    setLoading(true);
    const res = await getAllActiveFieldOfficer({
      page,
      size,
      nameOrPhoneNumber: nameOrPhoneNumber || "",
      stateId: stateId || "",
      lgaId: lgaId || "",
    });

    setLoading(false);
    return JSON.parse(res);
  };

  const gatAllPendingFo = async (page = 1, size = 10) => {
    setLoading(true);
    const res = await getAllPendingFieldOfficer({
      page,
      size,
    });
    setLoading(false);
    return JSON.parse(res);
  };
  const getFoDetails = async (id) => {
    setLoading(true);
    const res = await getFieldOfficerDetail(id);

    setLoading(false);
    return res;
  };
  const activateFO = async (id) => {
    setLoading(true);
    const res = await activateFieldOfficer({
      userId: id,
    });

    setLoading(false);
    return res;
  };

  const fieldOfficersList = async () => {
    setLoading(true);
    const res = await getAllFieldOfficerList();
    setLoading(false);
    return res;
  };

  const fieldOfficerDownload = async ({
    nameOrPhoneNumber,
    stateId,
    lgaId,
  }) => {
    toast.success("download...");
    const res = await downloadFieldOfficer({
      nameOrPhoneNumber: nameOrPhoneNumber || "",
      stateId: stateId || "",
      lgaId: lgaId || "",
    });
    return downloadCsvFile(res, "fieldofficers.csv", setDownloadData, toast);
  };

  const getAllfieldOfficerMetric = async (id) => {
    const res = await getFieldOfficerMetric(id);
    return res;
  };

  return {
    loading,
    gatAllActiveFo,
    gatAllPendingFo,
    getFoDetails,
    activateFO,
    fieldOfficerDownload,
    getAllfieldOfficerMetric,
    fieldOfficersList,
  };
};

export default useFieldOfficer;
