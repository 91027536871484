export const downloadCsvFile = (csv = "", filename, setDownloadData, toast) => {
  if (csv && csv.length > 0) {
    // export successfull
    setDownloadData(csv);
    let hiddenElement = document.createElement("a");
    let blob = new Blob(["\ufeff", csv]);
    let url = URL.createObjectURL(blob);
    hiddenElement.href = url;
    // hiddenElement.target = "_blank";
    hiddenElement.download = filename;
    hiddenElement.click();
    hiddenElement.remove(); // experimental, hopefully prevent creating too many nodes
    toast.success("Download Succesful");
  } else {
    toast.error("download failed");
  }
};
