import * as yup from "yup";
export const signInSchema = yup.object({
  email: yup
    .string()
    .email("Invalid email format")
    .required("Email is required"),
  password: yup
    .string()
    .required("Please enter your password")
    .min(8, "Too Short!"),
});
export const deactivateSchema = yup.object({
  phoneNumber: yup.string().required("Please enter your password"),
});
