async function fetcher(url, options, searchParams, download) {
  const apiUrl = process.env.REACT_APP_BACKEND_URL + url;

  const urlWithParams = new URL(apiUrl);
  urlWithParams.search = new URLSearchParams(searchParams).toString();

  try {
    const res = await fetch(urlWithParams, {
      ...options,
      // ...(options.cache && { cache: options.cache }),
      headers: {
        "Content-Type": download ? "text/csv" : "application/json",
        ...options.headers,
      },
    });
    if (download) {
      const data = await res.text();
      return data;
    }

    const data = await res.json();
    return data;
  } catch (error) {
    throw new Error(error);
  }
}

export default fetcher;
