import fetcher from "../api/fetacher";

export const getAllSalesReport = async () => {
  try {
    const accessToken = localStorage.getItem("accessToken");
    // const params = {};
    const res = await fetcher(
      "/dashboard/sales/report",
      {
        method: "GET",
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      },
      //   params,
      true
    );
    return res;
  } catch (error) {
    throw error;
  }
};
export const getAllProductSalesReport = async ({ CalendarType }) => {
  try {
    const accessToken = localStorage.getItem("accessToken");
    const params = {};
    if (CalendarType) {
      params.CalendarType = CalendarType;
    }
    const res = await fetcher(
      "/dashboard/product/sales/report",
      {
        method: "GET",
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      },
      params,
      true
    );
    return res;
  } catch (error) {
    throw error;
  }
};

export const getAllProductRegionReport = async ({ stateId, calendarType }) => {
  try {
    const accessToken = localStorage.getItem("accessToken");
    const params = {};
    if (stateId) {
      params.stateId = stateId;
    }
    if (calendarType) {
      params.calendarType = calendarType;
    }
    const res = await fetcher(
      "/dashboard/product/region/report",
      {
        method: "GET",
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      },
      params,
      true
    );
    return res;
  } catch (error) {
    throw error;
  }
};
export const getAllRetailerReport = async () => {
  try {
    const accessToken = localStorage.getItem("accessToken");
    // const params = {};
    const res = await fetcher(
      "/dashboard/retailer/report",
      {
        method: "GET",
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      },
      //   params,
      true
    );
    return res;
  } catch (error) {
    throw error;
  }
};
export const getAllRetailerOnboardingReport = async ({
  stateId,
  calendarType,
}) => {
  try {
    const accessToken = localStorage.getItem("accessToken");
    const params = {};
    if (stateId) {
      params.stateId = stateId;
    }
    if (calendarType) {
      params.calendarType = calendarType;
    }
    const res = await fetcher(
      "/dashboard/retailer/onboarding/report",
      {
        method: "GET",
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      },
      params,
      true
    );
    return res;
  } catch (error) {
    throw error;
  }
};

export const getAllRetailerGenderOnboardingReport = async () => {
  try {
    const accessToken = localStorage.getItem("accessToken");
    const res = await fetcher(
      "/dashboard/retailer/gender/onboarding/report",
      {
        method: "GET",
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      },
      true
    );
    return res;
  } catch (error) {
    throw error;
  }
};
export const getAllRetailerGenderLocationReport = async () => {
  try {
    const accessToken = localStorage.getItem("accessToken");
    const res = await fetcher(
      "/dashboard/retailer/gender/location/report",
      {
        method: "GET",
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      },
      true
    );
    return res;
  } catch (error) {
    throw error;
  }
};

export const getAllRetailerGenderSalesReport = async ({ CalendarType }) => {
  try {
    const accessToken = localStorage.getItem("accessToken");
    const params = {};
    if (CalendarType) {
      params.CalendarType = CalendarType;
    }
    const res = await fetcher(
      "/dashboard/retailer/gender/sales/report",
      {
        method: "GET",
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      },
      params,
      true
    );
    return res;
  } catch (error) {
    throw error;
  }
};
export const getAllRetailerGenderDateReport = async ({ CalendarType }) => {
  try {
    const accessToken = localStorage.getItem("accessToken");
    const params = {};
    if (CalendarType) {
      params.CalendarType = CalendarType;
    }
    const res = await fetcher(
      "/dashboard/retailer/gender/date/report",
      {
        method: "GET",
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      },
      params,
      true
    );
    return res;
  } catch (error) {
    throw error;
  }
};

export const getAllRetailerSalesReport = async ({ stateId, calendarType }) => {
  try {
    const accessToken = localStorage.getItem("accessToken");
    const params = {};
    if (stateId) {
      params.stateId = stateId;
    }
    if (calendarType) {
      params.calendarType = calendarType;
    }
    const res = await fetcher(
      "/dashboard/retailer/sales/report",
      {
        method: "GET",
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      },
      params,
      true
    );
    return res;
  } catch (error) {
    throw error;
  }
};
// export const getAllGenderSalesReport = async ({ CalendarType }) => {
//   try {
//     const accessToken = localStorage.getItem("accessToken");
//     const params = {};
//     if (CalendarType) {
//       params.CalendarType = CalendarType;
//     }
//     const res = await fetcher(
//       "/dashboard/household/report",
//       {
//         method: "GET",
//         headers: {
//           Authorization: `Bearer ${accessToken}`,
//         },
//       },
//       params,
//       true
//     );
//     return res;
//   } catch (error) {
//     throw error;
//   }
// };
export const getAllHouseholdReport = async ({ CalendarType }) => {
  try {
    const accessToken = localStorage.getItem("accessToken");
    const params = {};
    if (CalendarType) {
      params.CalendarType = CalendarType;
    }
    const res = await fetcher(
      "/dashboard/household/report",
      {
        method: "GET",
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      },
      params,
      true
    );
    return res;
  } catch (error) {
    throw error;
  }
};

export const getAllHouseoldSalesReport = async ({ stateId, calendarType }) => {
  try {
    const accessToken = localStorage.getItem("accessToken");
    const params = {};
    if (stateId) {
      params.stateId = stateId;
    }
    if (calendarType) {
      params.calendarType = calendarType;
    }
    const res = await fetcher(
      "/dashboard/household/sales/report",
      {
        method: "GET",
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      },
      params,
      true
    );
    return res;
  } catch (error) {
    throw error;
  }
};
export const getAllHouseoldOnboardingReport = async ({
  stateId,
  calendarType,
}) => {
  try {
    const accessToken = localStorage.getItem("accessToken");
    const params = {};
    if (stateId) {
      params.stateId = stateId;
    }
    if (calendarType) {
      params.calendarType = calendarType;
    }
    const res = await fetcher(
      "/dashboard/household/onboarding/report",
      {
        method: "GET",
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      },
      params,
      true
    );
    return res;
  } catch (error) {
    throw error;
  }
};
