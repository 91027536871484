import React from "react";
import { IoCloseOutline } from "react-icons/io5";
import Avatar from "../../assets/images/Dropdown.png";

const SignupRequestModal = ({
  closeModal,
  useerData,
  handleApproval,
  declineApproval,
}) => {
  return (
    <div className="bg-white w-[562px] h-[650px] overflow-auto">
      {" "}
      <div className="flex justify-between px-[24px] pt-[16px]">
        <div className="">
          <h1 className="capitalize font-bold">Signup Request</h1>
        </div>
        <IoCloseOutline
          style={{ width: 26, height: 26, cursor: "pointer" }}
          onClick={() => closeModal()}
        />
      </div>
      <div className="p-[24px]">
        <div className="h-[120px] w-[120px] rounded-full mb-[20px]">
          <img
            src={useerData?.image}
            alt="profile"
            style={{
              width: "100%",
              height: "100%",
              objectFit: "contain",
            }}
          />
        </div>
        <div className="">
          <div className="mb-[24px] flex gap-[72px]">
            <div className="w-full">
              <p className="mb-[8px]">Joining Date and Time</p>
              <div className=" flex gap-[10px]">
                <span className="px-[14px] py-[6px] rounded-[24px] bg-[#EFF8FF] w-max">
                  {useerData?.createdAt?.split(" ")[0] || "N/A"}
                </span>
                <span className="px-[14px] py-[6px] rounded-[24px] bg-[#EFF8FF] w-max">
                  {useerData?.createdAt?.split(" ")[1] || "N/A"}
                </span>
              </div>
            </div>
            <div className="w-full">
              <p className="mb-[8px]">Status</p>
              <span className="px-[16px] py-[6px] rounded-[24px] bg-[#EFF8FF]">
                {useerData?.firstName}
              </span>
            </div>
          </div>
          <div className="mb-[24px] flex gap-[72px]">
            <div className="w-full">
              <p className="mb-[8px]">First Name</p>
              <span className="px-[16px] py-[6px] rounded-[24px] bg-[#EFF8FF]">
                {useerData?.firstName}
              </span>
            </div>
            <div className="w-full">
              <p className="mb-[8px]">Last Name</p>
              <span className="px-[16px] py-[6px] rounded-[24px] bg-[#EFF8FF]">
                {useerData?.lastName}
              </span>
            </div>
          </div>
          <div className="mb-[24px] flex gap-[72px]">
            <div className="w-full">
              <p className="mb-[8px]">Email Adress</p>
              <span className="px-[16px] py-[6px] rounded-[24px] bg-[#EFF8FF] w-max">
                {useerData?.email}
              </span>
            </div>
            <div className="w-full">
              <p className="mb-[8px]">Phone Number</p>
              <span className="px-[16px] py-[6px] rounded-[24px] bg-[#EFF8FF] w-max">
                {useerData?.phoneNumber}
              </span>
            </div>
          </div>
          <div className="mb-[24px] flex gap-[72px]">
            <div className="w-full">
              <p className="mb-[8px]">State</p>
              <span className="px-[16px] py-[6px] rounded-[24px] bg-[#EFF8FF] w-max">
                {useerData?.state}
              </span>
            </div>
            <div className="w-full">
              <p className="mb-[8px]">Lga(s)</p>
              <div className="flex flex-wrap gap-[10px]">
                {useerData?.localGovernments?.map((data, index) => {
                  return (
                    <span className="px-[16px] py-[6px] rounded-[24px] bg-[#EFF8FF] w-max">
                      {data?.name}
                    </span>
                  );
                })}
              </div>
            </div>
          </div>
          <div className="flex gap-[72px]">
            <div className="w-full">
              <p className="mb-[8px]">Community</p>
              <div className="flex flex-wrap gap-[10px]">
                {useerData?.communities?.map((data, index) => {
                  return (
                    <span className="px-[16px] py-[6px] rounded-[24px] bg-[#EFF8FF] w-max">
                      {data?.name}
                    </span>
                  );
                })}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="pt-[12px] px-[24px] pb-[24px] flex gap-[12px]">
        <button
          className="bg-[#FEF3F2] text-[#F04438] w-[261px] h-[48px] rounded-md"
          onClick={() => declineApproval()}
        >
          Decline Request
        </button>
        <button
          className=" bg-green-900 text-white w-[261px] h-[48px] rounded-md"
          onClick={() => handleApproval()}
        >
          Approve Request
        </button>
      </div>
    </div>
  );
};

export default SignupRequestModal;
