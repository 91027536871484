import React from "react";

const TopMiniCard = ({ css, title, subtitle, subtlet, textColor }) => {
  return (
    <div
      className={`flex flex-col lg:flex-row px-[16px] py-[18px] rounded-[8px] h-[116px] ${
        css ? css : " "
      }`}
    >
      <div className="flex flex-col gap-[8px]">
        <h1 className="capitalize text-sm">{title}</h1>
        <p className="font-bold text-2xl">{subtitle}</p>
        <p className={`text-sm ${textColor ? textColor : ""}`}>{subtlet}</p>
      </div>
    </div>
  );
};

export default TopMiniCard;
