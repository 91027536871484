import React, { useEffect, useRef, useState } from "react";
import CustomTable from "../../components/table/CustomTable";
import TopCard from "../../components/card/TopCard";
import TableTopCard from "../../components/card/TableTopCard";
import InputSearch from "../../components/input/InputSearch";
import { AdminModal } from "../../components/modals/AdminModal";
import useOutsideClick from "../../hooks/useOutsideClick";
import Modal from "../../components/Modal";
import useAdmin from "../../hooks/useAdmin";
import { HiOutlineDotsVertical } from "react-icons/hi";
import PaginationTab from "../../components/pagination/PaginationTab";
import { useNavigate } from "react-router-dom";

const headers = [
  "First Name",
  "Last Name",
  "Company Email",
  "Phone Number",
  "Log-in Time Stamp",
  "Joining Date & Time",
];

const Administrator = () => {
  const wrapperRef = useRef(null);
  const [showModal, setShowModal] = useOutsideClick(wrapperRef);
  const [editModal, setEditModal] = useOutsideClick(wrapperRef);
  const [admins, setAdmins] = useState();
  const [acttiveAdminIndex, setActiveAdminIndex] = useState(1);
  const [acttiveAdminDetail, setActiveAdminDetail] = useState(1);
  const [showMore, setShowMore] = useState(false);
  const [page, setPage] = useState(1);
  const navigate = useNavigate();

  const { loading, getAllAdmin } = useAdmin();
  const getAllAdmins = async () => {
    const res = await getAllAdmin();
    setAdmins(res);
  };
  useEffect(() => {
    getAllAdmins();
  }, []);

  const handlePageChange = (data) => {
    if (data === 0) {
      setPage(1);
    }
    if (data >= 0) {
      setPage((prev) => data + prev);
    }
  };
  const prevPage = () => {
    setPage((prev) => (prev > 1 ? prev - 1 : prev));
  };

  return (
    <div className="p-4">
      <div className="mb-10">
        <TopCard
          title={"Total Admin"}
          subtitle={"Admins"}
          userCount={admins?.data?.totalElements || "0"}
        />
      </div>
      <div className="rounded-[8px] border border-gray-300">
        <div className="border-b border-gray-300">
          <TableTopCard
            title={"Administrators"}
            subtitle={"keep track of all sub-admins"}
            buttonTitle={"Create Administrator"}
            className={"bg-green-900 text-white"}
            setShowModal={() => setShowModal(true)}
          />
        </div>
        <div className="flex flex-col justify-between md:flex-row lg:flex-row">
          <div className="py-[12px] px-[16px]">
            <InputSearch
              placeholder={"search by name, email or phone number"}
              css={"w-[481px] h-[44px]"}
            />
          </div>
        </div>

        {loading ? (
          <div className="flex justify-center">
            <p className="text-center">Loading...</p>
          </div>
        ) : admins?.data?.content?.length === 0 ? (
          <div className="flex justify-center">
            <p className="text-center">No data.</p>
          </div>
        ) : (
          <CustomTable
            headers={headers}
            rows={admins?.data?.content?.map((data, index) => {
              return {
                firstName: data.firstName ? data.firstName : "",
                lastName: data.lastName ? data.lastName : "",
                "company email": data.email ? data.email : "",
                phoneNumber: data.phoneNumber ? data.phoneNumber : "",
                "Log-in Time Stamp": data.createdAt ? data.createdAt : "N/A",
                "Joining Date & Time": (
                  <div className="w-[100px]">
                    {data.createdAt ? data.createdAt : "N/A"}
                  </div>
                ),
                "settings button": (
                  <div className="relative">
                    <HiOutlineDotsVertical
                      onClick={() => {
                        setShowMore((prev) => !prev);
                        setActiveAdminIndex(index);
                      }}
                    />
                    {showMore && index === acttiveAdminIndex ? (
                      <div className="absolute bottom-[15px] right-[25px] bg-white border border-gray-300 h-max w-max p-[8px] z-50">
                        <button
                          onClick={() => {
                            setEditModal(true);
                            setShowMore(false);
                            setActiveAdminDetail(data);
                          }}
                        >
                          view more
                        </button>
                      </div>
                    ) : (
                      ""
                    )}
                  </div>
                ),
              };
            })}
          />
        )}
      </div>
      <div className="pt-[12px] pb-[16px] px-[24px] flex justify-end">
        <PaginationTab
          pageIndex={page}
          pageCount={admins?.data?.totalPages || 1}
          gotoPage={handlePageChange}
          // pageSize={10}
          canPreviousPage={""}
          canNextPage={""}
          // pageOptions,
          setPageSize={""}
          // useAlternatePagination={false}
        />
      </div>
      {showModal && (
        <Modal
          variant="default"
          refProp={wrapperRef}
          closeModal={() => setShowModal(false)}
        >
          <AdminModal
            closeModal={() => setShowModal(false)}
            getAdmin={getAllAdmin}
            // aggregatorList={aggregatorList}
            // collectorList={collectorList}
            // fetchTransactions={fetchTransactions}
            // isAggregator={isAggregator}
            // selectOptDefault={{ label: user.name, value: user?.aggregatorId }}
          />{" "}
        </Modal>
      )}
      {editModal && (
        <Modal
          variant="default"
          refProp={wrapperRef}
          closeModal={() => setEditModal(false)}
        >
          <AdminModal
            closeModal={() => setEditModal(false)}
            getAdmin={getAllAdmin}
            requestType={"edit"}
            useerData={acttiveAdminDetail}
          />{" "}
        </Modal>
      )}
    </div>
  );
};

export default Administrator;
