import React, { Children } from "react";
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from "recharts";

const COLORS = [
  "#D62728",
  "#9467BD",
  "#1F77B4",
  "#FF7F0E",
  "#2CA02C",
  "#8C564B",
];

const LineCharts = ({
  fill,
  datakeyX,
  dataKeyB,
  lineData,
  dataKeyX,
  lineDataTwo = [],
}) => {
  // const arrayChildren = Children.toArray(children);
  //   static demoUrl = 'https://codesandbox.io/p/sandbox/line-chart-width-xaxis-padding-8v7952';

  const data = [
    {
      name: "A",
      uv: 4000,
      pv: 2400,
      amt: 2400,
    },
    {
      name: "B",
      uv: 3000,
      pv: 1398,
      amt: 2210,
    },
    {
      name: "C",
      uv: 2000,
      pv: 9800,
      amt: 2290,
    },
    {
      name: "D",
      uv: 2780,
      pv: 3908,
      amt: 2000,
    },
    {
      name: "E",
      uv: 1890,
      pv: 4800,
      amt: 2181,
    },
    {
      name: "F",
      uv: 2390,
      pv: 3800,
      amt: 2500,
    },
    {
      name: "G",
      uv: 3490,
      pv: 4300,
      amt: 2100,
    },
  ];

  const DataFormater = (number) => {
    if (number > 1000000000) {
      return (number / 1000000000).toString() + "B";
    } else if (number > 1000000) {
      return (number / 1000000).toString() + "M";
    } else if (number > 1000) {
      return (number / 1000).toString() + "K";
    } else {
      return number.toString();
    }
  };
  function numberWithCommas(x) {
    return x?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  }
  return (
    <ResponsiveContainer width="100%" height="100%">
      <LineChart
        width={500}
        height={300}
        data={lineData || []}
        margin={{
          top: 5,
          right: 30,
          left: 20,
          bottom: 5,
        }}
      >
        {/* <CartesianGrid strokeDasharray="3 3" /> */}
        <XAxis dataKey={dataKeyX} />
        <YAxis tickFormatter={DataFormater} />
        {/* <Tooltip /> */}
        <Legend
          iconType="circle"
          iconSize={8}
          layout="horizontal"
          verticalAlign="top"
          align="right"
        />
        <Tooltip formatter={numberWithCommas} />
        {lineDataTwo.map((data, index) => {
          return (
            <Line
              type="monotone"
              dataKey={data}
              stroke={COLORS[index % COLORS.length]}
              key={index}
              strokeWidth={3}
            />
          );
        })}
      </LineChart>
    </ResponsiveContainer>
  );
};

export default LineCharts;
