import fetcher from "../api/fetacher";

export const createHousehold = async (data) => {
  try {
    const accessToken = localStorage.getItem("accessToken");

    const res = await fetcher("/household", {
      method: "POST",
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
      body: JSON.stringify(data),
      cache: "no-store",
    });
    return res;
  } catch (error) {
    throw error;
  }
};

export const updateHousehold = async (data) => {
  try {
    const res = await fetcher("/household", {
      method: "PATCH",
      body: JSON.stringify(data),
      cache: "no-store",
    });
    return res;
  } catch (error) {
    throw error;
  }
};
export const getAllHousehold = async ({
  page,
  size,
  nameOrPhoneNumber,
  stateId,
  lgaId,
  retailerId,
  communityId,
  fieldOfficerId,
}) => {
  try {
    const accessToken = localStorage.getItem("accessToken");
    const params = {
      page,
      size,
    };
    if (nameOrPhoneNumber) {
      params.name = nameOrPhoneNumber;
    }
    if (retailerId) {
      params.retailerId = retailerId;
    }
    if (communityId) {
      params.communityId = communityId;
    }
    if (stateId) {
      params.stateId = stateId;
    }
    if (lgaId) {
      params.lgaId = lgaId;
    }
    if (fieldOfficerId) {
      params.fieldOfficerId = fieldOfficerId;
    }
    const res = await fetcher(
      "/household",
      {
        method: "GET",
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      },
      params,
      true
    );
    return res;
  } catch (error) {
    throw error;
  }
};
export const getAllHouseholdList = async () => {
  try {
    const accessToken = localStorage.getItem("accessToken");
    const res = await fetcher("/household/list", {
      method: "GET",
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });
    return res;
  } catch (error) {
    throw error;
  }
};

export const getHouseholdDetail = async (id) => {
  try {
    const accessToken = localStorage.getItem("accessToken");
    const res = await fetcher(`/household/${id}`, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });
    return res;
  } catch (error) {
    throw error;
  }
};

export const getHouseholdSummary = async (id) => {
  try {
    const accessToken = localStorage.getItem("accessToken");
    const res = await fetcher(`/household/summary/${id}`, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });
    return res;
  } catch (error) {
    throw error;
  }
};

export const downloadHousehold = async ({
  nameOrPhoneNumber,
  stateId,
  lgaId,
  retailerId,
  communityId,
  fieldOfficerId,
}) => {
  try {
    const accessToken = localStorage.getItem("accessToken");
    const params = {};
    if (nameOrPhoneNumber) {
      params.nameOrPhoneNumber = nameOrPhoneNumber;
    }
    if (stateId) {
      params.stateId = stateId;
    }
    if (lgaId) {
      params.lgaId = lgaId;
    }
    if (retailerId) {
      params.retailerId = retailerId;
    }
    if (communityId) {
      params.communityId = communityId;
    }
    if (fieldOfficerId) {
      params.fieldOfficerId = fieldOfficerId;
    }
    const res = await fetcher(
      "/household/download",
      {
        method: "GET",
        // responseType: "blob",
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      },
      params,
      true
    );

    return res;
  } catch (error) {
    throw error;
  }
};
