import fetcher from "../api/fetacher";

export const createRetailer = async (data) => {
  try {
    const accessToken = localStorage.getItem("accessToken");

    const res = await fetcher("/retailer", {
      method: "POST",
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
      body: JSON.stringify(data),
      cache: "no-store",
    });
    return res;
  } catch (error) {
    throw error;
  }
};

export const updateRetailer = async (data) => {
  try {
    const res = await fetcher("/retailer", {
      method: "PATCH",
      body: JSON.stringify(data),
      cache: "no-store",
    });
    return res;
  } catch (error) {
    throw error;
  }
};
export const getAllRetailer = async ({
  page,
  size,
  nameOrPhoneNumber,
  stateId,
  lgaId,
  fieldOfficerId,
}) => {
  try {
    const accessToken = localStorage.getItem("accessToken");
    const params = {
      page,
      size,
    };
    if (stateId) {
      params.stateId = stateId;
    }
    if (lgaId) {
      params.lgaId = lgaId;
    }
    if (nameOrPhoneNumber) {
      params.nameOrPhoneNumber = nameOrPhoneNumber;
    }
    if (fieldOfficerId) {
      params.fieldOfficerId = fieldOfficerId;
    }
    const res = await fetcher(
      "/retailer",
      {
        method: "GET",
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      },
      params,
      true
    );
    return res;
  } catch (error) {
    throw error;
  }
};

export const getAllRetailerList = async () => {
  try {
    const accessToken = localStorage.getItem("accessToken");
    const res = await fetcher("/retailer/list", {
      method: "GET",
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });
    return res;
  } catch (error) {
    throw error;
  }
};

export const getRetailerDetail = async (id) => {
  try {
    const accessToken = localStorage.getItem("accessToken");
    const res = await fetcher(`/retailer/${id}`, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });
    return res;
  } catch (error) {
    throw error;
  }
};
export const getRetailerSummary = async (id) => {
  try {
    const accessToken = localStorage.getItem("accessToken");
    const res = await fetcher(`/retailer/summary/${id}`, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });
    return res;
  } catch (error) {
    throw error;
  }
};

export const downloadRetailer = async ({
  nameOrPhoneNumber,
  stateId,
  lgaId,
  fieldOfficerId,
}) => {
  try {
    const accessToken = localStorage.getItem("accessToken");
    const params = {};
    if (nameOrPhoneNumber) {
      params.nameOrPhoneNumber = nameOrPhoneNumber;
    }
    if (stateId) {
      params.stateId = stateId;
    }
    if (lgaId) {
      params.lgaId = lgaId;
    }

    if (fieldOfficerId) {
      params.fieldOfficerId = fieldOfficerId;
    }
    const res = await fetcher(
      "/retailer/download",
      {
        method: "GET",
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      },
      params,
      true
    );

    return res;
  } catch (error) {
    throw error;
  }
};
