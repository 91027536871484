/* eslint-disable import/no-extraneous-dependencies */
/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useRef, useState, useEffect, forwardRef } from "react";
import { PiUsers } from "react-icons/pi";
import { IoCloseOutline } from "react-icons/io5";
import InputText from "../input/InputText";
import toast from "react-hot-toast";
// import useAggregator from "../../hooks/useAggregator";
import SearchableDropdown2 from "../input/SearchableDropdown2";

// import { getState, getLgaByState } from "../../ds/resource";
import { Formik } from "formik";
import { Form } from "react-router-dom";
import { useSelector } from "react-redux";
import ReactDatePicker from "react-datepicker";

import moment from "moment";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import useCommunity from "../../hooks/useCommunity";
import { createCommunitySchema } from "../../utils/validationSchema/communitySchema";
export const CommunityModal = ({
  states,
  lgas,
  closeModal,
  requestType,
  getAllCommunity,
  useerData,
  setSelectedStateId,
}) => {
  const modalRef = useRef(null);
  const [loading, setLoading] = useState(false);
  const [formatStartDate, setFormatStartDate] = useState(null);
  const [formatEndDate, setFormatEndDate] = useState(null);
  const { createNewCommunity, updateNewCommunity } = useCommunity();

  const initialValues = {
    localGovernmentId: requestType === "edit" ? useerData?.localGovernment : "",
    stateId: requestType === "edit" ? useerData?.state : "",
    name: requestType === "edit" ? useerData?.name : "",
  };

  const ExampleCustomInput = forwardRef(({ value, onClick }, ref) => (
    <div className="flex flex-col gap-2">
      <div
        className="example-custom-input border border-gray-300 h-[44px] w-[140px] md:w-[200px] lg:w-[280px] p-2  rounded-md cursor:pointer"
        onClick={onClick}
        ref={ref}
      >
        <h3>{value ? value : "Select date"}</h3>
      </div>
    </div>
  ));

  const onSubmit = async (data) => {
    try {
      if (requestType === "edit") {
        setLoading(true);
        const res = await updateNewCommunity({
          ...data,
          id: useerData.id,
        });
        if (res.code === "00") {
          toast.success("Aggregator updated");
          closeModal();
        }
        if (res.code !== "00") {
          setLoading(false);
          toast.error(res.message || "Error updating waybill");
        }
      }
      if (requestType !== "edit") {
        setLoading(true);
        const res = await createNewCommunity(data);
        if (res.code === "00") {
          toast.success("Community created");
          closeModal();
        }
        if (res.code !== "00") {
          setLoading(false);
          toast.error(res.message || "Error creating Community");
        }
      }
    } catch (error) {
      toast.error(error.message || "something went wrong");
    } finally {
      setLoading(false);
      setTimeout(() => {
        getAllCommunity();
      }, 1000);
    }
  };

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={createCommunitySchema}
      onSubmit={onSubmit}
    >
      {({ handleChange, errors, values, handleSubmit, setFieldValue }) => {
        return (
          <Form style={{ height: "500px" }}>
            <div className="flex flex-col gap-y-[12px] min-w-[300px] md:min-w-[450px] lg:w-[465px]  bg-white p-4 ">
              <div className="flex justify-between">
                <div className="">
                  <h1 className="capitalize font-bold">
                    {requestType === "edit"
                      ? "Edit Community"
                      : "create Community"}
                  </h1>
                </div>
                <IoCloseOutline
                  style={{ width: 26, height: 26, cursor: "pointer" }}
                  onClick={() => closeModal()}
                />
              </div>
              <div className="w-full">
                <InputText
                  label={"Community Name"}
                  name="name"
                  error={errors.name}
                  value={values.name}
                  fieldRequired={true}
                  className={"w-full"}
                  placeholder={"Enter community name"}
                  handleChange={handleChange}
                />
              </div>
              <div className="w-full">
                <SearchableDropdown2
                  label={"State"}
                  options={states}
                  error={errors.stateId}
                  className={"w-full"}
                  placeholder="Select state"
                  handleChange={(e) => setFieldValue("stateId", e.value)}
                />
              </div>
              <div className="w-full">
                <SearchableDropdown2
                  label={"Lga"}
                  options={lgas}
                  defaultValue={{
                    label: useerData?.localGovernment,
                    value: useerData?.localGovernmentId,
                  }}
                  error={errors.localGovernmentId}
                  className={"w-full"}
                  placeholder="Select lga"
                  handleChange={(e) =>
                    setFieldValue("localGovernmentId", e.value)
                  }
                />
              </div>

              <div className="flex gap-2 justify-end">
                <button
                  className="p-[12px] rounded-[8px] w-max h-[40px] font-normal text-xs flex justify-center items-center gap-3 disabled:cursor-not-allowed border border-pfBlack md:w-272"
                  onClick={() => closeModal()}
                >
                  Cancel
                </button>

                {requestType === "edit" ? (
                  <button
                    type="submit"
                    onClick={handleSubmit}
                    className="bg-green-700 rounded-[8px] text-white flex justify-center items-center h-[40px] w-max p-[12px]"
                  >
                    {loading ? "Updating..." : "Save changes"}
                  </button>
                ) : (
                  <button
                    type="submit"
                    onClick={handleSubmit}
                    className="bg-green-700 rounded-[8px] text-white flex justify-center items-center h-[40px] w-max p-[12px]"
                  >
                    {loading ? "Creating..." : "Create Community"}
                  </button>
                )}
              </div>
            </div>
          </Form>
        );
      }}
    </Formik>
  );
};
