import React, { useEffect, useState } from "react";
import Logo from "../../assets/images/Logo.png";
import { CiUser } from "react-icons/ci";
import { FaKey } from "react-icons/fa";
import { BsBoxArrowRight } from "react-icons/bs";
import { Form, Formik } from "formik";
import { useNavigate } from "react-router-dom";
import { signInSchema } from "../../utils/validationSchema/authSchema";
// import useAuth from "../../hooks/useAuth";
import { useDispatch, useSelector } from "react-redux";
import { resetPasswordSchema } from "../../utils/validationSchema/resetPassword";
import { forgotPassword } from "../../ds/auth";

const ForgotPassword = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const { program, user } = useSelector((state) => state.auth);
  // const { resetCurrentUserPassword } = useAuth();
  const dispatch = useDispatch();

  const initialValues = {
    email: "",
  };

  const handleSubmit = async (data) => {
    try {
      setLoading(true);
      const res = await forgotPassword(data);
      localStorage.setItem("email", data?.email);
      if (res.code === "00") {
        navigate("/resetpassword");
      }
      setError(res.message);
    } catch (error) {
    } finally {
      setLoading(false);
    }
  };
  return (
    <div className="flex justify-center items-center h-screen bg-[#F9FAFB] p-[24px]">
      <div className="flex flex-col items-center justify-center w-[424px] bg-white h-max p-4 rounded-md">
        {/* <div className="flex flex-col items-center w-[400px]"> */}
        <div className="self-start w-[104px] h-[37.44px] mb-[14px]">
          <img
            src={Logo}
            alt="logo"
            style={{ width: "100%", height: "auto", objectFit: "contain" }}
          />
        </div>
        <div className="px-2 w-full">
          <Formik
            initialValues={initialValues}
            validationSchema={resetPasswordSchema}
            onSubmit={handleSubmit}
          >
            {({ handleChange, errors, values, handleSubmit }) => {
              return (
                <Form>
                  <div className="mb-[5px]">
                    {error && (
                      <p className="text-red-500 border border-red-500 bg-[#FEE4E2] h-[48px] flex items-center justify-center">
                        {error}
                      </p>
                    )}
                  </div>
                  <h4 className="text-xl font-bold">Reset Password</h4>
                  <p className="text-sm mb-3 text-gray-400 mb-[32px]">
                    Please provide your details to reset your log-in detail on
                    this site
                  </p>
                  <div className="mb-[32px] w-full">
                    <label htmlFor="password">Email</label>
                    <div className="flex items-center border border-gray-300 h-30 bg-gray-50">
                      <input
                        type="text"
                        className="p-2 w-full"
                        onChange={handleChange}
                        value={values?.email}
                        name="email"
                        placeholder="Enter Email"
                      />
                    </div>
                    {errors && errors.email && (
                      <p className="text-red-500">{errors.email}</p>
                    )}
                  </div>
                  <div className="mt-2 w-full">
                    <button
                      className="bg-green-900 rounded-[8px] w-full flex justify-center items-center p-4 text-white h-[44px]"
                      type="submit"
                    >
                      {loading ? "loading..." : "Submit Request"}
                    </button>
                  </div>
                  <div className="mt-2 w-full">
                    <button
                      className="bg-white text-green-900 rounded-[8px] w-full flex justify-center items-center p-4 h-8 text-sm"
                      onClick={() => navigate("/")}
                    >
                      Back to Log-in
                    </button>
                  </div>
                </Form>
              );
            }}
          </Formik>
        </div>
        {/* </div> */}
      </div>
    </div>
  );
};

export default ForgotPassword;
