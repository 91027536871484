import React, { useCallback, useEffect, useRef, useState } from "react";
import { useParams } from "react-router-dom";
import Avatar from "../../assets/images/Dropdown.png";
import TopCard from "../../components/card/TopCard";
import TopMiniCard from "../../components/card/TopMiniCard";
import CustomTab from "../../components/tabs/CustomTab";
import CustomTable from "../../components/table/CustomTable";
import PaginationTab from "../../components/pagination/PaginationTab";
import InputSearch from "../../components/input/InputSearch";
import useFieldOfficer from "../../hooks/useFieldOfficer";
import useHousehold from "../../hooks/useHousehold";
import useRetailer from "../../hooks/useRetailer";
import Modal from "../../components/Modal";
import useOutsideClick from "../../hooks/useOutsideClick";
import RetailerModal from "../../components/modals/RetailerModal";
import { CircularProgressbarWithChildren } from "react-circular-progressbar";
import { toMoney } from "../../utils/formatCurrency";

const householdHeaders = [
  "Household",
  "Retailer",
  "Field Officer",
  "Phone Number",
  "State",
  "Lga",
  "Date & Time",
];
const RetailerDetail = () => {
  const { id } = useParams();
  const wrapperRef = useRef(null);
  const [showProfile, setShowProfile] = useOutsideClick(wrapperRef);
  const [retailerDetail, setRetailerDetail] = useState();
  const [householdManaging, setHouseholdManaging] = useState();
  const [retailersSummary, setRetailersSummary] = useState(1);
  const [householdPage, setHouseholdPage] = useState(1);
  const [householdsManagingTotalPage, setHouseholdsManagingTotalPage] =
    useState();
  const { loading, gatAllRetailers, getRetailerDetails, retailerSummary } =
    useRetailer();
  const { gatAllHouseHolds } = useHousehold();

  const getRetailerDetail = useCallback(async () => {
    const res = await getRetailerDetails(id);
    setRetailerDetail(res);
  }, [id]);
  const getHouseholdManaging = useCallback(async () => {
    const res = await gatAllHouseHolds({ retailerId: id });
    setHouseholdManaging(res);
  }, [id]);
  const getRetailerSummary = useCallback(async () => {
    const res = await retailerSummary(id);
    setRetailersSummary(res?.data);
  }, [id]);

  const handleHouseholdTabPageChange = (data) => {
    if (data === 0) {
      setHouseholdPage(1);
    }
    setHouseholdPage(data + 1);
  };

  useEffect(() => {
    getRetailerDetail();
    getRetailerSummary();
    getHouseholdManaging();
  }, [getHouseholdManaging, getRetailerDetail, getRetailerSummary, id]);

  return (
    <div className="p-[24px] flex gap-[24px]">
      <div className=" p-[16px] border border-gray-300 w-[240px] rounded-md">
        <div className="flex justify-center w-full mb-[24px]">
          <div className="h-[120px] w-[120px] rounded-full">
            <img
              src={retailerDetail?.data?.image}
              alt="profile"
              style={{ width: "100%", height: "100%", objectFit: "contain" }}
            />
          </div>
        </div>
        <div className="mb-[24px] border border-gray-300">
          <button
            className="h-[40px] flex items-center justify-center w-full p-[12px] rounded-[8px]"
            onClick={() => setShowProfile(true)}
          >
            Edit
          </button>
        </div>
        <div className="flex flex-col gap-[24px]">
          <div>
            <div className="mb-[16px]">
              <p className="capitalize bg-[#FFFAEB] px-[16px] py-[6px]">
                basic details
              </p>
            </div>
            <div>
              <div className="px-[16px] mb-[12px]">
                <p className="text-[12px]">Name</p>
                <p className="text-[14px] font-bold">
                  {retailerDetail?.data?.firstName}{" "}
                  {retailerDetail?.data?.lastName}
                </p>
              </div>
              <div className="px-[16px] mb-[12px]">
                <p className="text-[12px]">Field Officer</p>
                <p className="text-[14px] font-bold">
                  {retailerDetail?.data?.fieldOfficer || "N/A"}
                </p>
              </div>
            </div>
          </div>
          <div>
            <div className="mb-[16px]">
              <p className="capitalize bg-[#FFFAEB] px-[16px] py-[6px]">
                Contact details
              </p>
            </div>
            <div>
              {/* <div className="px-[16px] mb-[12px]">
                <p className="text-[12px]">Email Address</p>
                <p className="text-[14px]">
                  {retailerDetail?.data?.email || "N/A"}
                </p>
              </div> */}
              <div className="px-[16px] mb-[12px]">
                <p className="text-[12px]">Phone Number</p>
                <p className="text-[14px] font-bold">
                  {retailerDetail?.data?.phoneNumber || "N/A"}
                </p>
              </div>
            </div>
          </div>
          <div>
            <div className="mb-[16px]">
              <p className="capitalize bg-[#FFFAEB] px-[16px] py-[6px]">
                Location details
              </p>
            </div>
            <div>
              <div className="px-[16px] mb-[12px]">
                <p className="text-[12px]">State</p>
                <p className="text-[14px] font-bold">
                  {retailerDetail?.data?.state
                    ? retailerDetail?.data?.state
                    : "N/A"}
                </p>
              </div>
              <div className="px-[16px] mb-[12px]">
                <p className="text-[12px]">Lga's</p>
                <p className="text-[14px] font-bold">
                  {retailerDetail?.data?.localGovernment}
                </p>
              </div>
              <div className="px-[16px] mb-[12px]">
                <p className="text-[12px]">Joining Date&Time</p>
                <p className="text-[14px] font-bold">
                  {retailerDetail?.data?.createdAt?.split(" ")[0] || "N/A"} |{" "}
                  {retailerDetail?.data?.createdAt?.split(" ")[1] || "N/A"}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="flex flex-col gap-[24px] w-[calc(100%-240px)]">
        <div className="flex gap-[16px]">
          <div className="basis-[50%] flex flex-wrap w-[449.5px] gap-[16px]">
            <TopMiniCard
              css={"basis-[100%] bg-[#FFFAEB]"}
              title={"Total households"}
              subtitle={retailersSummary?.totalHouseHold || 0}
              // subtlet={"Households"}
            />
            <TopMiniCard
              css={"basis-[100%] bg-[#EFF8FF]"}
              title={"Total sales value"}
              subtitle={`₦${toMoney(retailersSummary?.totalSales || 0)}`}
              subtlet={"Last order: 3 May 2024"}
              textColor={"text-[#2E90FA]"}
            />
          </div>
          <div className=" basis-[50%] flex flex-col gap-[16px]">
            <div className="px-[16px] py-[18px] flex justify-between items-center gap-[16px border border-gray-300 rounded-[8px] h-[114px] basis-[100%]">
              <div>
                <p className="mb-[10px]">Product with the highest value</p>
                <p className="flex gap-[15px] items-center text-sm">
                  Product:{" "}
                  <span className="font-bold text-sm">
                    {retailersSummary?.highestSalesProduct || "N/A"}
                  </span>
                </p>
                <p className="flex gap-[15px] items-center text-sm">
                  Total value:{" "}
                  <span className="font-bold text-sm">
                    {" "}
                    {`₦${toMoney(
                      retailersSummary?.highestSalesProductValue || 0
                    )}`}
                  </span>
                </p>
              </div>
              <div className="w-[90px] h-[90px]">
                <CircularProgressbarWithChildren
                  value={80}
                  strokeWidth={17}
                  styles={{
                    path: {
                      // Path color
                      stroke: `#7F56D9`,
                      // Whether to use rounded or flat corners on the ends - can use 'butt' or 'round'
                      strokeLinecap: "butt",
                      // // Customize transition animation
                      // transition: "stroke-dashoffset 0.5s ease 0s",
                      // // Rotate the path
                      // transform: "rotate(0.25turn)",
                      // transformOrigin: "center center",
                    },
                  }}
                >
                  <div className="flex flex-col justify-center">
                    <p className="text-center">Sales</p>
                    <p className="text-center font-bold">
                      {toMoney(retailersSummary?.highestSalesProductValue || 0)}
                    </p>
                  </div>
                </CircularProgressbarWithChildren>
              </div>
            </div>
            <div className="px-[16px] py-[18px] flex justify-between items-center gap-[16px border border-gray-300 rounded-[8px] h-[114px] basis-[100%]">
              <div>
                <p className="mb-[10px]">Product with the lowest value</p>
                <p className="flex gap-[15px] items-center text-sm">
                  Product:{" "}
                  <span className="font-bold text-sm">
                    {retailersSummary?.lowestSalesProduct || "N/A"}
                  </span>
                </p>
                <p className="flex gap-[15px] items-center text-sm">
                  Total value:{" "}
                  <span className="font-bold text-sm">
                    {" "}
                    {`₦${toMoney(
                      retailersSummary?.lowestSalesProductValue || 0
                    )}`}
                  </span>
                </p>
              </div>
              <div className="w-[90px] h-[90px]">
                <CircularProgressbarWithChildren
                  value={10}
                  strokeWidth={17}
                  counterClockwise={true}
                  styles={{
                    path: {
                      // Path color
                      stroke: `#F79009`,
                      // Whether to use rounded or flat corners on the ends - can use 'butt' or 'round'
                      strokeLinecap: "butt",
                      // // Customize transition animation
                      // transition: "stroke-dashoffset 0.5s ease 0s",
                      // // Rotate the path
                      // transform: "rotate(0.25turn)",
                      // transformOrigin: "center center",
                    },
                  }}
                >
                  <div className="flex flex-col justify-center">
                    <p className="text-center">Sales</p>
                    <p className="text-center font-bold">
                      {" "}
                      {toMoney(retailersSummary?.highestSalesProductValue || 0)}
                    </p>
                  </div>
                </CircularProgressbarWithChildren>
              </div>
            </div>
          </div>
        </div>
        <div>
          <CustomTab titles={["Households"]}>
            <div>
              {/* <div className="px-[16px] py-[12px]">
                <InputSearch
                  placeholder={"search by name, or phone number"}
                  css={"w-full"}
                />
              </div> */}
              <CustomTable
                headers={householdHeaders}
                rows={householdManaging?.data?.content.map((data, index) => {
                  return {
                    household: data.household ? data.household : "N/A",
                    retailer: data.retailer ? data.retailer : "N/A",
                    fieldofficer: data.fieldOfficer ? data.fieldOfficer : "N/A",
                    phoneNumber: data.phoneNumber ? data.phoneNumber : "N/A",
                    state: data.state ? data.state : "N/A",
                    localGovernment: data.localGovernment
                      ? data.localGovernment
                      : "N/A",
                    "date & time": data.createdAt ? data.createdAt : "N/A",
                  };
                })}
              />
              <div className="pt-[12px] pb-[16px] px-[24px] flex justify-end">
                <PaginationTab
                  pageIndex={householdPage}
                  pageCount={householdManaging?.data?.totalPages}
                  gotoPage={handleHouseholdTabPageChange}
                  // pageSize={10}
                  // canPreviousPage={""}
                  // canNextPage={""}
                  // pageOptions,
                  setPageSize={""}
                  // useAlternatePagination={false}
                />
              </div>
            </div>
            <div>
              {/* <div className="px-[16px] py-[12px]">
                <InputSearch
                  placeholder={"search by name, or phone number"}
                  css={"w-full"}
                />
              </div> */}
              <CustomTable headers={householdHeaders} />
              {/* <div className="pt-[12px] pb-[16px] px-[24px] flex justify-end">
                <PaginationTab
                  pageIndex={1}
                  pageCount={10}
                  gotoPage={""}
                  // pageSize={10}
                  canPreviousPage={""}
                  canNextPage={""}
                  // pageOptions,
                  setPageSize={""}
                  // useAlternatePagination={false}
                />
              </div> */}
            </div>
          </CustomTab>
        </div>
      </div>
      {showProfile && (
        <Modal
          variant="default"
          refProp={wrapperRef}
          closeModal={() => setShowProfile(false)}
        >
          <RetailerModal
            closeModal={() => setShowProfile(false)}
            useerData={retailerDetail?.data}
            requestType={"edit"}
            getAllRetailers={getRetailerDetail}
          />
        </Modal>
      )}
    </div>
  );
};

export default RetailerDetail;
