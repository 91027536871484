import React, { useState, Children } from "react";
import { TabItem, Tabs, View } from "@aws-amplify/ui-react";

const CustomTab = ({ children, titles }) => {
  const arrayChildren = Children.toArray(children);
  return (
    <Tabs.Container defaultValue="1">
      <Tabs.List>
        {titles.map((data, index) => {
          return (
            <>
              <Tabs.Item value={`${index + 1}`} key={index}>
                {data}
              </Tabs.Item>
            </>
          );
        })}
      </Tabs.List>
      {children.map(
        (arrayChildren,
        (child, index) => {
          return (
            <>
              <Tabs.Panel value={`${index + 1}`} key={index}>
                {child}
              </Tabs.Panel>
            </>
          );
        })
      )}
    </Tabs.Container>
  );
};

export default CustomTab;
