import React, { useState } from "react";
import { resetPasswordSchema } from "../../utils/validationSchema/resetPassword";
import { useNavigate } from "react-router-dom";
import { Form, Formik } from "formik";
import { IoEyeOutline, IoEyeOffOutline } from "react-icons/io5";
import { IoCloseOutline } from "react-icons/io5";
import InputText from "../input/InputText";
import { createAdminSchema } from "../../utils/validationSchema/adminSchema";
const EditProfileModal = ({
  states,
  lgas,
  closeModal,
  requestType,
  getAdmin,
  useerData,
  setSelectedStateId,
}) => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const [viewPassword, setViewPassword] = useState(false);
  //   const { program, user } = useSelector((state) => state.auth);
  // const { resetCurrentUserPassword } = useAuth();
  //   const dispatch = useDispatch();

  const initialValues = {
    firstName: useerData?.firstName || "",
    lastName: useerData?.lastName || "",
    phoneNumber: useerData?.phoneNumber || "",
    email: useerData?.email || "",
  };

  const handleSubmit = async (data) => {
    // try {
    //   setLoading(true);
    //   const res = await resetCurrentUserPassword(data);
    //   navigate("/");
    //   setError(res.message);
    // } catch (error) {
    // } finally {
    //   setLoading(false);
    // }
  };
  return (
    <Formik
      initialValues={initialValues}
      validationSchema={createAdminSchema}
      onSubmit={handleSubmit}
    >
      {({ handleChange, errors, values, handleSubmit, setFieldValue }) => {
        return (
          <Form style={{ height: "500px" }}>
            <div className="flex flex-col gap-y-[12px] min-w-[300px] md:min-w-[450px] lg:w-[640px]  bg-white p-4 ">
              <div className="flex justify-between">
                <div className="">
                  <h1 className="capitalize font-bold">Edit your profile</h1>
                </div>
                <IoCloseOutline
                  style={{ width: 26, height: 26, cursor: "pointer" }}
                  onClick={() => closeModal()}
                />
              </div>
              <div className="w-full">
                <InputText
                  label={"First Name"}
                  name="firstName"
                  error={errors.firstName}
                  value={values.firstName}
                  fieldRequired={true}
                  className={"w-full"}
                  placeholder={"Enter first name"}
                  handleChange={handleChange}
                />
              </div>
              <div className="w-full">
                <InputText
                  label={"Last Name"}
                  name="lastName"
                  error={errors.lastName}
                  value={values.lastName}
                  fieldRequired={true}
                  className={"w-full"}
                  placeholder={"Enter last name"}
                  handleChange={handleChange}
                />
              </div>
              <div className="w-full">
                <InputText
                  label={"Company Email"}
                  name="email"
                  error={errors.email}
                  value={values.email}
                  fieldRequired={true}
                  className={"w-full"}
                  placeholder={"Enter email address"}
                  handleChange={handleChange}
                />
              </div>
              <div className="w-full">
                <InputText
                  label={"Phone Number"}
                  name="phoneNumber"
                  error={errors.phoneNumber}
                  value={values.phoneNumber}
                  fieldRequired={true}
                  className={"w-full"}
                  placeholder={"Enter phone number"}
                  handleChange={handleChange}
                />
              </div>

              <div className="flex gap-2 justify-end">
                <button
                  className="p-[12px] rounded-[8px] w-max h-[40px] font-normal text-xs flex justify-center items-center gap-3 disabled:cursor-not-allowed border border-pfBlack md:w-272"
                  onClick={() => closeModal()}
                >
                  Cancel
                </button>

                <button
                  type="submit"
                  onClick={handleSubmit}
                  className="bg-green-900 rounded-[8px] text-white flex justify-center items-center h-[40px] w-max p-[12px]"
                >
                  {loading ? "Updating..." : "Save changes"}
                </button>
              </div>
            </div>
          </Form>
        );
      }}
    </Formik>
  );
};

export default EditProfileModal;
