import React, { forwardRef, useCallback, useEffect, useState } from "react";
import TopCard from "../../components/card/TopCard";
import LineCharts from "../../components/charts/LineChart";
import BarCharts from "../../components/charts/BarChart";
import PieCharts from "../../components/charts/PieChart";
import Avatar from "../../assets/images/Dropdown.png";
import { useSelector } from "react-redux";
import StackedBarChart from "../../components/charts/StackedBarChart";
import useDashboard from "../../hooks/useDashboard";
//date range
import moment from "moment";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import SearchableDropdown from "../../components/input/SearchableDropdown";
import useResource from "../../hooks/useResource";
import { toMoney } from "../../utils/formatCurrency";
import useTabTitle from "../../hooks/useTabTitle";

const Dashboard = () => {
  const { islogin, user } = useSelector((state) => state.auth);
  //for date range
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [retailerStartDate, setRetailerStartDate] = useState("");
  const [retailerEndDate, setRetailerEndDate] = useState("");
  const [houseHoldStartDate, setHouseholdStartDate] = useState("");
  const [householdEndDate, setHouseholdEndDate] = useState("");

  const [formatStartDate, setFormatStartDate] = useState(
    moment().subtract(1, "months").format("YYYY-MM-DD")
  );
  const [formatEndDate, setFormatEndDate] = useState(
    moment().format("YYYY-MM-DD")
  );
  const [householdRecordStartDate, setHouseholdRecordStartDate] = useState(
    moment().subtract(1, "months").format("YYYY-MM-DD")
  );
  const [householdRecordEndDate, setHouseholdRecordEndDate] = useState(
    moment().format("YYYY-MM-DD")
  );
  const [retailerRecordStartDate, setRetailerRecordStartDate] = useState(
    moment().subtract(1, "months").format("YYYY-MM-DD")
  );
  const [retailerRecordEndDate, setretailerRecordEndDate] = useState(
    moment().format("YYYY-MM-DD")
  );

  const [allStates, setAllstates] = useState([]);
  const [householdStateId, setHouseholdStateId] = useState();
  const [retailerStateId, setRetailerStateId] = useState();
  const [allSalesReports, setAllSalesReports] = useState();
  const [allProductSalesReports, setAllProductSalesReports] = useState([]);
  const [allProductRegionReports, setAllProductRegionReports] = useState([]);
  const [allRetailerReports, setAllRetailerReports] = useState();
  const [householdRecordYear, setHouseholdRecordYear] = useState();
  const [retailerRecordYear, setRetailerRecordYear] = useState();
  const [productPerformanceYear, setProductPerformanceYear] = useState();

  const [allRetailerSalesReports, setAllRetailerSalesReports] = useState();
  const [allHouseholdSalesReports, setAllHouseholdSalesReports] = useState();
  const [allHouseholdReports, setAllHouseholdReports] = useState();
  const [productSalesYear, setProductSalesYear] = useState({
    label: "THIS_YEAR",
    value: "THIS_YEAR",
  });
  const [retailerGenderSalesYear, setRetailerGenderSalesYear] = useState({
    label: "THIS_YEAR",
    value: "THIS_YEAR",
  });
  const [householdGenderSalesYear, setHouseholdGenderSalesYear] = useState({
    label: "THIS_YEAR",
    value: "THIS_YEAR",
  });
  const [productRegionState, setProductRegionState] = useState(null);

  const { getAllStates } = useResource();
  const [allHouseholdOnboardingReports, setAllHouseholdOnboardingReports] =
    useState();
  const [allRetailerOnboardingReports, setAllRetailerOnboardingReports] =
    useState();
  const [allRetailerGenderSalesReports, setAllRetailerGenderSalesReports] =
    useState();
  const [allRetailerGenderDateReports, setAllRetailerGenderDateReports] =
    useState();
  const [
    allRetailerGenderOnboardingReports,
    setAllRetailerGenderOnboardingReports,
  ] = useState();
  const { title } = useTabTitle({ title: "Dashboard" });
  const {
    getAllSalesReports,
    getAllRetailerReports,
    getAllRetailerSalesReports,
    getAllRetailerGenderOnboardingReports,
    getAllHouseholdReports,
    getAllHouseholdsSalesReports,
    getAllHouseholdsOnboardingReports,
    getAllRetailerOnboardingReports,
    getAllRetailerGenderSalesReports,
    getAllProductSalesReports,
    getAllProductRegionReports,
    getAllRetailerGenderDateReports,
  } = useDashboard();
  const listtwo = Object.keys(
    Object.assign({}, ...(allProductSalesReports || []))
  ).filter((data) => data !== "date");
  const listRegionSales = Object.keys(
    Object.assign({}, ...(allProductRegionReports || []))
  ).filter((data) => data !== "lga");

  const getAllSalesReport = async () => {
    const res = await getAllSalesReports();
    setAllSalesReports(res);
  };
  const getAllRetailersReport = async () => {
    const res = await getAllRetailerReports();
    // console.log({ res });
    setAllRetailerReports(res);
  };
  const getAllRetailersGenderOnboardingReports = useCallback(async () => {
    const res = await getAllRetailerGenderOnboardingReports();
    setAllRetailerGenderOnboardingReports(res);
  }, []);
  const getAllRetailerSalesReport = useCallback(async () => {
    const res = await getAllRetailerSalesReports({
      stateId: retailerStateId,
      calendarType: retailerRecordYear,
    });
    setAllRetailerSalesReports(res);
  }, [retailerRecordYear, retailerStateId]);
  const getAllHouseholdSalesReport = useCallback(async () => {
    const res = await getAllHouseholdsSalesReports({
      stateId: householdStateId,
      calendarType: householdRecordYear,
    });
    setAllHouseholdSalesReports(res);
  }, [householdRecordYear, householdStateId]);
  const getAllHouseholdOnboardingReport = useCallback(async () => {
    const res = await getAllHouseholdsOnboardingReports({
      stateId: householdStateId,
      CalendarType: householdRecordYear,
    });
    setAllHouseholdOnboardingReports(res);
  }, [householdRecordYear, householdStateId]);
  const getAllRetailerOnboardingReport = useCallback(async () => {
    const res = await getAllRetailerOnboardingReports({
      stateId: retailerStateId,
      calendarType: retailerRecordYear,
    });
    setAllRetailerOnboardingReports(res);
  }, [retailerRecordEndDate, retailerRecordStartDate, retailerStateId]);

  const getAllHouseholdsReport = useCallback(async () => {
    const res = await getAllHouseholdReports({
      CalendarType: householdGenderSalesYear,
    });
    setAllHouseholdReports(res);
  }, [householdGenderSalesYear]);
  const getAllRetailerGenderSalesReport = useCallback(async () => {
    const res = await getAllRetailerGenderSalesReports({
      CalendarType: retailerGenderSalesYear,
    });
    setAllRetailerGenderSalesReports(res);
  }, [retailerGenderSalesYear]);
  const getAllRetailerGenderDateReport = useCallback(async () => {
    const res = await getAllRetailerGenderDateReports({
      CalendarType: retailerGenderSalesYear,
    });
    setAllRetailerGenderDateReports(res);
  }, [retailerGenderSalesYear]);
  const getAllProductSalesReport = useCallback(async () => {
    const res = await getAllProductSalesReports({
      CalendarType: productSalesYear,
    });
    setAllProductSalesReports(res?.data);
  }, [productSalesYear]);
  const getAllProductregionReports = useCallback(async () => {
    const res = await getAllProductRegionReports({
      stateId: productRegionState,
      calendarType: productPerformanceYear,
    });
    setAllProductRegionReports(res?.data);
  }, [productPerformanceYear, productRegionState]);
  useEffect(() => {
    const getAllState = async () => {
      const res = await getAllStates();
      const list = res.data.map((item) => {
        return {
          label: item.name,
          value: item.id,
        };
      });
      setAllstates([...list]);
    };
    getAllState();
  }, []);
  useEffect(() => {
    getAllSalesReport();
    getAllRetailersReport();
    getAllRetailerSalesReport();
    getAllRetailersGenderOnboardingReports();
    getAllHouseholdsReport();
    getAllHouseholdSalesReport();
    getAllHouseholdOnboardingReport();
    getAllRetailerOnboardingReport();
    getAllRetailerGenderSalesReport();
    getAllProductSalesReport();
    getAllProductregionReports();
    getAllRetailerGenderDateReport();
  }, [
    formatStartDate,
    formatEndDate,
    householdGenderSalesYear,
    productSalesYear,
    retailerGenderSalesYear,
    householdStateId,
    retailerStateId,
    retailerRecordStartDate,
    retailerRecordEndDate,
    householdRecordStartDate,
    householdRecordEndDate,
    productPerformanceYear,
    retailerRecordYear,
    householdRecordYear,
    productRegionState,
    retailerStateId,
    householdStateId,
  ]);

  // useEffect(() => {
  //   document.title = "Dashboard";
  // }, []);

  const onChange = (dates) => {
    const [start, end] = dates;

    setStartDate(start);
    setEndDate(end);

    start
      ? setFormatStartDate(moment(start).format("YYYY-MM-DD"))
      : setFormatStartDate(null);

    end
      ? setFormatEndDate(moment(end).format("YYYY-MM-DD"))
      : setFormatEndDate(null);
  };
  const onHouseholdRecordChange = (dates) => {
    const [start, end] = dates;

    setHouseholdStartDate(start);
    setHouseholdEndDate(end);

    start
      ? setHouseholdRecordStartDate(moment(start).format("YYYY-MM-DD"))
      : setHouseholdRecordStartDate(null);

    end
      ? setHouseholdRecordEndDate(moment(end).format("YYYY-MM-DD"))
      : setHouseholdRecordEndDate(null);
  };
  const onRetailerRecordChange = (dates) => {
    const [start, end] = dates;

    setRetailerStartDate(start);
    setRetailerEndDate(end);

    start
      ? setRetailerRecordStartDate(moment(start).format("YYYY-MM-DD"))
      : setRetailerRecordStartDate(null);

    end
      ? setretailerRecordEndDate(moment(end).format("YYYY-MM-DD"))
      : setretailerRecordEndDate(null);
  };

  const ExampleCustomInput = forwardRef(({ value, onClick }, ref) => (
    <div className="flex flex-col gap-2">
      <div
        className="example-custom-input border border-gray-300 h-[44px] w-[260px] md:w-[170px] lg:w-[220px] p-2 rounded-md"
        onClick={onClick}
        ref={ref}
      >
        <h3>{value ? value : "Select date range"}</h3>
      </div>
    </div>
  ));

  return (
    <div className="p-[24px] flex flex-col gap-[32px]">
      {/* <div className="flex gap-x-[12px]">
        <div className="h-[60px] w-[60px] rounded-full">
          <img
            src={Avatar}
            alt="avatar"
            style={{ width: "100%", height: "auto", objectFit: "contain" }}
          />
        </div>
        <div>
          <h1 className="font-bold text-xl">
            Welcome back, {user?.firstName} {user?.lastName}
          </h1>
          <p className="text-sm">View all data associated with gain</p>
        </div>
      </div> */}
      <div className="flex gap-[16px]">
        <TopCard
          title={"This Years Sales Record"}
          subtitle={`Volume: ${
            allSalesReports?.data?.thisYearTotalSalesVolume || 0
          }`}
          userCount={`₦${toMoney(
            allSalesReports?.data?.thisYearTotalSalesValue || 0
          )}`}
          css={"bg-[#EDFCF2] border border-gray-300 "}
        />
        <TopCard
          title={"Last Years Sales Record"}
          subtitle={`Volume: ${
            allSalesReports?.data?.lastYearTotalSalesVolume || 0
          }`}
          userCount={`₦${toMoney(
            allSalesReports?.data?.lastYearTotalSalesValue || 0
          )}`}
          css={"bg-[#F9FAFB] border border-gray-300"}
        />
      </div>
      <div className="h-max border border-gray-300 rounded-md px-[16px] py-[18px] relative">
        <div className="border-b border-gray-300">
          <div className="flex flex-col lg:flex-row lg:justify-between mb-[20px] h-max">
            <div className="mb-2">
              <p className="">Product Sales Records</p>
              {/* <p className="text-sm">Track the rate of collection over time.</p> */}
            </div>
            <div className="w-[200px]">
              <SearchableDropdown
                options={[
                  { label: "LAST_YEAR", value: "LAST_YEAR" },
                  { label: "THIS_YEAR", value: "THIS_YEAR" },
                  { label: "THIS_MONTH", value: "THIS_MONTH" },
                ]}
                selectedOption={productSalesYear}
                defaultValue={null}
                placeholder="select option"
                className={"w-full"}
                handleChange={(e) => {
                  setProductSalesYear(e);
                }}
              />
            </div>
            {/* <div className="flex rounded-md w-max">
              {[
                { name: "Last year", year: "LAST_YEAR" },
                { name: "This year", year: "THIS_YEAR" },
                { name: "This month", year: "THIS_MONTH" },
              ]?.map((data, index) => {
                return (
                  <button
                    key={index}
                    className={`w-max border border-gray-300 p-2 text-sm ${
                      index === 2 ? "rounded-br rounded-tr" : ""
                    } ${
                      index === 0
                        ? "text-sm rounded-tl rounded-bl bg-[#F9FAFB]"
                        : ""
                    }`}
                    onClick={() => setProductSalesYear(data.year)}
                  >
                    {data.name}
                  </button>
                );
              })}
            </div> */}
          </div>
        </div>
        <div className="h-[500px]">
          <LineCharts
            dataKeyX={"date"}
            lineData={allProductSalesReports}
            lineDataTwo={listtwo || []}
          />
        </div>
      </div>
      <div className="h-max border border-gray-300 rounded-md px-[16px] py-[18px] relative">
        <div className="border-b border-gray-300">
          <div className="flex flex-col lg:flex-row lg:justify-between mb-[20px] h-max ">
            <div className="mb-2">
              <p className="">Product performance by state</p>
              {/* <p className="text-sm">Track the rate of collection over time.</p> */}
            </div>
            <div className="flex gap-[10px] w-max">
              {/* <div className="w-full">
                <DatePicker
                  selected={startDate}
                  onChange={onChange}
                  startDate={startDate}
                  endDate={endDate}
                  selectsRange
                  customInput={<ExampleCustomInput />}
                  wrapperClassName="w-full"
                  // containerStyle={{ width: "100%" }}
                  showYearDropdown
                  yearDropdownItemNumber={100}
                  scrollableYearDropdown
                  isClearable
                />
              </div> */}
              <div className="w-[200px]">
                <SearchableDropdown
                  options={[
                    { label: "THIS_YEAR", value: "THIS_YEAR" },
                    { label: "THIS_MONTH", value: "THIS_MONTH" },
                    { label: "THIS_WEEK", value: "THIS_WEEK" },
                    { label: "THIS_QUARTER", value: "THIS_QUARTER" },
                    { label: "LAST_MONTH", value: "LAST_MONTH" },
                    { label: "LAST_WEEK", value: "LAST_WEEK" },
                  ]}
                  selectedOption={productPerformanceYear}
                  defaultValue={null}
                  placeholder="select option"
                  className={"w-full"}
                  handleChange={(e) => {
                    setProductPerformanceYear(e);
                  }}
                />
              </div>
              <div className="w-[200px]">
                <SearchableDropdown
                  options={allStates}
                  selectedOption={productRegionState}
                  defaultValue={null}
                  placeholder="state"
                  className={"w-full"}
                  handleChange={(e) => {
                    setProductRegionState(e);
                  }}
                />
              </div>
            </div>
          </div>
        </div>
        <div className="h-[500px]">
          <LineCharts
            dataKeyX={"lga"}
            lineData={allProductRegionReports || []}
            lineDataTwo={listRegionSales || []}
          />
        </div>
      </div>
      <div className="h-max border border-gray-300 rounded-md px-[16px] py-[18px] relative">
        <div className="border-b border-gray-300">
          <div className="flex flex-col lg:flex-row lg:justify-between mb-[20px] h-max ">
            <div className="mb-2">
              <p className="">Retailer Gender Sales Records</p>
            </div>
            <div className="w-[200px]">
              <SearchableDropdown
                options={[
                  { label: "LAST_YEAR", value: "LAST_YEAR" },
                  { label: "THIS_YEAR", value: "THIS_YEAR" },
                  { label: "THIS_MONTH", value: "THIS_MONTH" },
                ]}
                selectedOption={retailerGenderSalesYear}
                defaultValue={null}
                placeholder="select option"
                className={"w-full"}
                handleChange={(e) => {
                  setRetailerGenderSalesYear(e);
                }}
              />
            </div>
            {/* <div className="flex rounded-md w-max">
              {[
                { name: "Last year", year: "LAST_YEAR" },
                { name: "This year", year: "THIS_YEAR" },
                { name: "This month", year: "THIS_MONTH" },
              ]?.map((data, index) => {
                return (
                  <button
                    key={index}
                    className={`w-max border border-gray-300 p-2 text-sm cursor-pointer ${
                      index === 2 ? "rounded-br rounded-tr" : ""
                    } ${
                      index === 0
                        ? "text-sm rounded-tl rounded-bl bg-[#F9FAFB]"
                        : ""
                    }`}
                    onClick={() => setRetailerGenderSalesYear(data.year)}
                  >
                    {data.name}
                  </button>
                );
              })}
            </div> */}
          </div>
        </div>
        <div className="h-max px-[16px] py-[16px] ">
          <div className="min-h-[500px] flex gap-[24px] ">
            <div className="h-max basis-[50%] mb-[16px] border border-gray-300 p-[16px] rounded-md">
              <div className="flex gap-[16px] mb-[16px]">
                <div
                  className={`flex justify-between lg:flex-row bg-[#FFFAEB] px-[16px] py-[18px] rounded-[8px] w-full`}
                >
                  <div className="flex flex-col gap-[8px]">
                    <h1 className="capitalize text-[14px]">
                      Retailers Onboarded
                    </h1>
                    <p className="font-bold text-[24px]">
                      {allRetailerGenderOnboardingReports?.data
                        ?.totalRetailer || 0}
                    </p>
                    <p className="text-sm">Total Retailers</p>
                  </div>
                  <div className="flex flex-col gap-[8px]">
                    <p className="font-bold text-[24px] mt-[30px]">
                      {allRetailerGenderOnboardingReports?.data?.maleRetailer ||
                        0}
                    </p>
                    <p className="text-sm">Male</p>
                  </div>
                  <div className="flex flex-col gap-[8px]">
                    <p className="font-bold text-[24px] mt-[30px]">
                      {" "}
                      {allRetailerGenderOnboardingReports?.data
                        ?.femaleRetailer || 0}
                    </p>
                    <p className="text-sm">Female</p>
                  </div>
                </div>
              </div>
              <div className="flex gap-[16px] mb-[16px]">
                <TopCard
                  title={"Total Value of Sales By Retailers"}
                  subtitle={`volume: ${allRetailerGenderSalesReports?.data?.totalVolume}`}
                  userCount={`₦${toMoney(
                    allRetailerGenderSalesReports?.data?.totalValue || 0
                  )}`}
                  css={"justify-center text-center bg-[#EDFCF2] text-normal"}
                />
              </div>
              {/* <div className="h-[104px] bg-[#F9FAFB] w-full mb-[16px]">
                <TinyBarChart fill={"#F38744"} />
              </div> */}
              <div className="flex h-[340px] bg-[#F9FAFB]">
                {/* <div className="h-[340px]"> */}
                <PieCharts
                  data={allRetailerGenderSalesReports?.data?.genderSalesReport}
                  outerRadius={140}
                  innerRadius={0}
                  dataKey={"value"}
                />
                {/* </div> */}
                <div className="basis-[50%] flex flex-col justify-center gap-[32px]">
                  {allRetailerGenderSalesReports?.data?.genderSalesReport.map(
                    (data, index) => {
                      return (
                        <div className="flex flex-col gap-[8px]" key={index}>
                          <div className="flex items-center gap-[8px]">
                            <div
                              className={`w-[8px] h-[8px] rounded-full ${
                                data.gender === "MALE"
                                  ? "bg-[#2E90FA]"
                                  : "bg-[#F670C7]"
                              }`}
                            ></div>
                            <div className="capitalize">Total Sales</div>
                          </div>
                          <p>₦{toMoney(data?.value) || 0}</p>
                          {/* <p>Sales: {data?.volume || 0} sales</p> */}
                        </div>
                      );
                    }
                  )}
                </div>
              </div>
            </div>
            <div className="h-max basis-[50%] border border-gray-300 p-[16px] rounded-md">
              <div className="h-[79px] border-b border-gray-300 mb-[20px]">
                <div className="flex items-center gap-[16px] mb-[20px]">
                  <h2 className="w-full text-lg">Retailer sales by gender</h2>
                </div>
              </div>
              <div className="h-[533.5px]">
                <StackedBarChart
                  lineData={allRetailerGenderDateReports?.data}
                  lineDataList={["male", "female"]}
                  dataKeyX={"date"}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="h-max border border-gray-300 rounded-md px-[16px] py-[18px] relative">
        <div className="border-b border-gray-300 mb-[24px]">
          <div className="flex flex-col lg:flex-row lg:justify-between mb-[20px] h-max ">
            <div className="mb-2">
              <p className="">Retailer Records</p>
              {/* <p className="text-sm">Track the rate of collection over time.</p> */}
            </div>
            <div className="flex gap-[10px] w-max">
              {/* <div className="w-full">
                <DatePicker
                  selected={retailerStartDate}
                  onChange={onRetailerRecordChange}
                  startDate={retailerStartDate}
                  endDate={retailerEndDate}
                  selectsRange
                  customInput={<ExampleCustomInput />}
                  wrapperClassName="w-full"
                  // containerStyle={{ width: "100%" }}
                  showYearDropdown
                  yearDropdownItemNumber={100}
                  scrollableYearDropdown
                  isClearable
                />
              </div> */}
              <div className="w-[200px]">
                <SearchableDropdown
                  options={[
                    { label: "THIS_YEAR", value: "THIS_YEAR" },
                    { label: "THIS_MONTH", value: "THIS_MONTH" },
                    { label: "THIS_WEEK", value: "THIS_WEEK" },
                    { label: "THIS_QUARTER", value: "THIS_QUARTER" },
                    { label: "LAST_MONTH", value: "LAST_MONTH" },
                    { label: "LAST_WEEK", value: "LAST_WEEK" },
                  ]}
                  selectedOption={retailerRecordYear}
                  defaultValue={null}
                  placeholder="select option"
                  className={"w-full"}
                  handleChange={(e) => {
                    setRetailerRecordYear(e);
                  }}
                />
              </div>
              <div className="w-[200px]">
                <SearchableDropdown
                  options={allStates}
                  selectedOption={householdStateId}
                  defaultValue={null}
                  placeholder="state"
                  className={"w-full"}
                  handleChange={(e) => {
                    setRetailerStateId(e);
                  }}
                />
              </div>
            </div>
          </div>
        </div>
        <div className="flex gap-[16px] mb-[16px]">
          <TopCard
            title={"Total Retailers in state"}
            subtitle={"Retailers"}
            userCount={`${
              allRetailerOnboardingReports?.data?.totalRetailer || 0
            }`}
            css={"justify-center text-center bg-[#FFFAEB]"}
          />
          <TopCard
            title={"Total sales made in state"}
            subtitle={"Sales"}
            userCount={`₦${toMoney(
              allRetailerSalesReports?.data?.totalSales || 0
            )}`}
            css={"justify-center text-center bg-[#EDFCF2]"}
          />
        </div>
        <div className="min-h-[500px] flex gap-[24px]">
          <div className="h-max basis-[50%] mb-[16px] border border-gray-300 p-[16px] rounded-md">
            <div className="h-[79px] border-b border-gray-300 mb-[20px]">
              <div className="flex items-center gap-[16px] mb-[20px]">
                <h2 className="w-full">Retailers Onboarded</h2>
                <p className="px-[16px] py-[6px] rounded-[24px] bg-[#ECFDF3]">
                  {`${allRetailerOnboardingReports?.data?.totalRetailer || 0}`}
                </p>
              </div>
            </div>
            <div className="h-[533.5px]">
              <BarCharts
                fill={"#15B79E"}
                datakeyX={"lga"}
                dataKeyB={"value"}
                lineData={allRetailerOnboardingReports?.data?.reports}
                layout={"horizontal"}
                cartesian={false}
              />
            </div>
          </div>
          <div className="h-max basis-[50%] border border-gray-300 p-[16px] rounded-md">
            <div className="h-[79px] border-b border-gray-300 mb-[20px]">
              <div className="flex items-center gap-[16px] mb-[20px]">
                <h2 className="w-full">Retailers Sales</h2>
                <p className="px-[16px] py-[6px] rounded-[24px] bg-[#EFF8FF]">
                  {`₦${toMoney(
                    allRetailerSalesReports?.data?.totalSales || 0
                  )}`}
                </p>
              </div>
            </div>
            <div className="h-[533.5px]">
              <BarCharts
                fill={"#669F2A"}
                datakeyX={"lga"}
                dataKeyB={"value"}
                lineData={allRetailerSalesReports?.data?.reports}
                layout={"horizontal"}
                cartesian={false}
              />
            </div>
          </div>
        </div>
      </div>
      <div className="h-max border border-gray-300 rounded-md px-[16px] py-[18px] relative">
        <div className="border-b border-gray-300 mb-[24px]">
          <div className="flex flex-col lg:flex-row lg:justify-between mb-[20px] h-max ">
            <div className="mb-2">
              <p className="">Household Gender Sales Records</p>
            </div>
            <div className="w-[200px]">
              <SearchableDropdown
                options={[
                  { label: "Last_YEAR", value: "LAST_YEAR" },
                  { label: "THIS_YEAR", value: "THIS_YEAR" },
                  { label: "THIS_MONTH", value: "THIS_MONTH" },
                ]}
                selectedOption={householdGenderSalesYear}
                defaultValue={null}
                placeholder="select option"
                className={"w-full"}
                handleChange={(e) => {
                  setHouseholdGenderSalesYear(e);
                }}
              />
            </div>
            {/* <div className="flex rounded-md w-max">
              {[
                { name: "Last year", year: "LAST_YEAR" },
                { name: "This year", year: "THIS_YEAR" },
                { name: "This month", year: "THIS_MONTH" },
              ]?.map((data, index) => {
                return (
                  <button
                    key={index}
                    className={`w-max border border-gray-300 p-2 text-sm ${
                      index === 2 ? "rounded-br rounded-tr" : ""
                    } ${
                      index === 0
                        ? "text-sm rounded-tl rounded-bl bg-[#F9FAFB]"
                        : ""
                    }`}
                    onClick={() => setHouseholdGenderSalesYear(data.year)}
                  >
                    {data.name}
                  </button>
                );
              })}
            </div> */}
          </div>
        </div>
        <div className="flex flex-col gap-[16px] p-[16px] border border-gray-300 rounded-md">
          <div className="flex gap-[16px] mb-[16px]">
            <div
              className={`flex justify-between lg:flex-row bg-[#FFFAEB] px-[16px] py-[18px] rounded-[8px] w-full`}
            >
              <div className="flex flex-col gap-[8px]">
                <h1 className="capitalize text-[14px]">HouseHolds Onboarded</h1>
                <p className="font-bold text-[24px]">
                  {allHouseholdReports?.data?.totalHousehold || 0}
                </p>
                <p className="text-sm">Total HouseHolds</p>
              </div>
              <div className="flex flex-col gap-[8px]">
                <p className="font-bold text-[24px] mt-[30px]">
                  {allHouseholdReports?.data?.maleRetailer || 0}
                </p>
                <p className="text-sm">Male</p>
              </div>
              <div className="flex flex-col gap-[8px]">
                <p className="font-bold text-[24px] mt-[30px]">
                  {" "}
                  {allHouseholdReports?.data?.femaleRetailer || 0}
                </p>
                <p className="text-sm">Female</p>
              </div>
            </div>
          </div>
          {/* <div className="h-[300px] bg-[#F9FAFB] w-full">
            <TinyBarChart fill={"#F38744"} />
          </div> */}
        </div>
      </div>
      <div className="h-max border border-gray-300 rounded-md px-[16px] py-[18px] relative">
        <div className="border-b border-gray-300 mb-[24px]">
          <div className="flex flex-col lg:flex-row lg:justify-between mb-[20px] h-max ">
            <div className="mb-2">
              <p className="">Household Records</p>
              {/* <p className="text-sm">Track the rate of collection over time.</p> */}
            </div>
            <div className="flex gap-[10px] w-max">
              {/* <div className="w-full">
                <DatePicker
                  selected={houseHoldStartDate}
                  onChange={onHouseholdRecordChange}
                  startDate={houseHoldStartDate}
                  endDate={householdEndDate}
                  selectsRange
                  customInput={<ExampleCustomInput />}
                  wrapperClassName="w-full"
                  // containerStyle={{ width: "100%" }}
                  showYearDropdown
                  yearDropdownItemNumber={100}
                  scrollableYearDropdown
                  isClearable
                />
              </div> */}
              <div className="w-[200px]">
                <SearchableDropdown
                  options={[
                    { label: "THIS_YEAR", value: "THIS_YEAR" },
                    { label: "THIS_MONTH", value: "THIS_MONTH" },
                    { label: "THIS_WEEK", value: "THIS_WEEK" },
                    { label: "THIS_QUARTER", value: "THIS_QUARTER" },
                    { label: "LAST_MONTH", value: "LAST_MONTH" },
                    { label: "LAST_WEEK", value: "LAST_WEEK" },
                  ]}
                  selectedOption={householdRecordYear}
                  defaultValue={null}
                  placeholder="select option"
                  className={"w-full"}
                  handleChange={(e) => {
                    setHouseholdRecordYear(e);
                  }}
                />
              </div>
              <div className="w-[200px]">
                <SearchableDropdown
                  options={allStates}
                  selectedOption={householdStateId}
                  defaultValue={null}
                  placeholder="state"
                  className={"w-full"}
                  handleChange={(e) => {
                    setHouseholdStateId(e);
                  }}
                />
              </div>
              {/* <InputSelect
                css={"w-[185px]"}
                placeholder={"Calendar Type"}
                value={collectionReportTimeline}
                options={["Monthly", "Quarterly"]}
                handleChange={(e) => setHouseholdStateId(e.target.value)}
              /> */}
            </div>
          </div>
        </div>
        <div className="flex flex-col gap-[24px]">
          {/* <div className="flex gap-[16px]">
            <TopCard
              title={"Total Householders in state"}
              subtitle={"Householders"}
              userCount={`${
                allHouseholdOnboardingReports?.data?.totalHousehold || 0
              }`}
              css={"justify-center text-center bg-[#FFFAEB]"}
            />
            <TopCard
              title={"Total Sales made in state"}
              subtitle={"Sales"}
              userCount={`₦${toMoney(
                allHouseholdSalesReports?.data?.totalSales || 0
              )}`}
              css={"justify-center text-center bg-[#EDFCF2]"}
            />
          </div> */}
          <div className="min-h-[500px] flex gap-[24px]">
            <div className="h-max basis-[50%] mb-[16px] border border-gray-300 p-[16px] rounded-md">
              <div className="h-[79px] border-b border-gray-300 mb-[20px]">
                <div className="flex items-center gap-[16px] mb-[20px]">
                  <h2 className="w-full">Household onboarded</h2>
                  <p className="px-[16px] py-[6px] rounded-[24px] bg-[#ECFDF3]">
                    {`${
                      allHouseholdOnboardingReports?.data?.totalHousehold || 0
                    }`}
                  </p>
                </div>
              </div>
              <div className="h-[533.5px]">
                <BarCharts
                  fill={"#15B79E"}
                  datakeyX={"lga"}
                  dataKeyB={"value"}
                  lineData={allHouseholdSalesReports?.data?.salesReports}
                  layout={"horizontal"}
                  cartesian={false}
                />
              </div>
            </div>
            <div className="h-max basis-[50%] border border-gray-300 p-[16px] rounded-md">
              <div className="h-[79px] border-b border-gray-300 mb-[20px]">
                <div className="flex items-center gap-[16px] mb-[20px]">
                  <h2 className="w-full">Household sales</h2>
                  <p className="px-[16px] py-[6px] rounded-[24px] bg-[#EFF8FF]">
                    {`₦${toMoney(
                      allHouseholdSalesReports?.data?.totalSales || 0
                    )}`}
                  </p>
                </div>
              </div>
              <div className="h-[533.5px]">
                <BarCharts
                  fill={"#669F2A"}
                  datakeyX={"lga"}
                  dataKeyB={"value"}
                  lineData={allHouseholdSalesReports?.data?.salesReports}
                  layout={"horizontal"}
                  cartesian={false}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Dashboard;
