import * as yup from "yup";
export const createAdminSchema = yup.object({
  email: yup
    .string()
    .email("Invalid email format")
    .required("Email is required"),
  firstName: yup.string().required("Please enter your first name"),
  lastName: yup.string().required("Please enter your last name"),
  phoneNumber: yup.string().required("Please enter your phone number"),
});
