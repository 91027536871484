import * as yup from "yup";
export const changePasswordSchema = yup.object({
  password: yup
    .string()
    .required("Password is required")
    .test({
      test: (value) => {
        let errors = [];
        if (!/^(?=.{8,})/.test(value)) {
          errors.push("does not contain 8 Characters");
        }
        if (!/^(?=.*[A-Z])/.test(value)) {
          errors.push("does not contain one uppercase");
        }
        if (!/^(?=.*[a-z])/.test(value)) {
          errors.push("does not contain one lowercase");
        }
        if (!/^(?=.*[!@#\$%\^&\*])/.test(value)) {
          errors.push("does not contain one special case character");
        }
        if (errors.length > 0) {
          throw new yup.ValidationError({
            errors: errors,
            inner: true,
            path: "password",
            message: errors,
            value: value,
            name: "ValidationError",
          });
        }

        return true;
      },
    }),
  password2: yup
    .string()
    .oneOf([yup.ref("password"), null], "password must match")
    .required("Required"),
});
