import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";

import {
  getLga,
  getLocationCollectionReport,
  getParticipantReport,
  getPerformanceReport,
  getPlasticCollectionReport,
  getPlasticProcessedReport,
  getProgram,
  getSourceOfPlastic,
  getState,
  getLgaByState,
  getStateCollectionReport,
  getTopAggregators,
  getTopCollectors,
  getTopLocation,
  getTopState,
  getTypeOfPlastic,
  getAllProducts,
  getAllProductVariants,
} from "../ds/resource";
const useResource = (program) => {
  const [loading, setLoading] = useState();

  const getAllStates = async () => {
    setLoading(true);
    const res = await getState();
    return res;
  };
  const getAllLgas = async () => {
    setLoading(true);
    const res = await getLga();
    return res;
  };

  const getLgaFromState = async (id) => {
    setLoading(true);
    const res = await getLgaByState(id);
    return res;
  };
  const getAllProduct = async () => {
    setLoading(true);
    const res = await getAllProducts();
    return res;
  };
  const getAllProductVariant = async () => {
    setLoading(true);
    const res = await getAllProductVariants();
    return res;
  };

  return {
    loading,
    getAllStates,
    getAllLgas,
    getLgaFromState,
    getAllProduct,
    getAllProductVariant,
  };
};

export default useResource;
