import React from "react";
import { CiSearch } from "react-icons/ci";

const InputSearch = ({
  placeholder,
  inputValue,
  setInputValue,
  css,
  classDefName,
}) => {
  return (
    <div
      className={`flex items-center border border-gray-300 rounded-md ${
        classDefName ? classDefName : ""
      }`}
    >
      <span className="w-[40px] block flex justify-center">
        <CiSearch style={{ width: "20px", height: "20px" }} />
      </span>
      <input
        type="text"
        placeholder={placeholder}
        className={`${css ? css : "h-[44px] w-[150px] lg:w-[150px] "} p-2`}
        value={inputValue}
        onChange={(e) => {
          setInputValue(e.target.value);
        }}
        style={{ outline: "none" }}
      />
    </div>
  );
};

export default InputSearch;
