import React, { useCallback, useEffect, useState } from "react";

const useBackLink = () => {
  const [linkList, setLinkList] = useState([]);

  const getPathList = useCallback((origin) => {
    const splitPathname = origin.split("/");
    const links = splitPathname
      .splice(1, splitPathname.length - 1)
      .map((data) => {
        return {
          name: data,
          routePath: `/${data}`,
        };
      });
    setLinkList(links);
  }, []);
  return {
    linkList,
    getPathList,
  };
};

export default useBackLink;
