import { Form, Formik } from "formik";
import React, { useState } from "react";
import { deactivateUser } from "../../ds/auth";
import { deactivateSchema } from "../../utils/validationSchema/authSchema";

const DeactivateUser = () => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");

  const initialValues = {
    phoneNumber: "",
  };

  const onSubmit = async (data) => {
    try {
      setLoading(true);
      const res = await deactivateUser(data);
      //   if (res?.data) {
      //     localStorage.setItem("accessToken", res?.data?.access_token);
      //     const { user } = res?.data;
      //     dispatch(setUser(user));
      //     navigate("/dashboard");
      //   }
      setError(res.message);
    } catch (error) {
    } finally {
      setLoading(false);
    }
  };
  return (
    <div className="flex justify-center items-center h-screen bg-[#F9FAFB] p-[24px]">
      <div className="flex flex-col items-center justify-center gap-[32px] w-[424px] bg-white h-max p-4 rounded-md">
        {/* <div className="flex flex-col">
        <div className="self-start m-0 w-[121px] h-[30.04px]">
          <img
            src={Logo}
            alt="logo"
            // className="my-3"
            style={{ width: "100%", height: "100%", objectFit: "contain" }}
          />
        </div> */}
        <div className="px-2 mt-2 w-full">
          <h4 className="text-xl font-bold mb-[32px] ">Deactivation</h4>
          <p className="text-sm mb-3 text-gray-400 mb-[32px]">
            Please provide your details to Deactivate account
          </p>
          <Formik
            initialValues={initialValues}
            validationSchema={deactivateSchema}
            onSubmit={onSubmit}
          >
            {({ handleChange, errors, values }) => {
              return (
                <Form>
                  <div className="mb-3 w-full">
                    <label className="form-label" htmlFor="username">
                      Phone Number
                    </label>
                    <div className="flex flex-col items-center border border-gray-300 h-30 bg-gray-50">
                      <input
                        type="text"
                        className="p-2 w-full"
                        onChange={handleChange}
                        value={values.phoneNumber}
                        id="phoneNumber"
                        name="phoneNumber"
                        placeholder="Enter phone number"
                      />
                    </div>
                    {errors && errors.email && (
                      <p className="text-red-500">{errors.phoneNumber}</p>
                    )}
                  </div>
                  <div className="mt-2 w-full">
                    <button
                      className="bg-green-900 rounded-[8px] w-full flex justify-center items-center p-4 text-white h-[44px]"
                      type="submit"
                    >
                      {loading ? "loading..." : "Deactivate"}
                    </button>
                  </div>
                </Form>
              );
            }}
          </Formik>
        </div>
        {/* </div> */}
      </div>
    </div>
  );
};

export default DeactivateUser;
