import React, { useRef } from "react";
import TopCard from "../../components/card/TopCard";
import TableTopCard from "../../components/card/TableTopCard";
import InputSearch from "../../components/input/InputSearch";
import CustomTable from "../../components/table/CustomTable";
import useOutsideClick from "../../hooks/useOutsideClick";
import Modal from "../../components/Modal";

const headers = ["Activity", "Admin", "Date & Time"];

const AdminAudit = () => {
  const wrapperRef = useRef(null);
  const [showModal, setShowModal] = useOutsideClick(wrapperRef);
  return (
    <div className="p-4">
      <div className="rounded-[8px] border border-gray-300">
        <div className="border-b border-gray-300">
          <TableTopCard
            title={"Admin Audit"}
            subtitle={"keep track of all activities by admins"}
            className={"bg-green-900 text-white w-max"}
            setShowModal={() => setShowModal(true)}
          />
        </div>
        <div className="flex flex-col justify-between md:flex-row lg:flex-row">
          <div className="py-[12px] px-[16px]">
            <InputSearch
              placeholder={"search by name, email or phone number"}
              css={"w-[481px] h-[44px]"}
            />
          </div>
        </div>

        {false ? (
          <div className="flex justify-center">
            <p className="text-center">Loading...</p>
          </div>
        ) : ["hello"]?.length === 0 ? (
          <div className="flex justify-center">
            <p className="text-center">No data.</p>
          </div>
        ) : (
          <CustomTable
            headers={headers}
            rows={[]?.map((data, index) => {
              return {
                collector: "",
                price: "NGN " + "",
                quantity: "",
                amount: "NGN " + "",
                state: "",
                date: "",
                open: "",
              };
            })}
          />
        )}
      </div>
      {/* <PaginationPane
        currentPage={page > 1 ? page : 1}
        totalPages={totalPages || 1}
        nextPage={() => nextPage()}
        prevPage={() => prevPage()}
      /> */}
    </div>
  );
};

export default AdminAudit;
