import React, { PureComponent } from "react";
import {
  BarChart,
  Bar,
  Rectangle,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from "recharts";

const data = [
  {
    name: "Jan",
    uv: 4000,
    pv: 2400,
    amt: 2400,
  },
  {
    name: "Feb",
    uv: 3000,
    pv: 1398,
    amt: 2210,
  },
  {
    name: "March",
    uv: 2000,
    pv: 9800,
    amt: 2290,
  },
  {
    name: "Apr",
    uv: 2780,
    pv: 3908,
    amt: 2000,
  },
  {
    name: "May",
    uv: 1890,
    pv: 4800,
    amt: 2181,
  },
  {
    name: "Jun",
    uv: 2390,
    pv: 3800,
    amt: 2500,
  },
  {
    name: "Jul",
    uv: 3490,
    pv: 4300,
    amt: 2100,
  },
  {
    name: "Aug",
    uv: 3490,
    pv: 4300,
    amt: 2100,
  },
  {
    name: "Sep",
    uv: 3490,
    pv: 4300,
    amt: 2100,
  },
  {
    name: "Oct",
    uv: 3490,
    pv: 4300,
    amt: 2100,
  },
  {
    name: "Nov",
    uv: 3490,
    pv: 4300,
    amt: 2100,
  },
  {
    name: "Dec",
    uv: 3490,
    pv: 4300,
    amt: 2100,
  },
];

function numberWithCommas(x) {
  return x?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}

const BarCharts = ({
  fill,
  datakeyX,
  dataKeyB,
  lineData,
  layout,
  cartesian,
}) => {
  //   static demoUrl = "https://codesandbox.io/s/simple-bar-chart-tpz8r";
  const AxisLabel = ({ axisType, x, y, width, height, children }) => {
    const isVert = axisType === "yAxis";
    const rot = isVert ? -90 : 0;
    const cx = isVert ? -height / 2 : x + width / 2;
    const cy = isVert ? y : y + height / 2 + 14;
    return (
      <text x={cx} y={cy} transform={`rotate(${rot})`} textAnchor="middle">
        {children}
      </text>
    );
  };

  const DataFormater = (number) => {
    if (number > 1000000000) {
      return (number / 1000000000).toString() + "B";
    } else if (number > 1000000) {
      return (number / 1000000).toString() + "M";
    } else if (number > 1000) {
      return (number / 1000).toString() + "K";
    } else {
      return number.toString();
    }
  };

  return (
    <ResponsiveContainer width="100%" height="100%">
      <BarChart
        width={500}
        height={500}
        data={lineData ? lineData : data}
        barSize={25}
        margin={{
          top: 5,
          right: 30,
          left: 20,
          bottom: 5,
        }}
        layout={layout}
      >
        {cartesian ? <CartesianGrid strokeDasharray="3 3" /> : ""}
        <XAxis
          dataKey={datakeyX}
          // label={{
          //   value: "Month",
          //   offset: 100,
          //   position: "insidebottom",
          // }}
        />
        <YAxis
          tickFormatter={DataFormater}
          // label={{
          //   value: "Plastics",
          //   angle: -90,
          //   position: "insideLeft",
          // }}
        />
        <Tooltip formatter={(payload) => numberWithCommas(parseInt(payload))} />
        {/* <Legend /> */}
        <Bar
          dataKey={dataKeyB}
          fill={fill}
          // onMouseOver={() => tooltip = "plasticsNum"}
          // activeBar={<Rectangle fill="pink" stroke="blue" />}
        />
        {/* <Bar
          dataKey="uv"
          fill="#82ca9d"
          activeBar={<Rectangle fill="gold" stroke="purple" />}
        /> */}
      </BarChart>
    </ResponsiveContainer>
  );
};

export default BarCharts;
