import React, { useEffect, useState } from "react";
import Logo from "../../assets/images/Logo.png";
import { CiUser } from "react-icons/ci";
import { FaKey } from "react-icons/fa";
import { BsBoxArrowRight } from "react-icons/bs";
import { Form, Formik } from "formik";
import { useNavigate } from "react-router-dom";
import { signInSchema } from "../../utils/validationSchema/authSchema";
// import useAuth from "../../hooks/useAuth";
// import api from "../../api/axios";
// import fetcher from "../../api/fetacher";
import { useDispatch, useSelector } from "react-redux";
// import { setProgram, setUser } from "../../feature/auth";
// import InputText from "../../components/input/InputText";
import { IoMdLock } from "react-icons/io";
import { IoEyeOutline, IoEyeOffOutline } from "react-icons/io5";
import useAuth from "../../hooks/useAuth";
import { setUser } from "../../features/auth";

const SignIn = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [viewPassword, setViewPassword] = useState(false);
  const [error, setError] = useState("");
  const { loginUser } = useAuth();
  const dispatch = useDispatch();

  const initialValues = {
    email: "",
    password: "",
  };

  const signIn = async (data) => {
    try {
      setLoading(true);
      const res = await loginUser(data);
      if (res?.data) {
        localStorage.setItem("accessToken", res?.data?.access_token);
        const { user } = res?.data;
        dispatch(setUser(user));
        navigate("/dashboard");
      }
      setError(res.message);
    } catch (error) {
    } finally {
      setLoading(false);
    }
  };
  return (
    <div className="flex justify-center items-center h-screen bg-[#F9FAFB] p-[24px]">
      <div className="flex flex-col items-center justify-center gap-[32px] w-[424px] bg-white h-max p-4 rounded-md">
        {/* <div className="flex flex-col"> */}
        <div className="self-start m-0 w-[121px] h-[30.04px]">
          <img
            src={Logo}
            alt="logo"
            // className="my-3"
            style={{ width: "100%", height: "100%", objectFit: "contain" }}
          />
        </div>
        <div className="px-2 mt-2 w-full">
          <h4 className="text-xl font-bold mb-[32px] ">Log-In</h4>
          <p className="text-sm mb-3 text-gray-400 mb-[32px]">
            Please provide your details to access this site
          </p>
          <Formik
            initialValues={initialValues}
            validationSchema={signInSchema}
            onSubmit={signIn}
          >
            {({ handleChange, errors, values }) => {
              return (
                <Form>
                  <div className="mb-3 w-full">
                    <label className="form-label" htmlFor="username">
                      Email
                    </label>
                    <div className="flex flex-col items-center border border-gray-300 h-30 bg-gray-50">
                      <input
                        type="text"
                        className="p-2 w-full"
                        onChange={handleChange}
                        value={values.email}
                        id="email"
                        name="email"
                        placeholder="Enter email address"
                      />
                    </div>
                    {errors && errors.email && (
                      <p className="text-red-500">{errors.email}</p>
                    )}
                  </div>

                  <div className="mb-3 w-full">
                    <label className="form-label" htmlFor="password">
                      Password
                    </label>
                    <div className="flex items-center border border-gray-300 h-30 bg-gray-50">
                      <input
                        type={`${viewPassword ? "text" : "password"}`}
                        className="p-2 w-full"
                        onChange={handleChange}
                        value={values.password}
                        id="password"
                        name="password"
                        placeholder="Enter password"
                      />
                      <span className="inline-block w-[20px]">
                        {viewPassword ? (
                          <IoEyeOutline
                            onClick={() => setViewPassword(false)}
                          />
                        ) : (
                          <IoEyeOffOutline
                            onClick={() => setViewPassword(true)}
                          />
                        )}
                      </span>
                    </div>
                    {errors && errors.password && (
                      <p className="text-red-500">{errors.password}</p>
                    )}
                  </div>
                  {error && <p className="text-red-500">{error}</p>}
                  <div
                    className="mt-4 mb-[32px] flex justify-end gap-1 w-full"
                    onClick={() => navigate("/forgotpassword")}
                    style={{ cursor: "pointer" }}
                  >
                    <p className="mb-0 text-sm text-green-900">
                      Forgot your password?
                    </p>
                  </div>
                  <div className="mt-2 w-full">
                    <button
                      className="bg-green-900 rounded-[8px] w-full flex justify-center items-center p-4 text-white h-[44px]"
                      type="submit"
                    >
                      {loading ? "loading..." : "Log-In"}
                    </button>
                  </div>
                </Form>
              );
            }}
          </Formik>
        </div>
        {/* </div> */}
      </div>
    </div>
  );
};

export default SignIn;
