import React, { useState } from "react";
import { resetPasswordSchema } from "../../utils/validationSchema/resetPassword";
import { useNavigate } from "react-router-dom";
import { Form, Formik } from "formik";
import { IoEyeOutline, IoEyeOffOutline } from "react-icons/io5";
import { IoCloseOutline } from "react-icons/io5";

const ChangePasswordModal = ({
  states,
  lgas,
  closeModal,
  requestType,
  getAdmin,
  useerData,
  setSelectedStateId,
}) => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const [viewPassword, setViewPassword] = useState(false);

  //   const { program, user } = useSelector((state) => state.auth);
  // const { resetCurrentUserPassword } = useAuth();
  //   const dispatch = useDispatch();

  const initialValues = {
    email: "",
  };

  const handleSubmit = async (data) => {
    // try {
    //   setLoading(true);
    //   const res = await resetCurrentUserPassword(data);
    //   navigate("/");
    //   setError(res.message);
    // } catch (error) {
    // } finally {
    //   setLoading(false);
    // }
  };
  return (
    <Formik
      initialValues={initialValues}
      validationSchema={resetPasswordSchema}
      onSubmit={handleSubmit}
    >
      {({ handleChange, errors, values, handleSubmit }) => {
        return (
          <Form>
            <div className="p-[16px] w-full bg-white min-w-[465px]">
              <div className="flex justify-between pb-[24px] px-[24px] pt-[16px]">
                <div className="">
                  <h1 className="capitalize font-bold">Change password</h1>
                </div>
                <IoCloseOutline
                  style={{ width: 26, height: 26, cursor: "pointer" }}
                  onClick={() => closeModal()}
                />
              </div>
              <div className="px-[24px]">
                <div className="mb-3 w-full">
                  <label htmlFor="password">Current Password</label>
                  <div className="flex items-center border border-gray-300 h-30 bg-gray-50 rounded-md">
                    <input
                      type={`${viewPassword ? "text" : "password"}`}
                      className="p-2 w-full"
                      onChange={handleChange}
                      value={values.password}
                      id="password"
                      name="password"
                      placeholder="Enter new password"
                    />
                    <span className="inline-block w-[20px]">
                      {viewPassword ? (
                        <IoEyeOutline onClick={() => setViewPassword(false)} />
                      ) : (
                        <IoEyeOffOutline
                          onClick={() => setViewPassword(true)}
                        />
                      )}
                    </span>
                  </div>
                  {errors && errors.password && (
                    <p className="text-red-500">{errors.password}</p>
                  )}
                </div>
                <div className="mb-3 w-full">
                  <label htmlFor="password">New Password</label>
                  <div className="flex items-center border border-gray-300 h-30 bg-gray-50 rounded-md">
                    <input
                      type={`${viewPassword ? "text" : "password"}`}
                      className="p-2 w-full"
                      onChange={handleChange}
                      value={values.password}
                      id="password"
                      name="password"
                      placeholder="Enter new password"
                    />
                    <span className="inline-block w-[20px]">
                      {viewPassword ? (
                        <IoEyeOutline onClick={() => setViewPassword(false)} />
                      ) : (
                        <IoEyeOffOutline
                          onClick={() => setViewPassword(true)}
                        />
                      )}
                    </span>
                  </div>
                  {errors && errors.password && (
                    <p className="text-red-500">{errors.password}</p>
                  )}
                </div>
                <div className="mb-3 w-full">
                  <label htmlFor="password2">Confirm Password</label>
                  <div className="flex items-center border border-gray-300 h-30 bg-gray-50 rounded-md">
                    <input
                      type={`${viewPassword ? "text" : "password"}`}
                      className="p-2 w-full"
                      onChange={handleChange}
                      value={values.password2}
                      id="password"
                      name="password2"
                      placeholder="Confirm new password"
                    />
                    <span className="inline-block w-[20px]">
                      {viewPassword ? (
                        <IoEyeOutline onClick={() => setViewPassword(false)} />
                      ) : (
                        <IoEyeOffOutline
                          onClick={() => setViewPassword(true)}
                        />
                      )}
                    </span>
                  </div>
                  {errors && errors.password2 && (
                    <p className="text-red-500">{errors.password2}</p>
                  )}
                </div>
                {error && <p className="text-red-500">{error}</p>}
                <div className="flex gap-2 justify-end">
                  <button
                    className="p-[12px] rounded-[8px] w-max h-[40px] font-normal text-xs flex justify-center items-center gap-3 disabled:cursor-not-allowed border border-pfBlack md:w-272"
                    onClick={() => closeModal()}
                  >
                    Cancel
                  </button>
                  <button
                    type="submit"
                    onClick={handleSubmit}
                    className="bg-green-700 rounded-[8px] text-white flex justify-center items-center h-[40px] w-max p-[12px]"
                  >
                    {loading ? "Creating..." : "Change Password"}
                  </button>
                </div>
              </div>
            </div>
          </Form>
        );
      }}
    </Formik>
  );
};

export default ChangePasswordModal;
