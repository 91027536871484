import React from "react";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from "recharts";

const data = [
  {
    name: "Page A",
    uv: 4000,
    pv: 2400,
    amt: 2400,
  },
  {
    name: "Page B",
    uv: 3000,
    pv: 1398,
    amt: 2210,
  },
  {
    name: "Page C",
    uv: 2000,
    pv: 9800,
    amt: 2290,
  },
  {
    name: "Page D",
    uv: 2780,
    pv: 3908,
    amt: 2000,
  },
  {
    name: "Page E",
    uv: 1890,
    pv: 4800,
    amt: 2181,
  },
  {
    name: "Page F",
    uv: 2390,
    pv: 3800,
    amt: 2500,
  },
  {
    name: "Page G",
    uv: 3490,
    pv: 4300,
    amt: 2100,
  },
];
const COLORS = ["#2E90FA", "#F670C7"];

const StackedBarChart = ({ dataKey, lineData, lineDataList, dataKeyX }) => {
  //   static demoUrl = 'https://codesandbox.io/p/sandbox/stacked-bar-chart-7fwfgj';
  const DataFormater = (number) => {
    if (number > 1000000000) {
      return (number / 1000000000).toString() + "B";
    } else if (number > 1000000) {
      return (number / 1000000).toString() + "M";
    } else if (number > 1000) {
      return (number / 1000).toString() + "K";
    } else {
      return number.toString();
    }
  };

  return (
    <ResponsiveContainer width="100%" height="100%">
      <BarChart
        width={500}
        height={300}
        data={lineData}
        margin={{
          top: 20,
          right: 30,
          left: 20,
          bottom: 5,
        }}
      >
        {/* <CartesianGrid strokeDasharray="3 3" /> */}
        <XAxis dataKey={dataKeyX} />
        <YAxis tickFormatter={DataFormater} />
        <Tooltip />
        <Legend />
        {lineDataList.map((data, index) => {
          return (
            <Bar
              key={index}
              dataKey={data}
              stackId="a"
              fill={COLORS[index % COLORS.length]}
            />
          );
        })}
        {/* <Bar dataKey="uv" stackId="a" fill="#F670C7" /> */}
      </BarChart>
    </ResponsiveContainer>
  );
};

export default StackedBarChart;
