import React from "react";
import DashboardLayout from "../layouts/DashboardLayout";
import Administrator from "../pages/administrator";
import Community from "../pages/community";
import HouseHolds from "../pages/household";
import Retailer from "../pages/retailer";
import FieldOfficer from "../pages/fieldofficer";
import SalesRecord from "../pages/salesrecords";
import Dashboard from "../pages/dashboard";
import AuthGuard from "../route-guards/AuthGuard";
import AdminAudit from "../pages/adminAudit";
import ApprovalRequest from "../pages/fieldofficer/ApprovalRequest";
import FieldOfficerDetail from "../pages/fieldofficer/FieldOfficerDetail";
import HouseholdDetail from "../pages/household/HouseholdDetail";
import RetailerDetail from "../pages/retailer/RetailerDetail";
// import Dashboard from "../pages/dashboard";
// import Aggregator from "../pages/aggregator";
// import Collector from "../pages/collector";
// import Recycler from "../pages/recycler";
// import Transaction from "../pages/transaction";
// import User from "../pages/user";
// import Offtake from "../pages/waybill";
// import Admin from "../pages/admin";
// import AdminDashboard from "../pages/admin/dashboard/Dashboard";
// import AuthGuard from "../route-guard/AuthGuard";
// import Collection from "../pages/collection";
// import ErrorBoundary from "../components/ErrorBoundary/ErrorBondary";

export const getDashboardRouter = () => {
  return [
    {
      path: "/",
      element: (
        //   <AuthGuard>
        <DashboardLayout />
        //   </AuthGuard>
      ),
      // errorElement: <ErrorBoundary />,
      children: [
        {
          children: [
            {
              path: "/dashboard",
              element: (
                <AuthGuard>
                  <Dashboard />,
                </AuthGuard>
              ),
              // errorElement: <ErrorBoundary />,
            },
            {
              path: "/administrators",
              element: (
                <AuthGuard>
                  <Administrator />,
                </AuthGuard>
              ),
              // errorElement: <ErrorBoundary />,
            },
            {
              path: "/community",
              element: (
                <AuthGuard>
                  <Community />,
                </AuthGuard>
              ),
              // errorElement: <ErrorBoundary />,
            },
            {
              path: "/households",
              element: (
                <AuthGuard>
                  <HouseHolds />,
                </AuthGuard>
              ),
              // errorElement: <ErrorBoundary />,
            },
            {
              path: "/households/:id",
              element: (
                <AuthGuard>
                  <HouseholdDetail />,
                </AuthGuard>
              ),
              // errorElement: <ErrorBoundary />,
            },
            {
              path: "/retailers",
              element: (
                <AuthGuard>
                  <Retailer />,
                </AuthGuard>
              ),
              // errorElement: <ErrorBoundary />,
            },
            {
              path: "/retailers/:id",
              element: (
                <AuthGuard>
                  <RetailerDetail />,
                </AuthGuard>
              ),
              // errorElement: <ErrorBoundary />,
            },
            {
              path: "/fieldofficers",
              element: (
                <AuthGuard>
                  <FieldOfficer />,
                </AuthGuard>
              ),
              // errorElement: <ErrorBoundary />,
            },
            {
              path: "/fieldofficers/:id",
              element: (
                <AuthGuard>
                  <FieldOfficerDetail />,
                </AuthGuard>
              ),
              // errorElement: <ErrorBoundary />,
            },
            {
              path: "/fieldofficers/requests",
              element: (
                <AuthGuard>
                  <ApprovalRequest />,
                </AuthGuard>
              ),
              // errorElement: <ErrorBoundary />,
            },
            {
              path: "salesrecords",
              element: (
                <AuthGuard>
                  <SalesRecord />,
                </AuthGuard>
              ),
              // errorElement: <ErrorBoundary />,
            },
            {
              path: "adminaudit",
              element: (
                <AuthGuard>
                  <AdminAudit />,
                </AuthGuard>
              ),
              // errorElement: <ErrorBoundary />,
            },
            // {
            //   path: "/dashboard/:modelId",
            //   element: <DashboardLayout />,
            //   errorElement: <ErrorPage />,
            // },
          ],
        },
      ],
    },
    // {
    //   path: "admin",
    //   element: (
    //     <AuthGuard>
    //       <Admin />
    //     </AuthGuard>
    //   ),
    //   errorElement: <ErrorBoundary />,
    //   children: [
    //     {
    //       children: [
    //         {
    //           path: "dashboard",
    //           element: <AdminDashboard />,
    //           // errorElement: <ErrorBoundary />,
    //         },
    //         {
    //           path: "aggregator",
    //           element: <Aggregator />,
    //           // errorElement: <ErrorBoundary />,
    //         },
    //         {
    //           path: "collector",
    //           element: <Collector />,
    //           // errorElement: <ErrorBoundary />,
    //         },
    //         {
    //           path: "recycler",
    //           element: <Recycler />,
    //           // errorElement: <ErrorBoundary />,
    //         },
    //         {
    //           path: "transaction",
    //           element: <Transaction />,
    //           // errorElement: <ErrorBoundary />,
    //         },
    //         {
    //           path: "user",
    //           element: <User />,
    //           // errorElement: <ErrorBoundary />,
    //         },
    //         {
    //           path: "offtake",
    //           element: <Offtake />,
    //           // errorElement: <ErrorBoundary />,
    //         },
    //         {
    //           path: "collection",
    //           element: <Collection />,
    //           // errorElement: <ErrorBoundary />,
    //         },
    //         // {
    //         //   path: "/dashboard/:modelId",
    //         //   element: <DashboardLayout />,
    //         //   errorElement: <ErrorPage />,
    //         // },
    //       ],
    //     },
    //   ],
    // },
  ];
};
