import React from "react";
// import { NumberRangeInput } from "components/inputs";
import { Pagination as PaginationPrimitive } from "react-headless-pagination";
import { MdArrowForward, MdArrowBack } from "react-icons/md";

export const paginationConstants = {
  initialState: { PAGE_SIZE: 20, PAGE_INDEX: 0 },
  defaultState: { pageSize: 20, pageIndex: 0 },
};
const PaginationTab = ({
  pageIndex,
  pageSize,
  canPreviousPage,
  canNextPage,
  gotoPage,
  pageCount,
  handleNext,
  handlePrevious,
  setPageSize,
  useAlternatePagination,
}) => {
  //   const prevBtnClassNames = classnames({
  //     "pagination__previous-btn": useAlternatePagination !== true,
  //     prev: useAlternatePagination === true,
  //   });
  //   const nextBtnClassNames = classnames({
  //     "pagination__next-btn": useAlternatePagination !== true,
  //     next: useAlternatePagination === true,
  //   });
  //   if (useAlternatePagination === true) {
  //     return (
  //       <Flex px="16px" pt="12px" pb="1rem">
  //         <PaginationPrimitive
  //           className="alternate-pagination"
  //           currentPage={pageIndex}
  //           totalPages={pageCount}
  //           setCurrentPage={gotoPage}
  //           edgePageCount={3}
  //           middlePagesSiblingCount={3}
  //           truncableText="..."
  //           truncableClassName="alternate-pagination-nav__page-btn"
  //         >
  //           <PaginationPrimitive.PrevButton
  //             className={prevBtnClassNames}
  //             disabled={!canPreviousPage}
  //           >
  //             <span className="arrow-icon">
  //               <Icon icon="arrow-left" color="#344054" size="20px" />
  //             </span>
  //           </PaginationPrimitive.PrevButton>
  //           <span className="current-page">
  //             Page <span className="current-page__bold">{pageIndex + 1}</span> of{" "}
  //             <span className="current-page__bold">{pageCount}</span>
  //           </span>
  //           <PaginationPrimitive.NextButton
  //             className={nextBtnClassNames}
  //             disabled={!canNextPage}
  //           >
  //             <span className="arrow-icon">
  //               <Icon icon="arrow-right" color="#344054" size="20px" />
  //             </span>
  //           </PaginationPrimitive.NextButton>
  //         </PaginationPrimitive>
  //       </Flex>
  //     );
  //   }

  return (
    <>
      {/* <NumberRangeInput
        options={[10, 20, 50, 100, 1000]}
        value={pageSize}
        defaultValue={paginationConstants.initialState.PAGE_SIZE}
        onChange={setPageSize}
      /> */}
      {/* {useAlternatePagination} */}
      <PaginationPrimitive
        className="flex w-max h-[40px] overflow-hidden w-max"
        currentPage={pageIndex}
        totalPages={pageCount}
        setCurrentPage={gotoPage}
        edgePageCount={4}
        middlePagesSiblingCount={2}
        truncableText="..."
        truncableClassName="pagination-nav__page-btn"
      >
        <PaginationPrimitive.PrevButton
        // disabled={!canPreviousPage}
        // className={
        //   "flex w-[114px] h-[40px] px-[16px] items-center justify-center border border-gray-300"
        // }
        >
          <div
            className={
              "flex w-[114px] h-[40px] px-[16px] items-center justify-center border border-gray-300"
            }
            onClick={() => handlePrevious()}
          >
            <MdArrowBack />
            <p>Previous</p>
          </div>
        </PaginationPrimitive.PrevButton>
        <nav className="flex w-full">
          <ul className="pagination-nav__list flex items-center">
            <PaginationPrimitive.PageButton
              activeClassName="h-[40px] w-[40px] border border-gray-300 bg-gray-300"
              as={<button />}
              className="h-[40px] w-[40px] border border-gray-300 bg-white"
            />
          </ul>
        </nav>
        <PaginationPrimitive.NextButton
        // disabled={!canNextPage}
        // className={
        //   "flex w-[90px] h-[40px] px-[16px] items-center justify-center border border-gray-300 cursor-pointer"
        // }
        // disabled={false}
        >
          <div
            className={
              "flex w-[90px] h-[40px] px-[16px] items-center justify-center border border-gray-300 cursor-pointer"
            }
            onClick={() => handleNext()}
          >
            <p>Next</p>
            <MdArrowForward />
          </div>
        </PaginationPrimitive.NextButton>
      </PaginationPrimitive>
    </>
  );
};

export default PaginationTab;
