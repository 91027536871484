import React, { useState } from "react";
import {
  getAllHouseholdReport,
  getAllHouseoldOnboardingReport,
  getAllHouseoldSalesReport,
  getAllProductRegionReport,
  getAllProductSalesReport,
  getAllRetailerGenderDateReport,
  getAllRetailerGenderOnboardingReport,
  getAllRetailerGenderSalesReport,
  getAllRetailerOnboardingReport,
  getAllRetailerReport,
  getAllRetailerSalesReport,
  getAllSalesReport,
} from "../ds/dashbord";

const useDashboard = () => {
  const [loading, setLoading] = useState();
  const getAllSalesReports = async () => {
    setLoading(true);
    const res = await getAllSalesReport();
    setLoading(false);
    return res;
  };
  const getAllProductRegionReports = async ({ stateId, calendarType }) => {
    setLoading(true);
    const res = await getAllProductRegionReport({
      stateId: stateId?.value || "",
      calendarType: calendarType?.value || "",
    });
    setLoading(false);
    return JSON.parse(res);
  };
  const getAllRetailerReports = async () => {
    setLoading(true);
    const res = await getAllRetailerReport();
    setLoading(false);
    return res;
  };
  const getAllRetailerGenderOnboardingReports = async () => {
    setLoading(true);
    const res = await getAllRetailerGenderOnboardingReport();
    setLoading(false);
    return res;
  };
  const getAllRetailerSalesReports = async ({ stateId, calendarType }) => {
    setLoading(true);
    const res = await getAllRetailerSalesReport({
      stateId: stateId?.value || "",
      calendarType: calendarType?.value || "",
    });
    setLoading(false);
    return JSON.parse(res);
  };
  const getAllHouseholdsSalesReports = async ({ stateId, calendarType }) => {
    setLoading(true);
    const res = await getAllHouseoldSalesReport({
      stateId: stateId?.value || "",
      calendarType: calendarType?.value || "",
    });
    setLoading(false);
    return JSON.parse(res);
  };
  const getAllHouseholdsOnboardingReports = async ({
    stateId,
    calendarType,
  }) => {
    setLoading(true);
    const res = await getAllHouseoldOnboardingReport({
      stateId: stateId?.value || "",
      calendarType: calendarType?.value || "",
    });
    setLoading(false);
    return JSON.parse(res);
  };
  const getAllRetailerOnboardingReports = async ({ stateId, calendarType }) => {
    setLoading(true);
    const res = await getAllRetailerOnboardingReport({
      stateId: stateId?.value || "",
      calendarType: calendarType?.value || "",
    });
    setLoading(false);
    return JSON.parse(res);
  };
  const getAllHouseholdReports = async ({ CalendarType }) => {
    setLoading(true);
    const res = await getAllHouseholdReport({
      CalendarType: CalendarType?.value || "",
    });
    setLoading(false);
    return JSON.parse(res);
  };
  const getAllRetailerGenderSalesReports = async ({ CalendarType }) => {
    setLoading(true);
    const res = await getAllRetailerGenderSalesReport({
      CalendarType: CalendarType?.value || "",
    });
    setLoading(false);
    return JSON.parse(res);
  };
  const getAllRetailerGenderDateReports = async ({ CalendarType }) => {
    setLoading(true);
    const res = await getAllRetailerGenderDateReport({
      CalendarType: CalendarType?.value || "",
    });
    setLoading(false);
    return JSON.parse(res);
  };
  const getAllProductSalesReports = async ({ CalendarType }) => {
    setLoading(true);
    const res = await getAllProductSalesReport({
      CalendarType: CalendarType?.value || "",
    });
    setLoading(false);
    return JSON.parse(res);
  };
  return {
    getAllSalesReports,
    getAllProductSalesReports,
    getAllProductRegionReports,
    getAllRetailerReports,
    getAllRetailerSalesReports,
    getAllRetailerGenderOnboardingReports,
    getAllHouseholdReports,
    getAllHouseholdsSalesReports,
    getAllHouseholdsOnboardingReports,
    getAllRetailerOnboardingReports,
    getAllRetailerGenderSalesReports,
    getAllRetailerGenderDateReports,
  };
};

export default useDashboard;
