import React, { useEffect, useState } from "react";
import Logo from "../../assets/images/airplane_with_dotted_line.png";
import { CiUser } from "react-icons/ci";
import { FaKey } from "react-icons/fa";
import { BsBoxArrowRight } from "react-icons/bs";
import { Form, Formik } from "formik";
import { useNavigate } from "react-router-dom";
import { signInSchema } from "../../utils/validationSchema/authSchema";
// import useAuth from "../../hooks/useAuth";
import { useDispatch, useSelector } from "react-redux";
import { resetPasswordSchema } from "../../utils/validationSchema/resetPassword";
import { forgotPassword, verifyOtp } from "../../ds/auth";
import { validateOtpSchema } from "../../utils/validationSchema/otpValidationSchema";
const VerifyOtp = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [viewPassword, setViewPassword] = useState(false);
  const [error, setError] = useState("");
  const { program, user } = useSelector((state) => state.auth);
  const dispatch = useDispatch();

  const initialValues = {
    otp1: "",
    otp2: "",
    otp3: "",
    otp4: "",
  };

  const changePassword = async (data) => {
    try {
      setLoading(true);
      const newData = parseInt(
        `${data.otp1}${data.otp2}${data.otp3}${data.otp4}`
      );
      const email = localStorage.getItem("email");
      if (email) {
        const res = await verifyOtp({ email: email, otp: newData });
        if (res.code === "00") {
          navigate("/login");
        }
        setError(res.message || res?.errors?.otp);
      }
    } catch (error) {
    } finally {
      setLoading(false);
    }
  };
  return (
    <div className="flex justify-center items-center h-screen bg-[#F9FAFB] p-[24px]">
      <div className="flex flex-col items-center justify-center w-[424px] bg-white h-max p-4 rounded-md">
        <div className="px-2 mt-2 w-full">
          <div className="flex flex-col items-center justify-center">
            <div className="w-[296px] mb-[14px] h-[146px]">
              <img
                src={Logo}
                alt="logo"
                style={{ width: "100%", height: "100%", objectFit: "cover" }}
              />
            </div>
            <p className="text-[30px] mb-3  mb-[32px] font-semibold text-center">
              Provide OTP sent to your email address{" "}
            </p>
          </div>

          <Formik
            initialValues={initialValues}
            validationSchema={validateOtpSchema}
            onSubmit={changePassword}
          >
            {({ handleChange, errors, values, handleSubmit }) => {
              return (
                <Form>
                  <div className="mb-3 w-full">
                    <div className="flex items-center justify-center gap-[5px] h-30">
                      <input
                        type={`text`}
                        className="p-2 w-[68.4px] border border-gray-300 rounded-md"
                        onChange={handleChange}
                        value={values.otp1}
                        id="otp1"
                        name="otp1"
                        // placeholder="Enter new password"
                      />
                      <input
                        type={`text`}
                        className="p-2 w-[68.4px] border border-gray-300 rounded-md"
                        onChange={handleChange}
                        value={values.otp2}
                        id="otp2"
                        name="otp2"
                        // placeholder="Enter new password"
                      />
                      <input
                        type={`text`}
                        className="p-2 w-[68.4px] border border-gray-300 rounded-md"
                        onChange={handleChange}
                        value={values.otp3}
                        id="otp3"
                        name="otp3"
                        // placeholder="Enter new password"
                      />
                      <input
                        type={`text`}
                        className="p-2 w-[68.4px] border border-gray-300 rounded-md"
                        onChange={handleChange}
                        value={values.otp4}
                        id="otp4"
                        name="otp4"
                      />
                    </div>
                  </div>
                  {error && <p className="text-red-500 text-center">{error}</p>}
                  <div className="mt-2 w-full">
                    <button
                      className="bg-green-900 rounded-[8px] w-full flex justify-center items-center p-4 text-white h-[44px]"
                      type="submit"
                    >
                      {loading ? "loading..." : "Submit otp"}
                    </button>
                  </div>
                  <div className="mt-2 w-full">
                    <button
                      className="bg-white text-green-900 rounded-[8px] w-full flex justify-center items-center p-4 h-8 text-sm"
                      onClick={() => navigate("/")}
                    >
                      Back to Log-in
                    </button>
                  </div>
                </Form>
              );
            }}
          </Formik>
        </div>
      </div>
    </div>
  );
};

export default VerifyOtp;
