import React from "react";

const PrivacyPolicy = () => {
  return (
    <div className="flex justify-center">
      <div className="w-[60%] p-[20px]">
        <div className="border-b border-gray-300">
          <h1 className="mb-[10px] font-bold">
            Privacy Policy for SNIPS Mobile Application
          </h1>
          <h1 className="mb-[10px] font-bold">
            Effective Date: 27th June, 2024
          </h1>
        </div>
        <div className="mb-[10px]">
          <h1 className="font-bold">Introduction</h1>
          <p>
            Welcome to the SNIPS (Strengthening Nutrition Project in Nigeria)
            Management Information System (MIS). The Strengthening Nutrition
            Project in Nigeria (SNIPS), a project funded by GAIN (Global
            Alliance for Improved Nutrition) is committed to protecting the
            privacy and security of personal information collected through our
            mobile application (SNIPS). This Privacy Policy explains how we
            collect, use, disclose, and protect personal information in
            connection with the App.
          </p>
        </div>
        <div className="mb-[10px]">
          <h1 className="font-bold">Scope</h1>
          <p>
            This Privacy Policy applies to all users of the App, including Field
            Officers, Retailers, and Administrators. By using the App, you
            acknowledge that you have read, understood, and agree to be bound by
            this Privacy Policy.
          </p>
        </div>
        <div className="mb-[10px]">
          <h1 className="font-bold">Information Collection and Use</h1>
          <p>
            We collect information from Field Officers, Retailers, and
            Administrators (collectively referred to as "Users") to facilitate
            the distribution of vitamin A products.
          </p>
        </div>
        <p>
          We may collect the following personal information through the App:
        </p>
        <div className="">
          <p>Field Officers:</p>
          <ul className="pl-[40px]">
            <li className="list-disc pl-[15px]">Name</li>
            <li className="list-disc pl-[15px]">Phone number</li>
            <li className="list-disc pl-[15px]">Location</li>
            <li className="list-disc pl-[15px]">Address</li>
            <li className="list-disc pl-[15px]">Picture</li>
          </ul>
        </div>
        <div className="">
          <p>Retailers:</p>
          <ul className="pl-[40px]">
            <li className="list-disc pl-[15px]">Name</li>
            <li className="list-disc pl-[15px]">Phone number</li>
            <li className="list-disc pl-[15px]">Location</li>
            <li className="list-disc pl-[15px]">Address</li>
            <li className="list-disc pl-[15px]">Picture</li>
          </ul>
        </div>
        <div className="">
          <p>HouseHolds:</p>
          <ul className="pl-[40px]">
            <li className="list-disc pl-[15px]">Name</li>
            <li className="list-disc pl-[15px]">Phone number</li>
            <li className="list-disc pl-[15px]">Location</li>
            <li className="list-disc pl-[15px]">Address</li>
            <li className="list-disc pl-[15px]">Picture</li>
          </ul>
        </div>
        <div className="mb-[20px]">
          <p>Sales data:</p>
          <ul className="pl-[40px]">
            <li className="list-disc pl-[15px]">Name</li>
            <li className="list-disc pl-[15px]">Phone number</li>
            <li className="list-disc pl-[15px]">Location</li>
            <li className="list-disc pl-[15px]">Address</li>
            <li className="list-disc pl-[15px]">Picture</li>
          </ul>
        </div>

        <div className="mb-[20px]">
          <h1 className="font-bold">How We Use Personal Information:</h1>
          <div className="">
            <p>We use personal information for the following purposes:</p>
            <ul className="pl-[40px]">
              <li className="list-disc pl-[15px]">
                To provide access to the App and its features
              </li>
              <li className="list-disc pl-[15px]">
                To manage user accounts and access levels
              </li>
              <li className="list-disc pl-[15px]">
                To enable Field Officers to onboard retailers and register
                households
              </li>
              <li className="list-disc pl-[15px]">
                To facilitate sales tracking and reporting by Retailers and
                Field Officers
              </li>
              <li className="list-disc pl-[15px]">
                To provide real-time data and insights to Administrators
              </li>
              <li className="list-disc pl-[15px]">
                To generate reports on vitamin A distribution
              </li>
              <li className="list-disc pl-[15px]">
                To improve the App's performance and user experience
              </li>
              <li className="list-disc pl-[15px]">
                To comply with legal and regulatory requirements{" "}
              </li>
            </ul>
          </div>
        </div>
        <div>
          <h1 className="font-bold">Data Sharing and Disclosure</h1>
          <p>
            We do not share personal information with third parties except as
            necessary to fulfill the business objectives of SNIPS or as required
            by law. Data may be shared with GAIN and relevant stakeholders to
            support the vitamin A distribution program.
          </p>
        </div>
        <div className="mb-[20px]">
          <h1 className="font-bold">How We Share Personal Information</h1>
          <div className="">
            <p>We may share personal information with:</p>
            <ul className="pl-[40px]">
              <li className="list-disc pl-[15px]">
                GAIN (Global Alliance for Improved Nutrition) as the project
                funder{" "}
              </li>
              <li className="list-disc pl-[15px]">
                Authorized personnel and partners involved in the SNIPS project{" "}
              </li>
              <li className="list-disc pl-[15px]">
                Service providers that help us operate and maintain the App
              </li>
              <li className="list-disc pl-[15px]">
                Law enforcement agencies or regulatory bodies, as required by
                law
              </li>
            </ul>
          </div>
        </div>
        <div className="mb-[20px]">
          <h1 className="font-bold">Data Security and Storage</h1>
          <div className="">
            <p>
              We take reasonable measures to protect personal information from
              unauthorized access, disclosure, or use. Our security measures
              include:
            </p>
            <ul className="pl-[40px]">
              <li className="list-disc pl-[15px]">Data encryption</li>
              <li className="list-disc pl-[15px]">
                Secure servers and databases{" "}
              </li>
              <li className="list-disc pl-[15px]">
                Access controls and authentication{" "}
              </li>
              <li className="list-disc pl-[15px]">
                Regular security updates and testing
              </li>
            </ul>
            <p className="mt-[15px]">
              We store personal information on secure servers. We retain
              personal information for as long as necessary to achieve our
              business objectives and comply with legal obligations.
            </p>
          </div>
        </div>
        <div className="mb-[20px]">
          <h1 className="font-bold">User Rights</h1>
          <div className="">
            <p>You have the right to:</p>
            <ul className="pl-[40px]">
              <li className="list-disc pl-[15px]">Data encryption</li>
              <li className="list-disc pl-[15px]">
                Request access to your personal information{" "}
              </li>
              <li className="list-disc pl-[15px]">
                Request correction or deletion of your personal information{" "}
              </li>
              <li className="list-disc pl-[15px]">
                Object to or restrict the processing of your personal
                information{" "}
              </li>
              <li className="list-disc pl-[15px]">
                Withdraw your consent to the processing of your personal
                information
              </li>
            </ul>
          </div>
        </div>
        <div className="mb-[20px]">
          <h1 className="font-bold">Changes to This Policy</h1>
          <p>
            We may update this Privacy Policy from time to time. We will notify
            you of significant changes through the App or by email. Your
            continued use of the App after the effective date of the revised
            Privacy Policy will be deemed acceptance of the changes.
          </p>
        </div>
        <div className="mb-[20px]">
          <h1 className="font-bold">Contact Us</h1>
          <p className="mb-[10px]">
            If you have any questions, concerns, or complaints about this
            Privacy Policy or our handling of personal information, please
            contact us.
          </p>
          <p>
            By using the App, you acknowledge that you have read, understood,
            and agree to be bound by this Privacy Policy.
          </p>
        </div>
      </div>
    </div>
  );
};

export default PrivacyPolicy;
