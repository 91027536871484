import React, { useState } from "react";
import useRetailer from "../../hooks/useRetailer";
import { Form, Formik } from "formik";
import { createAdminSchema } from "../../utils/validationSchema/adminSchema";
import { IoCloseOutline } from "react-icons/io5";
import InputText from "../input/InputText";
const RetailerModal = ({
  closeModal,
  requestType,
  useerData,
  getAllRetailers,
}) => {
  const [loading, setLoading] = useState(false);
  const { updateNewRetailer } = useRetailer();
  const initialValues = {
    firstName: requestType === "edit" ? useerData?.firstName : "",
    lastName: requestType === "edit" ? useerData?.lastName : "",
    phoneNumber: requestType === "edit" ? useerData?.phoneNumber : "",
    email: requestType === "edit" ? useerData?.email : "",
    businessAddress: requestType === "edit" ? useerData?.businessAddress : "",
  };

  const onSubmit = async (data) => {
    try {
      if (requestType === "edit") {
        setLoading(true);
        const res = await updateNewRetailer({
          ...data,
          id: useerData.id,
        });
        // if (res.code === "00") {
        //   toast.success("Aggregator updated");
        //   closeModal();
        // }
        // if (res.code !== "00") {
        //   setLoading(false);
        //   toast.error(res.message || "Error updating waybill");
        // }
      }
      // if (requestType !== "edit") {
      //   setLoading(true);
      //   const res = await createNewAdmin(data);
      //   if (res.code === "00") {
      //     toast.success("Admin created");
      //     closeModal();
      //   }
      //   if (res.code !== "00") {
      //     setLoading(false);
      //     toast.error(res.message || "Error creating Admin");
      //   }
      // }
    } catch (error) {
      // toast.error(error.message || "something went wrong");
    } finally {
      setLoading(false);
      setTimeout(() => {
        getAllRetailers();
      }, 1000);
    }
  };
  return (
    <Formik
      initialValues={initialValues}
      validationSchema={createAdminSchema}
      onSubmit={onSubmit}
    >
      {({ handleChange, errors, values, handleSubmit, setFieldValue }) => {
        return (
          <Form style={{ height: "500px" }}>
            <div className="flex flex-col gap-y-[12px] min-w-[300px] md:min-w-[450px] lg:w-[465px]  bg-white px-[24px]">
              <div className="flex justify-between pt-[16px]">
                <div className="">
                  <h1 className="capitalize font-bold">
                    {requestType === "edit"
                      ? "Edit retailer"
                      : "create Administrator"}
                  </h1>
                </div>
                <IoCloseOutline
                  style={{ width: 26, height: 26, cursor: "pointer" }}
                  onClick={() => closeModal()}
                />
              </div>
              <div className="w-full pt-[24px]">
                <InputText
                  label={"First Name"}
                  name="firstName"
                  error={errors.firstName}
                  value={values.firstName}
                  fieldRequired={true}
                  className={"w-full"}
                  placeholder={"Enter first name"}
                  handleChange={handleChange}
                />
              </div>
              <div className="w-full">
                <InputText
                  label={"Last Name"}
                  name="lastName"
                  error={errors.lastName}
                  value={values.lastName}
                  fieldRequired={true}
                  className={"w-full"}
                  placeholder={"Enter last name"}
                  handleChange={handleChange}
                />
              </div>
              <div className="w-full">
                <InputText
                  label={"Email"}
                  name="email"
                  error={errors.email}
                  value={values.email}
                  fieldRequired={true}
                  className={"w-full"}
                  placeholder={"Enter email address"}
                  handleChange={handleChange}
                />
              </div>
              <div className="w-full">
                <InputText
                  label={"Phone Number"}
                  name="phoneNumber"
                  error={errors.phoneNumber}
                  value={values.phoneNumber}
                  fieldRequired={true}
                  className={"w-full"}
                  placeholder={"Enter phone number"}
                  handleChange={handleChange}
                />
              </div>
              <div className="w-full">
                <InputText
                  label={"Business Address"}
                  name="businessAddress"
                  error={errors.businessAddress}
                  value={values.businessAddress}
                  fieldRequired={true}
                  className={"w-full"}
                  placeholder={"Enter business address"}
                  handleChange={handleChange}
                />
              </div>

              <div className="flex gap-2 justify-end pb-[24px]">
                <button
                  className="p-[12px] rounded-[8px] w-max h-[40px] font-normal text-xs flex justify-center items-center gap-3 disabled:cursor-not-allowed border border-pfBlack md:w-272"
                  onClick={() => closeModal()}
                >
                  Cancel
                </button>

                {requestType === "edit" ? (
                  <button
                    type="submit"
                    onClick={handleSubmit}
                    className="bg-green-700 rounded-[8px] text-white flex justify-center items-center h-[40px] w-max p-[12px]"
                  >
                    {loading ? "Updating..." : "Save changes"}
                  </button>
                ) : (
                  <button
                    type="submit"
                    onClick={handleSubmit}
                    className="bg-green-700 rounded-[8px] text-white flex justify-center items-center h-[40px] w-max p-[12px]"
                  >
                    {loading ? "Creating..." : "Create Admin"}
                  </button>
                )}
              </div>
            </div>
          </Form>
        );
      }}
    </Formik>
  );
};

export default RetailerModal;
