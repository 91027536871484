import React from "react";
import SignIn from "../pages/auth/SignIn";
// import SignIn from "../pages/auth/SignIn";
// import DashGuard from "../route-guard/DashGuard";
import ResetPassword from "../pages/auth/ResetPassword";
import ForgotPassword from "../pages/auth/ForgotPassword";
import CompleteSignUp from "../pages/auth/CompleteSignUp";
import PrivacyPolicy from "../pages/privacyPolicy";
import VerifyOtp from "../pages/auth/VerifyOtp";
import DeactivateUser from "../pages/user/DeactivateUser";

export const getAuthRouter = () => {
  return [
    {
      path: "/",
      element: <SignIn />,

      // errorElement: <ErrorPage />,
    },
    {
      path: "signin",
      element: <SignIn />,
      // errorElement: <ErrorPage />,
    },
    {
      path: "resetpassword",
      element: <ResetPassword />,
      // errorElement: <ErrorPage />,
    },
    {
      path: "complete-signup/:token",
      element: <CompleteSignUp />,
      // errorElement: <ErrorPage />,
    },
    {
      path: "forgotpassword",
      element: <ForgotPassword />,
      // errorElement: <ErrorPage />,
    },
    {
      path: "verify-otp",
      element: <VerifyOtp />,
      // errorElement: <ErrorPage />,
    },
    {
      path: "privacy-policy",
      element: <PrivacyPolicy />,
      // errorElement: <ErrorPage />,
    },
    {
      path: "deactivate",
      element: <DeactivateUser />,
      // errorElement: <ErrorPage />,
    },
  ];
};
