import React, { forwardRef, useCallback, useEffect, useState } from "react";
import CustomTable from "../../components/table/CustomTable";
import InputSearch from "../../components/input/InputSearch";
import SearchableDropdown from "../../components/input/SearchableDropdown";
import TableTopCard from "../../components/card/TableTopCard";
import TopCard from "../../components/card/TopCard";
import { AiOutlineExport } from "react-icons/ai";
import { FaSliders } from "react-icons/fa6";
import PaginationTab from "../../components/pagination/PaginationTab";
import useSalesRecord from "../../hooks/useSalesRecord";
import useResource from "../../hooks/useResource";
import { getLgaByState } from "../../ds/resource";
import useHousehold from "../../hooks/useHousehold";
import useRetailer from "../../hooks/useRetailer";
import useFieldOfficer from "../../hooks/useFieldOfficer";
import { toMoney } from "../../utils/formatCurrency";

//date range
import moment from "moment";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import useTabTitle from "../../hooks/useTabTitle";

const headers = [
  "Household",
  "Community",
  "Retailer",
  "Field Officer",
  "State",
  "LGA",
  "Product",
  "Variant",
  "Amount",
  "Date & Time",
];
const SalesRecord = () => {
  //for date range
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");

  const [formatStartDate, setFormatStartDate] = useState(null);
  const [formatEndDate, setFormatEndDate] = useState(null);
  const [showFilter, setShowFilter] = useState(false);
  const [allLgas, setAllLgas] = useState([]);
  const [allStates, setAllstates] = useState([]);
  const [allProductVariants, setAllProductVariants] = useState([]);
  const [allProducts, setAllProducts] = useState([]);
  const [allHouseholdList, setAllHouseholdList] = useState([]);
  const [allRetailerList, setAllRetailerList] = useState([]);
  const [allfieldOfficerList, setAllFieldOfficerList] = useState([]);
  const [selectedState, setSelectedState] = useState(null);
  const [selectedStateId, setSelectedStateId] = useState();
  const [selectedLga, setSelectedLga] = useState(null);
  const [communityId, setCommunityId] = useState(null);
  const [productId, setProductId] = useState(null);
  const [fieldOfficerId, setFieldOfficerId] = useState(null);
  const [retailerId, setRetailerId] = useState(null);
  const [householdId, setHouseholdId] = useState(null);
  const [productVariantId, setProductVariantId] = useState(null);
  const [salesRecords, setSalesRecords] = useState([]);
  const [totalPages, setTotalPages] = useState(0);

  const { loading, gatAllSalesRecords, salesRecordDownload } = useSalesRecord();
  const { getAllStates, getAllProduct, getAllProductVariant } = useResource();
  const { householdsList } = useHousehold();
  const { retailersList } = useRetailer();
  const { fieldOfficersList } = useFieldOfficer();
  const { title } = useTabTitle({ title: "Sales Records" });

  const [page, setPage] = useState(1);
  const limit = 25;
  const handlePageChange = (data) => {
    if (data === 0) {
      setPage(1);
    }
    setPage(data + 1);
  };

  const onChange = (dates) => {
    const [start, end] = dates;

    setStartDate(start);
    setEndDate(end);

    start
      ? setFormatStartDate(moment(start).format("YYYY-MM-DD"))
      : setFormatStartDate(null);

    end
      ? setFormatEndDate(moment(end).format("YYYY-MM-DD"))
      : setFormatEndDate(null);
  };

  const getAllSalesRecord = useCallback(
    async (
      // newQuery,
      selState,
      selLga,
      commId,
      houseId,
      retailId,
      fieldOffId,
      prodId,
      prodVarId,
      formatStaDate,
      formatEdDate
    ) => {
      //  const queryData = query || newQuery;
      const newState = selectedState?.value || selState?.value;
      const newLgaId = selectedLga?.value || selLga?.value;
      const newCommunityId = communityId?.value || commId?.value;
      const newHouseholdId = householdId?.value || houseId?.value;
      const newRetailId = retailerId?.value || retailId?.value;
      const newfieldOffId = fieldOfficerId?.value || fieldOffId?.value;
      const newprodId = productId?.value || prodId?.value;
      const newprodVarId = productVariantId?.value || prodVarId?.value;
      const newEdDate = formatEndDate || formatEdDate;
      const newStaDate = formatStartDate || formatStaDate;
      const res = await gatAllSalesRecords({
        page: page,
        size: limit,
        stateId: newState,
        lgaId: newLgaId,
        communityId: newCommunityId,
        houseHoldId: newHouseholdId,
        retailerId: newRetailId,
        fieldOfficerId: newfieldOffId,
        productId: newprodId,
        variantId: newprodVarId,
        endDate: newStaDate,
        startDate: newEdDate,
      });
      setSalesRecords(res);
      setTotalPages(res?.data?.totalPages);
    },
    [page]
  );

  useEffect(() => {
    const getAllState = async () => {
      const res = await getAllStates();
      const list = res.data.map((item) => {
        return {
          label: item.name,
          value: item.id,
        };
      });
      setAllstates([...list]);
    };
    getAllState();
  }, []);

  useEffect(() => {
    if (selectedStateId) {
      const getAllLga = async () => {
        const res = await getLgaByState(selectedStateId);
        const list = res?.data?.map((item) => {
          return {
            label: item.name,
            value: item.id,
          };
        });
        if (list) {
          setAllLgas([...list]);
        }
      };
      getAllLga();
    }
  }, [selectedStateId]);

  useEffect(() => {
    getAllSalesRecord();
  }, [page, getAllSalesRecord]);

  const getAllProducts = async () => {
    const res = await getAllProduct();
    const list = res?.data?.content?.map((item) => {
      return {
        label: item.name,
        value: item.id,
      };
    });
    setAllProducts([...list]);
  };
  const getAllProductVariants = async () => {
    const res = await getAllProductVariant();
    const list = res?.data?.content?.map((item) => {
      return {
        label: item.name,
        value: item.id,
      };
    });
    setAllProductVariants([...list]);
  };
  const getAllHouseholdList = async () => {
    const res = await householdsList();
    const list = res?.data?.map((item) => {
      return {
        label: item.name,
        value: item.id,
      };
    });
    setAllHouseholdList([...list]);
  };
  const getAllRetailerList = async () => {
    const res = await retailersList();
    const list = res?.data?.map((item) => {
      return {
        label: item.name,
        value: item.id,
      };
    });
    setAllRetailerList([...list]);
  };
  const getAllFieldOfficerList = async () => {
    const res = await fieldOfficersList();
    const list = res?.data?.map((item) => {
      return {
        label: item.name,
        value: item.id,
      };
    });
    setAllFieldOfficerList([...list]);
  };

  useEffect(() => {
    getAllProducts();
    getAllProductVariants();
    getAllHouseholdList();
    getAllRetailerList();
    getAllFieldOfficerList();
  }, []);

  const reset = () => {
    // setQuery("");
    setSelectedLga(null);
    setSelectedState(null);
    setSelectedStateId();
    getAllSalesRecord();
  };

  const nextPage = () => {
    // if (page === totalPages) {
    //   setPage(page);
    // }
    setPage((prev) => prev < totalPages ?? prev + 1);
    // getAllSalesRecord(
    //   selectedState,
    //   selectedLga,
    //   communityId,
    //   householdId,
    //   retailerId,
    //   fieldOfficerId,
    //   productId,
    //   productVariantId,
    //   formatEndDate,
    //   formatStartDate
    // );
  };
  const prevPage = () => {
    if (page === 1) {
      setPage(1);
    }
    setPage((prev) => (prev !== 1 && prev > 1) ?? prev - 1);
    // getAllSalesRecord(
    //   selectedState,
    //   selectedLga,
    //   communityId,
    //   householdId,
    //   retailerId,
    //   fieldOfficerId,
    //   productId,
    //   productVariantId,
    //   formatEndDate,
    //   formatStartDate
    // );
  };

  const ExampleCustomInput = forwardRef(({ value, onClick }, ref) => (
    <div className="flex flex-col gap-2">
      <div
        className="example-custom-input border border-gray-300 h-[44px] w-[260px] md:w-[170px] lg:w-full p-2 rounded-md"
        onClick={onClick}
        ref={ref}
      >
        <h3>{value ? value : "Select date"}</h3>
      </div>
    </div>
  ));
  return (
    <div className="p-4">
      <div className="flex gap-[16px] mb-10">
        <TopCard
          title={"Total Sales Value"}
          // subtitle={"Sales amount"}
          userCount={`₦${toMoney(salesRecords?.data?.totalSales || 0)}`}
          css={"bg-[#EFF8FF] border border-gray-300"}
        />
      </div>
      <div className="rounded-[8px] border border-gray-300">
        <div className="border-b border-gray-300">
          <TableTopCard
            title={"Sales"}
            subtitle={"keep track of Sales"}
            buttonTitle={"Export"}
            className={"bg-white text-black border border-gray-300"}
            icon={AiOutlineExport}
            setShowModal={() =>
              salesRecordDownload({
                stateId: selectedState?.value,
                lgaId: selectedLga?.value,
                communityId: communityId?.value,
                houseHoldId: householdId?.value,
                retailerId: retailerId?.value,
                fieldOfficerId: fieldOfficerId?.value,
                productId: productId?.value,
                variantId: productVariantId?.value,
                startDate: formatStartDate,
                endDate: formatEndDate,
              })
            }
          />
        </div>
        <div className="flex flex-col justify-end items-center md:flex-row lg:flex-row relative h-max p-[10px] gap-[12px]">
          <div
            className="text-green-900 cursor-pointer"
            onClick={() => reset()}
          >
            Clear filter
          </div>
          <div
            className="w-[83px] h-[44px] flex items-center text-green-900 border border-green-900 gap-[8px] p-[12px] rounded-[8px] cursor-pointer"
            onClick={() => setShowFilter((prev) => !prev)}
          >
            <FaSliders /> <span>filter</span>
          </div>
          {showFilter ? (
            <div className="p-[14px] flex flex-col gap-[8px] absolute top-[54px] right-[5px] w-[358px] height-max bg-white rounded-[8px] z-50">
              <div className="flex justify-between items-center">
                <h2 className="text-xl font-semibold">Filters</h2>
                <button
                  className="p-[12px] rounded-[8px] text-green-900"
                  onClick={() => setShowFilter(false)}
                >
                  close
                </button>
              </div>
              <p>Date Range</p>
              <div className="w-full">
                <DatePicker
                  selected={startDate}
                  onChange={onChange}
                  startDate={startDate}
                  endDate={endDate}
                  selectsRange
                  customInput={<ExampleCustomInput />}
                  wrapperClassName="w-full"
                  // containerStyle={{ width: "100%" }}
                  showYearDropdown
                  yearDropdownItemNumber={100}
                  scrollableYearDropdown
                  isClearable
                />
              </div>
              <p>Location</p>
              <div className="flex flex-wrap gap-[12px]">
                <SearchableDropdown
                  options={allStates}
                  selectedOption={selectedState}
                  defaultValue={null}
                  placeholder="state"
                  className={"w-[159px]"}
                  handleChange={(e) => {
                    setSelectedStateId(e?.value);
                    setSelectedState(e);
                  }}
                />
                <SearchableDropdown
                  options={allLgas}
                  selectedOption={selectedLga}
                  defaultValue={null}
                  placeholder="lga"
                  className={"w-[159px]"}
                  handleChange={(e) => {
                    setSelectedLga(e);
                  }}
                />
                <SearchableDropdown
                  options={[]}
                  selectedOption={communityId}
                  defaultValue={null}
                  placeholder="community"
                  className={"w-full"}
                  handleChange={(e) => {
                    setCommunityId(e);
                  }}
                />
              </div>
              <p>Personnel</p>
              <div className="flex flex-col gap-[12px]">
                <SearchableDropdown
                  options={allfieldOfficerList}
                  selectedOption={fieldOfficerId}
                  defaultValue={null}
                  placeholder="FO"
                  className={"w-full"}
                  handleChange={(e) => {
                    setFieldOfficerId(e);
                  }}
                />
                <SearchableDropdown
                  options={allRetailerList}
                  selectedOption={retailerId}
                  defaultValue={null}
                  placeholder="Retailer"
                  className={"w-full"}
                  handleChange={(e) => {
                    setRetailerId(e);
                  }}
                />
                <SearchableDropdown
                  options={allHouseholdList}
                  selectedOption={householdId}
                  defaultValue={null}
                  placeholder="Household"
                  className={"w-full"}
                  handleChange={(e) => {
                    setHouseholdId(e);
                  }}
                />
              </div>
              <p>Products</p>
              <div className="flex flex-wrap gap-[12px]">
                <SearchableDropdown
                  options={allProducts}
                  selectedOption={productId}
                  defaultValue={null}
                  placeholder="product"
                  className={"w-[159px]"}
                  handleChange={(e) => {
                    setProductId(e);
                  }}
                />
                <SearchableDropdown
                  options={allProductVariants}
                  selectedOption={productVariantId}
                  defaultValue={null}
                  placeholder="product variant"
                  className={"w-[159px]"}
                  handleChange={(e) => {
                    setProductVariantId(e);
                  }}
                />
              </div>
              <div className="flex pt-[16px] gap-[16px]">
                <button
                  className="w-[157px] border border-gray-300 text-center rounded-[8px] h-[44px]"
                  onClick={() => reset()}
                >
                  Clear Filter
                </button>
                <button
                  className="w-[157px] text-center bg-green-900 text-white rounded-[8px] h-[44px]"
                  onClick={() =>
                    getAllSalesRecord(
                      selectedState,
                      selectedLga,
                      communityId,
                      householdId,
                      retailerId,
                      fieldOfficerId,
                      productId,
                      productVariantId,
                      formatEndDate,
                      formatStartDate
                    )
                  }
                >
                  Apply
                </button>
              </div>
            </div>
          ) : (
            ""
          )}
        </div>

        {loading ? (
          <div className="flex justify-center">
            <p className="text-center">Loading...</p>
          </div>
        ) : salesRecords?.data?.content?.length === 0 ? (
          <div className="flex justify-center">
            <p className="text-center">No data.</p>
          </div>
        ) : (
          <CustomTable
            headers={headers}
            rows={salesRecords?.data?.content?.map((data, index) => {
              return {
                household: data.household ? data.household : "N/A",
                community: data.community ? data.community : "N/A",
                retailer: data.retailer ? data.retailer : "N/A",
                fieldOfficer: data.fieldOfficer ? data.fieldOfficer : "N/A",
                state: data.state ? data.state : "N/A",
                lga: data.localGovernment ? data.localGovernment : "N/A",
                product: data.product ? data.product : "N/A",
                variant: data.variant ? data.variant : "N/A",
                amount: data.amount ? `₦${toMoney(data.amount)}` : "N/A",
                "date & time": data.createdAt ? data.createdAt : "N/A",
              };
            })}
          />
        )}
      </div>

      <div className="pt-[12px] pb-[16px] px-[24px] flex justify-end">
        <PaginationTab
          pageIndex={page}
          pageCount={salesRecords?.data?.totalPages}
          gotoPage={handlePageChange}
          // pageSize={10}
          canPreviousPage={""}
          canNextPage={""}
          handleNext={() => nextPage()}
          handlePrevious={() => prevPage()}
          // pageOptions,
          setPageSize={""}
          // useAlternatePagination={false}
        />
      </div>
    </div>
  );
};

export default SalesRecord;
