import { Form, Formik } from "formik";
import React, { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { changePasswordSchema } from "../../utils/validationSchema/changePasswordSchema";
import { IoEyeOutline, IoEyeOffOutline } from "react-icons/io5";
import Logo from "../../assets/images/Logo.png";
import useAdmin from "../../hooks/useAdmin";

const CompleteSignUp = () => {
  const navigate = useNavigate();
  const { token } = useParams();
  const [loading, setLoading] = useState(false);
  const [viewPassword, setViewPassword] = useState(false);
  const [error, setError] = useState("");
  const { completeAdminSignUp } = useAdmin();

  const initialValues = {
    password: "",
    password2: "",
  };
  const completeSignUp = async (data) => {
    try {
      setLoading(true);
      const res = await completeAdminSignUp({
        password: data.password,
        confirmPassword: data.password2,
        token: token,
      });
      if (res) {
        navigate("/login");
      }
      setError(res.message);
    } catch (error) {
    } finally {
      setLoading(false);
    }
  };
  return (
    <div className="flex justify-center items-center h-screen bg-[#F9FAFB] p-[24px]">
      <div className="flex flex-col items-center justify-center w-[424px] bg-white h-max p-4 rounded-md">
        {/* <div className="flex flex-col items-center w-[400px]"> */}
        <div className="self-start w-[100px] mb-[14px]">
          <img
            src={Logo}
            height="100"
            alt="logo"
            style={{ width: "100%", height: "auto", objectFit: "contain" }}
          />
        </div>
        <div className="px-2 mt-2 w-full">
          <h4 className="text-xl font-bold">Complete your sign-up</h4>
          <p className="text-sm mb-3 text-gray-500 mb-[32px]">
            Welcome Jon Ogbu, create a secure password to complete your sign-up
            process.
          </p>

          <Formik
            initialValues={initialValues}
            validationSchema={changePasswordSchema}
            onSubmit={completeSignUp}
          >
            {({ handleChange, errors, values, handleSubmit }) => {
              return (
                <Form>
                  <div className="mb-3 w-full">
                    <label htmlFor="password">New Password</label>
                    <div className="flex items-center border border-gray-300 h-30 bg-gray-50">
                      <input
                        type={`${viewPassword ? "text" : "password"}`}
                        className="p-2 w-full"
                        onChange={handleChange}
                        value={values.password}
                        id="password"
                        name="password"
                        placeholder="Enter new password"
                      />
                      <span className="inline-block w-[20px]">
                        {viewPassword ? (
                          <IoEyeOutline
                            onClick={() => setViewPassword(false)}
                          />
                        ) : (
                          <IoEyeOffOutline
                            onClick={() => setViewPassword(true)}
                          />
                        )}
                      </span>
                    </div>
                  </div>
                  <div className="flex flex-col gap-[2px] mb-[24px]">
                    <div className="flex items-center gap-[4px]">
                      <span
                        className={`block w-[14px] h-[14px] rounded-full ${
                          values?.password &&
                          /^(?=.{8,})/.test(values?.password)
                            ? "bg-green-500"
                            : "bg-gray-300"
                        }`}
                      ></span>
                      Must be at least 8 characters
                    </div>
                    <div className="flex items-center gap-[4px]">
                      {" "}
                      <span
                        className={`block w-[14px] h-[14px] rounded-full ${
                          values?.password &&
                          /^(?=.*[A-Z])/.test(values?.password)
                            ? "bg-green-500"
                            : "bg-gray-300"
                        }`}
                      ></span>
                      Must contain an uppercase letter
                    </div>
                    <div className="flex items-center gap-[4px]">
                      {" "}
                      <span
                        className={`block w-[14px] h-[14px] rounded-full ${
                          values?.password &&
                          /^(?=.*[a-z])/.test(values?.password)
                            ? "bg-green-500"
                            : "bg-gray-300"
                        }`}
                      ></span>
                      Must contain a Lowercase letter
                    </div>
                    <div className="flex items-center gap-[4px]">
                      {" "}
                      <span
                        className={`block w-[14px] h-[14px] rounded-full ${
                          values?.password &&
                          /^(?=.*[!@#\$%\^&\*])/.test(values?.password)
                            ? "bg-green-500"
                            : "bg-gray-300"
                        }`}
                      ></span>
                      Must contain a sign or symbol e.g #,@,%,$
                    </div>
                  </div>
                  <div className="mb-3 w-full">
                    <label htmlFor="password2">Confirm Password</label>
                    <div className="flex items-center border border-gray-300 h-30 bg-gray-50">
                      <input
                        type={`${viewPassword ? "text" : "password"}`}
                        className="p-2 w-full"
                        onChange={handleChange}
                        value={values.password2}
                        id="password2"
                        name="password2"
                        placeholder="Confirm new password"
                      />
                      <span className="inline-block w-[20px]">
                        {viewPassword ? (
                          <IoEyeOutline
                            onClick={() => setViewPassword(false)}
                          />
                        ) : (
                          <IoEyeOffOutline
                            onClick={() => setViewPassword(true)}
                          />
                        )}
                      </span>
                    </div>
                    {errors && errors.password2 && (
                      <p className="text-red-500">{errors.password2}</p>
                    )}
                  </div>
                  {error && <p className="text-red-500">{error}</p>}
                  <div className="mt-2 w-full">
                    <button
                      className="bg-green-900 rounded-[8px] w-full flex justify-center items-center p-4 text-white h-[44px]"
                      type="submit"
                      disabled={loading}
                      onClick={() => {
                        const error = [
                          "does not contain 8 Characters",
                          "does not contain one uppercase",
                          "does not contain one lowercase",
                          "does not contain one special case character",
                        ];
                        const hasError =
                          errors?.password?.includes(error) && errors.password2;
                        if (hasError) {
                          console.log("input has error");
                        } else {
                          handleSubmit();
                        }
                      }}
                      // disabled={errors?.password.some()}
                    >
                      {loading ? "loading..." : "Submit"}
                    </button>
                  </div>
                </Form>
              );
            }}
          </Formik>
        </div>
        {/* </div> */}
      </div>
    </div>
  );
};

export default CompleteSignUp;
