import React, { useState } from "react";
import {
  createHousehold,
  downloadHousehold,
  getAllHousehold,
  getAllHouseholdList,
  getHouseholdDetail,
  getHouseholdSummary,
  updateHousehold,
} from "../ds/household";
import { downloadCsvFile } from "../utils/download";
import toast from "react-hot-toast";

const useHousehold = () => {
  const [loading, setLoading] = useState();
  const [downloadData, setDownloadData] = useState("");

  const gatAllHouseHolds = async ({
    page = 1,
    size = 10,
    query,
    selectedState,
    lgaId,
    retailerId,
    communityId,
    fieldOfficerId,
  }) => {
    setLoading(true);
    console.log({
      query,
      selectedState,
      lgaId,
      retailerId,
      communityId,
      fieldOfficerId,
    });
    const res = await getAllHousehold({
      page,
      size,
      nameOrPhoneNumber: query || "",
      stateId: selectedState || "",
      lgaId: lgaId || "",
      retailerId: retailerId || "",
      communityId: communityId || "",
      fieldOfficerId: fieldOfficerId || "",
    });

    setLoading(false);
    return JSON.parse(res);
  };

  const createNewHousehold = (data) => {
    const res = createHousehold(data);
    return res;
  };
  const updateNewHousehold = (data) => {
    const res = updateHousehold(data);
    return res;
  };
  const getHouseholdDetails = async (id) => {
    setLoading(true);
    const res = await getHouseholdDetail(id);

    setLoading(false);
    return res;
  };
  const householdSummary = async (id) => {
    setLoading(true);
    const res = await getHouseholdSummary(id);
    setLoading(false);
    return res;
  };
  const householdsList = async () => {
    setLoading(true);
    const res = await getAllHouseholdList();
    setLoading(false);
    return res;
  };

  const householdDownload = async ({
    nameOrPhoneNumber,
    stateId,
    lgaId,
    retailerId,
    communityId,
  }) => {
    toast.success("download...");
    const res = await downloadHousehold({
      nameOrPhoneNumber: nameOrPhoneNumber || "",
      stateId: stateId || "",
      lgaId: lgaId || "",
      retailerId: retailerId || "",
      communityId: communityId || "",
    });
    return downloadCsvFile(res, "households.csv", setDownloadData, toast);
  };

  return {
    loading,
    gatAllHouseHolds,
    createNewHousehold,
    getHouseholdDetails,
    householdSummary,
    updateNewHousehold,
    householdDownload,
    householdsList,
  };
};

export default useHousehold;
