import React from "react";

const InputText = ({
  placeholder,
  label,
  handleChange,
  value,
  name,
  unit,
  type,
  className,
  error,
  fieldRequired,
  readOnly,
  disabled,
  textStyle,
  ...prop
}) => {
  return (
    <div className={`flex flex-col gap-2 ${textStyle ? textStyle : ""}`}>
      <label
        className={`${
          fieldRequired ? "after:content-['*'] after:text-red-500" : ""
        } `}
      >
        {label}
      </label>
      <div className="flex w-full">
        {unit ? (
          <span className="bg-gray-100 w-[40px] text-sm flex items-center justify-center">
            {unit}
          </span>
        ) : (
          ""
        )}
        <input
          type={type ? type : "text"}
          placeholder={placeholder}
          onChange={handleChange}
          value={value}
          readOnly={readOnly}
          disabled={disabled}
          name={name}
          className={`border border-gray-300 h-[44px] ${
            className ? className : "lg:w-[288px] sm:w-[140px] md:w-[200px]"
          } p-2 rounded-md `}
          {...prop}
        />
      </div>
      {error ? <p className="text-red-500">{error}</p> : ""}
    </div>
  );
};

export default InputText;
