import api from "../api/axios";
import fetcher from "../api/fetacher";

export const createFieldOfficer = async (data) => {
  try {
    const res = await fetcher("/field-officer", {
      method: "POST",
      body: JSON.stringify(data),
      cache: "no-store",
    });
    return res;
  } catch (error) {
    throw error;
  }
};
export const getAllActiveFieldOfficer = async ({
  page,
  size,
  nameOrPhoneNumber,
  stateId,
  lgaId,
  fieldOfficerId,
}) => {
  try {
    const accessToken = localStorage.getItem("accessToken");
    const params = {
      page,
      size,
    };
    if (nameOrPhoneNumber) {
      params.nameOrPhoneNumber = nameOrPhoneNumber;
    }
    if (stateId) {
      params.stateId = stateId;
    }
    if (lgaId) {
      params.lgaId = lgaId;
    }
    if (lgaId) {
      params.lgaId = lgaId;
    }
    if (fieldOfficerId) {
      params.fieldOfficerId = fieldOfficerId;
    }
    const res = await fetcher(
      "/field-officer",
      {
        method: "GET",
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      },
      params,
      true
    );
    return res;
  } catch (error) {
    throw error;
  }
};
export const getAllPendingFieldOfficer = async ({ page, size }) => {
  try {
    const accessToken = localStorage.getItem("accessToken");
    const params = {
      page,
      size,
    };
    const res = await fetcher(
      "/field-officer/status/pending",
      {
        method: "GET",
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      },
      params,
      true
    );
    return res;
  } catch (error) {
    throw error;
  }
};
export const getFieldOfficerDetail = async (id) => {
  try {
    const accessToken = localStorage.getItem("accessToken");
    const res = await fetcher(`/field-officer/details/${id}`, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });
    return res;
  } catch (error) {
    throw error;
  }
};
export const getFieldOfficerMetric = async (id) => {
  try {
    const accessToken = localStorage.getItem("accessToken");
    const res = await fetcher(`/field-officer/metric/${id}`, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });
    return res;
  } catch (error) {
    throw error;
  }
};
export const activateFieldOfficer = async (data) => {
  try {
    const accessToken = localStorage.getItem("accessToken");
    const res = await fetcher("/field-officer/activate", {
      method: "POST",
      body: JSON.stringify(data),
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
      cache: "no-store",
    });
    return res;
  } catch (error) {
    throw error;
  }
};

export const getAllFieldOfficerList = async () => {
  try {
    const accessToken = localStorage.getItem("accessToken");
    const res = await fetcher("/field-officer/list", {
      method: "GET",
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });
    return res;
  } catch (error) {
    throw error;
  }
};

export const downloadFieldOfficer = async ({
  nameOrPhoneNumber,
  stateId,
  lgaId,
}) => {
  try {
    const accessToken = localStorage.getItem("accessToken");
    const params = {};
    if (nameOrPhoneNumber) {
      params.nameOrPhoneNumber = nameOrPhoneNumber;
    }
    if (stateId) {
      params.stateId = stateId;
    }
    if (lgaId) {
      params.lgaId = lgaId;
    }
    const res = await fetcher(
      "/field-officer/download",
      {
        method: "GET",
        // responseType: "blob",
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      },
      params,
      true
    );

    return res;
  } catch (error) {
    throw error;
  }
};
