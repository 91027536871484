/* eslint-disable import/no-extraneous-dependencies */
/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useRef, useState, useEffect, forwardRef } from "react";
import { IoCloseOutline } from "react-icons/io5";
import InputText from "../input/InputText";
import toast from "react-hot-toast";
import { Formik } from "formik";
import { Form } from "react-router-dom";
import { createAdminSchema } from "../../utils/validationSchema/adminSchema";
import useAdmin from "../../hooks/useAdmin";
export const AdminModal = ({
  closeModal,
  requestType,
  getAdmin,
  useerData,
}) => {
  const modalRef = useRef(null);
  const [loading, setLoading] = useState(false);
  const { createNewAdmin } = useAdmin();

  const initialValues = {
    firstName: requestType === "edit" ? useerData?.firstName : "",
    lastName: requestType === "edit" ? useerData?.lastName : "",
    phoneNumber: requestType === "edit" ? useerData?.phoneNumber : "",
    email: requestType === "edit" ? useerData?.email : "",
  };

  const onSubmit = async (data) => {
    try {
      if (requestType === "edit") {
        // setLoading(true);
        // const res = await updateExistingAggregator({
        //   ...data,
        //   id: useerData.id,
        //   programId: program,
        // });
        // if (res.code === "00") {
        //   toast.success("Aggregator updated");
        //   closeModal();
        // }
        // if (res.code !== "00") {
        //   setLoading(false);
        //   toast.error(res.message || "Error updating waybill");
        // }
      }
      if (requestType !== "edit") {
        setLoading(true);
        const res = await createNewAdmin(data);
        if (res.code === "00") {
          toast.success("Admin created");
          closeModal();
        }
        if (res.code !== "00") {
          setLoading(false);
          toast.error(res.message || "Error creating Admin");
        }
      }
    } catch (error) {
      toast.error(error.message || "something went wrong");
    } finally {
      setLoading(false);
      setTimeout(() => {
        getAdmin();
      }, 1000);
    }
  };

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={createAdminSchema}
      onSubmit={onSubmit}
    >
      {({ handleChange, errors, values, handleSubmit, setFieldValue }) => {
        return (
          <Form style={{ height: "500px" }}>
            <div className="flex flex-col gap-y-[12px] min-w-[300px] md:min-w-[450px] lg:w-[640px]  bg-white p-4 ">
              <div className="flex justify-between">
                <div className="">
                  <h1 className="capitalize font-bold">
                    {requestType === "edit"
                      ? "Edit admin"
                      : "create Administrator"}
                  </h1>
                </div>
                <IoCloseOutline
                  style={{ width: 26, height: 26, cursor: "pointer" }}
                  onClick={() => closeModal()}
                />
              </div>
              <div className="w-full">
                <InputText
                  label={"First Name"}
                  name="firstName"
                  error={errors.firstName}
                  value={values.firstName}
                  fieldRequired={true}
                  className={"w-full"}
                  placeholder={"Enter first name"}
                  handleChange={handleChange}
                />
              </div>
              <div className="w-full">
                <InputText
                  label={"Last Name"}
                  name="lastName"
                  error={errors.lastName}
                  value={values.lastName}
                  fieldRequired={true}
                  className={"w-full"}
                  placeholder={"Enter last name"}
                  handleChange={handleChange}
                />
              </div>
              <div className="w-full">
                <InputText
                  label={"Company Email"}
                  name="email"
                  error={errors.email}
                  value={values.email}
                  fieldRequired={true}
                  className={"w-full"}
                  placeholder={"Enter email address"}
                  handleChange={handleChange}
                />
              </div>
              <div className="w-full">
                <InputText
                  label={"Phone Number"}
                  name="phoneNumber"
                  error={errors.phoneNumber}
                  value={values.phoneNumber}
                  fieldRequired={true}
                  className={"w-full"}
                  placeholder={"Enter phone number"}
                  handleChange={handleChange}
                />
              </div>

              <div className="flex gap-2 justify-end">
                <button
                  className="p-[12px] rounded-[8px] w-max h-[40px] font-normal text-xs flex justify-center items-center gap-3 disabled:cursor-not-allowed border border-pfBlack md:w-272"
                  onClick={() => closeModal()}
                >
                  Cancel
                </button>

                {requestType === "edit" ? (
                  <button
                    type="submit"
                    onClick={handleSubmit}
                    className="bg-green-700 rounded-[8px] text-white flex justify-center items-center h-[40px] w-max p-[12px]"
                  >
                    {loading ? "Updating..." : "Save changes"}
                  </button>
                ) : (
                  <button
                    type="submit"
                    onClick={handleSubmit}
                    className="bg-green-700 rounded-[8px] text-white flex justify-center items-center h-[40px] w-max p-[12px]"
                  >
                    {loading ? "Creating..." : "Create Admin"}
                  </button>
                )}
              </div>
            </div>
          </Form>
        );
      }}
    </Formik>
  );
};
