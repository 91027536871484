import React, { Children, useRef, useState } from "react";
import { BsBoxArrowRight } from "react-icons/bs";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { PiArrowFatLinesRight, PiArrowFatLinesLeft } from "react-icons/pi";
import { useDispatch, useSelector } from "react-redux";
// import { logout } from "../../feature/auth";
import useOutsideClick from "../../hooks/useOutsideClick";
// import SettingsModal from "../modal/SettingsModal";
// import Modal from "../Modal";
import { IoIosArrowUp } from "react-icons/io";
import { IoIosArrowDown } from "react-icons/io";
import DEFLOGO from "../../assets/images/Logo.png";
import CLOSENAVLOGO from "../../assets/images/closenav-logo.png";
// import NpsapLogo from "../../assets/images/npsap_logo.jpeg";
import { FaTimes } from "react-icons/fa";
import { logout } from "../../features/auth";
import { MdArrowForwardIos, MdArrowBackIos } from "react-icons/md";
const DashboardSidebar = ({
  setCloseNav,
  closeNav,
  navs = [],
  setShowmobileNav,
}) => {
  let location = useLocation();
  const wrapperRef = useRef(null);
  const [showSettings, setShowSettings] = useOutsideClick(wrapperRef);
  const [isSubMenuShow, setIsSubMenuShow] = useState(false);
  const [activeIndex, setActiveIndex] = useState(false);
  // const { program } = useSelector((state) => state.auth);

  // const { logout } = useSelector((state) => state.auth);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const logoutUser = () => {
    localStorage.clear();
    localStorage.removeItem("persist:root");
    dispatch(logout());
    navigate("/");
  };

  return (
    <div className={` h-screen`}>
      <div className="flex justify-between items-center mb-10 px-2">
        <FaTimes
          onClick={() => setShowmobileNav(false)}
          className="w-[30px] h-30 h-max lg:hidden mt-3 relative"
        />
        {closeNav ? (
          <div className="w-[61px] h-max">
            <div className="w-[59.5px] h-[68.23px] sm:hidden md:hidden lg:block pr-[8px]">
              <img
                src={CLOSENAVLOGO}
                alt={"logo"}
                style={{ width: "100%", height: "100%", objectFit: "contain" }}
              />
            </div>
          </div>
        ) : (
          <div className="w-[173px] h-max sm:hidden md:hidden lg:block px-[8px]">
            <img
              src={DEFLOGO}
              alt={"logo"}
              style={{ width: "100%", height: "100%", objectFit: "contain" }}
            />
          </div>
        )}
        {closeNav ? (
          <div className="w-[28px] h-[28px] rounded-full bg-orange-600 flex justify-center items-center p-[6px] absolute left-[69px]">
            <MdArrowForwardIos
              style={{
                width: 16,
                height: 16,
                cursor: "pointer",
                color: "white",
              }}
              onClick={() => setCloseNav((prev) => !prev)}
            />
          </div>
        ) : (
          <div className="w-[28px] h-[28px] rounded-full bg-orange-600 flex justify-center items-center p-[6px]">
            <MdArrowBackIos
              style={{
                width: 16,
                height: 16,
                cursor: "pointer",
                color: "white",
              }}
              onClick={() => setCloseNav((prev) => !prev)}
            />
          </div>
        )}
      </div>
      <div className={`flex flex-col justify-between h-full`}>
        <div className="basis-[75%]">
          <ul>
            {navs.map(({ id, Icon, name, route, children }, index) => {
              return (
                <Link to={`${route}`} key={`${index}`}>
                  <li className={`flex flex-col h-[62px]`}>
                    <div
                      className={`flex items-center justify-between h-10 rounded-md px-[8px] py-[4px] ${
                        location.pathname === route
                          ? "bg-green-700 text-white"
                          : ""
                      } ${
                        children && isSubMenuShow && location.pathname !== route
                          ? "bg-[#F0F1F1]"
                          : ""
                      }  p-2`}
                      onClick={() => {
                        setIsSubMenuShow(children ? !isSubMenuShow : "");
                        setActiveIndex(index);
                      }}
                    >
                      <div className="flex items-center gap-[12px] text-md">
                        <Icon
                          style={{
                            width: 24,
                            height: 24,
                            color: `${
                              location.pathname === route ? "white" : ""
                            }`,
                          }}
                        />
                        {closeNav ? "" : name}
                      </div>
                      {children ? (
                        <div>
                          {isSubMenuShow ? (
                            <IoIosArrowDown className="self-end" />
                          ) : (
                            <IoIosArrowUp className="self-end" />
                          )}
                        </div>
                      ) : (
                        ""
                      )}
                    </div>
                    {children && isSubMenuShow && (
                      <div
                        className={`h-max w-full p-2 bg-[#F0F1F1] ${
                          closeNav ? "items-center" : ""
                        }`}
                      >
                        <ul className="h-max w-full flex flex-col boder border-gray-300">
                          {children?.map(
                            ({ id, route: routeValue, Icon, name }, index) => {
                              return (
                                <li
                                  key={`${index}-${id}`}
                                  className={`flex items-center  gap-4 h-10 rounded-md mb-1 ${
                                    location.pathname === routeValue
                                      ? "bg-green-700 text-white"
                                      : "bg-white"
                                  }   p-2`}
                                  onClick={() => navigate(routeValue)}
                                >
                                  {Icon ? (
                                    <Icon
                                      style={{
                                        width: 24,
                                        height: 24,
                                        color: `${
                                          location.pathname === routeValue
                                            ? "white"
                                            : "black"
                                        }`,
                                      }}
                                    />
                                  ) : (
                                    ""
                                  )}
                                  {closeNav ? "" : name}
                                </li>
                              );
                            }
                          )}
                        </ul>
                      </div>
                    )}
                  </li>
                </Link>
              );
            })}
          </ul>
        </div>
        <div className="basis-[25%] items-end">
          <div className="">
            <button
              className="flex items-center gap-4 h-10 p-2 "
              onClick={() => logoutUser()}
            >
              <BsBoxArrowRight style={{ width: 24, height: 24 }} />
              {closeNav ? "" : "Logout"}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DashboardSidebar;
