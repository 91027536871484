import React, { useState } from "react";
import {
  createSalesRecord,
  downloadSalesRecords,
  getAllSalesRecord,
  getSalesDetail,
} from "../ds/salesRecord";
import { downloadCsvFile } from "../utils/download";
import toast from "react-hot-toast";

const useSalesRecord = () => {
  const [loading, setLoading] = useState();
  const [downloadData, setDownloadData] = useState("");

  const gatAllSalesRecords = async ({
    page = 1,
    size = 10,
    stateId,
    lgaId,
    communityId,
    houseHoldId,
    retailerId,
    fieldOfficerId,
    productId,
    variantId,
    startDate,
    endDate,
  }) => {
    setLoading(true);
    const res = await getAllSalesRecord({
      page,
      size,
      stateId: stateId || "",
      lgaId: lgaId || "",
      communityId: communityId || "",
      houseHoldId: houseHoldId || "",
      retailerId: retailerId || "",
      fieldOfficerId: fieldOfficerId || "",
      productId: productId || "",
      variantId: variantId || "",
      startDate: startDate || "",
      endDate: endDate || "",
    });

    setLoading(false);
    return JSON.parse(res);
  };

  const createNewSalesRecord = (data) => {
    const res = createSalesRecord(data);
    return res;
  };

  const getSalesRecordDetails = async (id) => {
    setLoading(true);
    const res = await getSalesDetail(id);

    setLoading(false);
    return res;
  };

  const salesRecordDownload = async ({
    stateId,
    lgaId,
    communityId,
    houseHoldId,
    retailerId,
    fieldOfficerId,
    productId,
    startDate,
    endDate,
    variantId,
  }) => {
    toast.success("download...");
    const res = await downloadSalesRecords({
      stateId: stateId || "",
      lgaId: lgaId || "",
      communityId: communityId || "",
      houseHoldId: houseHoldId || "",
      retailerId: retailerId || "",
      fieldOfficerId: fieldOfficerId || "",
      productId: productId || "",
      startDate: startDate || "",
      endDate: endDate || "",
      variantId: variantId || "",
    });
    return downloadCsvFile(res, "households.csv", setDownloadData, toast);
  };

  return {
    loading,
    gatAllSalesRecords,
    createNewSalesRecord,
    getSalesRecordDetails,
    salesRecordDownload,
  };
};

export default useSalesRecord;
