import React, { useEffect, useState } from "react";
import {
  createCommunity,
  getAllCommunity,
  updateCommunity,
} from "../ds/community";

const useCommunity = () => {
  const [loading, setLoading] = useState();
  const [communities, setCommunities] = useState();

  const gatAllCommunities = async (
    page = 1,
    size = 10,
    query,
    selectedState,
    program
  ) => {
    setLoading(true);
    const res = await getAllCommunity({
      page,
      size,
      nameOrEmailOrPhoneNumber: query || "",
      state: selectedState || "",
    });

    setLoading(false);
    return JSON.parse(res);
  };

  const createNewCommunity = (data) => {
    const res = createCommunity(data);
    return res;
  };
  const updateNewCommunity = (data) => {
    const res = updateCommunity(data);
    return res;
  };

  return {
    loading,
    gatAllCommunities,
    createNewCommunity,
    updateNewCommunity,
  };
};

export default useCommunity;
