import fetcher from "../api/fetacher";

export const createSalesRecord = async (data) => {
  try {
    const accessToken = localStorage.getItem("accessToken");

    const res = await fetcher("/sales", {
      method: "POST",
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
      body: JSON.stringify(data),
      cache: "no-store",
    });
    return res;
  } catch (error) {
    throw error;
  }
};

export const updateSalesRecord = async (data) => {
  try {
    const res = await fetcher("/sales/update", {
      method: "POST",
      body: JSON.stringify(data),
      cache: "no-store",
    });
    return res;
  } catch (error) {
    throw error;
  }
};
export const getAllSalesRecord = async ({
  page,
  size,
  stateId,
  lgaId,
  communityId,
  houseHoldId,
  retailerId,
  fieldOfficerId,
  productId,
  variantId,
  startDate,
  endDate,
}) => {
  try {
    const accessToken = localStorage.getItem("accessToken");
    const params = {
      page,
      size,
    };
    if (stateId) {
      params.stateId = stateId;
    }
    if (lgaId) {
      params.lgaId = lgaId;
    }
    if (communityId) {
      params.communityId = communityId;
    }
    if (houseHoldId) {
      params.houseHoldId = houseHoldId;
    }
    if (retailerId) {
      params.retailerId = retailerId;
    }
    if (fieldOfficerId) {
      params.fieldOfficerId = fieldOfficerId;
    }
    if (productId) {
      params.productId = productId;
    }
    if (variantId) {
      params.variantId = variantId;
    }
    if (startDate) {
      params.startDate = startDate;
    }
    if (endDate) {
      params.endDate = endDate;
    }
    const res = await fetcher(
      "/sales",
      {
        method: "GET",
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      },
      params,
      true
    );
    return res;
  } catch (error) {
    throw error;
  }
};

export const getSalesDetail = async (id) => {
  try {
    const accessToken = localStorage.getItem("accessToken");
    const res = await fetcher(`/sales/${id}`, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });
    return res;
  } catch (error) {
    throw error;
  }
};

export const downloadSalesRecords = async ({
  stateId,
  lgaId,
  communityId,
  houseHoldId,
  retailerId,
  fieldOfficerId,
  productId,
  variantId,
  startDate,
  endDate,
}) => {
  try {
    const accessToken = localStorage.getItem("accessToken");

    const params = {};
    if (stateId) {
      params.stateId = stateId;
    }
    if (lgaId) {
      params.lgaId = lgaId;
    }
    if (communityId) {
      params.communityId = communityId;
    }

    if (houseHoldId) {
      params.houseHoldId = houseHoldId;
    }
    if (retailerId) {
      params.retailerId = retailerId;
    }
    if (fieldOfficerId) {
      params.fieldOfficerId = fieldOfficerId;
    }
    if (productId) {
      params.productId = productId;
    }
    if (variantId) {
      params.variantId = variantId;
    }
    if (startDate) {
      params.startDate = startDate;
    }
    if (endDate) {
      params.endDate = endDate;
    }
    if (variantId) {
      params.variantId = variantId;
    }
    const res = await fetcher(
      "/sales/download",
      {
        method: "GET",
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      },
      params,
      true
    );

    return res;
  } catch (error) {
    throw error;
  }
};
