import React, { useEffect } from "react";

const useTabTitle = ({ title }) => {
  useEffect(() => {
    document.title = title;
  }, []);

  return {
    title,
  };
};

export default useTabTitle;
