import React, { useCallback, useEffect, useRef, useState } from "react";
import { useParams } from "react-router-dom";
import Avatar from "../../assets/images/Dropdown.png";
import TopCard from "../../components/card/TopCard";
import TopMiniCard from "../../components/card/TopMiniCard";
import CustomTab from "../../components/tabs/CustomTab";
import CustomTable from "../../components/table/CustomTable";
import PaginationTab from "../../components/pagination/PaginationTab";
import InputSearch from "../../components/input/InputSearch";
import useFieldOfficer from "../../hooks/useFieldOfficer";
import useHousehold from "../../hooks/useHousehold";
import Modal from "../../components/Modal";
import HouseholdModal from "../../components/modals/HouseholdModal";
import useOutsideClick from "../../hooks/useOutsideClick";
import { CircularProgressbarWithChildren } from "react-circular-progressbar";
import useSalesRecord from "../../hooks/useSalesRecord";
import { toMoney } from "../../utils/formatCurrency";

const householdHeaders = [
  // "Household",
  // "Retailer",
  // // "Field Officer",
  // "Phone Number",
  // "State",
  // "Lga",
  "Product",
  "Product Variant",
  "Amount",
  "Date & Time",
];
const HouseholdDetail = () => {
  const { id } = useParams();
  const wrapperRef = useRef(null);
  const [showProfile, setShowProfile] = useOutsideClick(wrapperRef);
  const [householdDetail, setHouseholdDetail] = useState(1);
  const [householdsSummary, setHouseholdsSummary] = useState(1);
  const [salesPage, setSalesPage] = useState(1);
  const { getHouseholdDetails, householdSummary } = useHousehold();
  const [salesRecords, setSalesRecords] = useState([]);
  const [salesTotalPage, setSalesTotalPage] = useState();
  const { gatAllSalesRecords } = useSalesRecord();

  const getHouseholdDetail = async () => {
    const res = await getHouseholdDetails(id);
    setHouseholdDetail(res);
  };
  const getHouseholdSummary = async () => {
    const res = await householdSummary(id);
    setHouseholdsSummary(res?.data);
  };
  const getAllSalesRecord = useCallback(async () => {
    const res = await gatAllSalesRecords({
      houseHoldId: id,
    });
    setSalesRecords(res);
    setSalesTotalPage(res?.data?.totalPages);
  }, [id]);

  const handleSalesTabPageChange = (data) => {
    if (data === 0) {
      setSalesPage(1);
    }
    setSalesPage(data + 1);
  };

  useEffect(() => {
    getHouseholdDetail();
    getHouseholdSummary();
    getAllSalesRecord();
  }, [getAllSalesRecord, id]);

  return (
    <div className="p-[24px] flex gap-[24px]">
      <div className=" p-[16px] border border-gray-300 w-[240px] rounded-md">
        <div className="flex justify-center w-full mb-[24px]">
          <div className="h-[120px] w-[120px] rounded-full">
            <img
              src={householdDetail?.data?.image}
              alt="profile"
              style={{ width: "100%", height: "100%", objectFit: "contain" }}
            />
          </div>
        </div>
        <div className="mb-[24px] border border-gray-300">
          <button
            className="h-[40px] flex items-center justify-center w-full p-[12px] rounded-[8px]"
            onClick={() => setShowProfile(true)}
          >
            Edit
          </button>
        </div>
        <div className="flex flex-col gap-[24px]">
          <div>
            <div className="mb-[16px]">
              <p className="capitalize bg-[#FFFAEB] px-[16px] py-[6px]">
                basic details
              </p>
            </div>
            <div>
              <div className="px-[16px] mb-[12px]">
                <p className="text-[12px]">Household</p>
                <p className="text-[14px] font-bold">
                  {householdDetail?.data?.household || "N/A"}
                </p>
              </div>
              <div className="px-[16px] mb-[12px]">
                <p className="text-[12px]">Field Officer</p>
                <p className="text-[14px] font-bold">
                  {householdDetail?.data?.fieldOfficer || "N/A"}
                </p>
              </div>
              <div className="px-[16px] mb-[12px]">
                <p className="text-[12px]">Retailer</p>
                <p className="text-[14px] font-bold">
                  {householdDetail?.data?.retailer || "N/A"}
                </p>
              </div>
            </div>
          </div>
          <div>
            <div className="mb-[16px]">
              <p className="capitalize bg-[#FFFAEB] px-[16px] py-[6px]">
                Contact details
              </p>
            </div>
            <div>
              <div className="px-[16px] mb-[12px]">
                <p className="text-[12px]">Email Address</p>
                <p className="text-[14px] font-bold">
                  {householdDetail?.data?.email || "N/A"}
                </p>
              </div>
              <div className="px-[16px] mb-[12px]">
                <p className="text-[12px]">Phone Number</p>
                <p className="text-[14px] font-bold">
                  {householdDetail?.data?.phoneNumber || "N/A"}
                </p>
              </div>
            </div>
          </div>
          <div>
            <div className="mb-[16px]">
              <p className="capitalize bg-[#FFFAEB] px-[16px] py-[6px]">
                Location details
              </p>
            </div>
            <div>
              <div className="px-[16px] mb-[12px]">
                <p className="text-[12px]">State</p>
                <p className="text-[14px] font-bold">
                  {householdDetail?.data?.state
                    ? householdDetail?.data?.state
                    : "N/A"}
                </p>
              </div>
              <div className="px-[16px] mb-[12px]">
                <p className="text-[12px]">Lga's</p>
                {householdDetail?.data?.localGovernments?.map((data) => {
                  return <p className="text-[14px] font-bold">{data.name}</p>;
                })}
              </div>
              <div className="px-[16px] mb-[12px]">
                <p className="text-[12px]">Date</p>
                <p className="text-[14px] font-bold">
                  {householdDetail?.data?.createdAt?.split(" ")[0] || "N/A"} |{" "}
                  {householdDetail?.data?.createdAt?.split(" ")[1] || "N/A"}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="flex flex-col gap-[24px] w-[calc(100%-240px)]">
        <div className="flex gap-[16px]">
          <div className="basis-[50%] flex flex-wrap w-[449.5px] gap-[16px]">
            <TopMiniCard
              css={"basis-[100%] bg-[#F9FAFB]"}
              title={"Total purchase volume"}
              subtitle={householdsSummary?.totalSalesVolume || 0}
              // subtlet={"Sales"}
            />
            <TopMiniCard
              css={"basis-[100%] bg-[#EFF8FF]"}
              title={"Total purchase value"}
              subtitle={householdsSummary?.totalSalesValue || 0}
              subtlet={"Last order: 3 May 2024"}
              textColor={"text-[#2E90FA]"}
            />
          </div>
          <div className="basis-[50%] flex flex-col gap-[16px]">
            <div className="px-[16px] py-[18px] flex justify-between items-center gap-[16px border border-gray-300 rounded-[8px] h-[114px] basis-[50%]">
              <div>
                <p className="mb-[10px]">Product with the highest value</p>
                <p className="flex gap-[15px] items-center text-sm">
                  Product: <span className="font-bold text-sm">{householdsSummary?.highestSalesProduct}</span>
                </p>
                <p className="flex gap-[15px] items-center text-sm">
                  Total value:{" "}
                  <span className="font-bold text-sm">
                    {" "}
                    {`₦${toMoney(householdsSummary?.highestSalesProductValue)}`}
                  </span>
                </p>
              </div>
              <div className="w-[90px] h-[90px]">
                <CircularProgressbarWithChildren
                  value={80}
                  strokeWidth={17}
                  styles={{
                    path: {
                      // Path color
                      stroke: `#7F56D9`,
                      // Whether to use rounded or flat corners on the ends - can use 'butt' or 'round'
                      strokeLinecap: "butt",
                      // // Customize transition animation
                      // transition: "stroke-dashoffset 0.5s ease 0s",
                      // // Rotate the path
                      // transform: "rotate(0.25turn)",
                      // transformOrigin: "center center",
                    },
                  }}
                >
                  <div className="flex flex-col justify-center">
                    <p className="text-center">Sales</p>
                    <p className="text-center font-bold">
                      {toMoney(
                        householdsSummary?.highestSalesProductValue || 0
                      )}
                    </p>
                  </div>
                </CircularProgressbarWithChildren>
              </div>
            </div>
            <div className="px-[16px] py-[18px] flex justify-between items-center gap-[16px border border-gray-300 rounded-[8px] h-[114px] basis-[50%]">
              <div>
                <p className="mb-[10px]">Product with the lowest values</p>
                <p className="flex gap-[15px] items-center text-sm">
                  Product: <span className="font-bold text-sm">{householdsSummary?.lowestSalesProduct}</span>
                </p>
                <p className="flex gap-[15px] items-center text-sm">
                  Total value:{" "}
                  <span className="font-bold text-sm">
                    {`₦${toMoney(
                      householdsSummary?.lowestSalesProductValue || 0
                    )}`}
                  </span>
                </p>
              </div>
              <div className="w-[90px] h-[90px]">
                <CircularProgressbarWithChildren
                  value={10}
                  strokeWidth={17}
                  counterClockwise={true}
                  styles={{
                    path: {
                      // Path color
                      stroke: `#F79009`,
                      // Whether to use rounded or flat corners on the ends - can use 'butt' or 'round'
                      strokeLinecap: "butt",
                      // // Customize transition animation
                      // transition: "stroke-dashoffset 0.5s ease 0s",
                      // // Rotate the path
                      // transform: "rotate(0.25turn)",
                      // transformOrigin: "center center",
                    },
                  }}
                >
                  <div className="flex flex-col justify-center">
                    <p className="text-center">Sales</p>
                    <p className="text-center font-bold">
                      {toMoney(householdsSummary?.lowestSalesProductValue || 0)}
                    </p>
                  </div>
                </CircularProgressbarWithChildren>
              </div>
            </div>
          </div>
        </div>
        <div>
          <div>
            {/* <div className="px-[16px] py-[12px]">
              <InputSearch
                placeholder={"search by name, or phone number"}
                css={"w-[300px]"}
                classDefName={"w-[300px]"}
              />
            </div> */}
            <CustomTable
              headers={householdHeaders}
              rows={salesRecords?.data?.content.map((data, index) => {
                return {
                  // household: data.household ? data.household : "N/A",
                  // retailer: data.retailer ? data.retailer : "N/A",
                  // fieldOfficer: data.fieldOfficer ? data.fieldOfficer : "N/A",
                  // phoneNumber: data.phoneNumber ? data.phoneNumber : "N/A",
                  // state: data.state ? data.state : "N/A",
                  // localGovernment: data.localGovernment
                  //   ? data.localGovernment
                  //   : "N/A",
                  product: data.product ? data.product : "N/A",
                  "product variant": data.variant ? data.variant : "N/A",
                  amount: data.amount ? `₦${toMoney(data.amount)}` : "0",
                  "date & time": data.createdAt ? data.createdAt : "N/A",
                };
              })}
            />
            <div className="pt-[12px] pb-[16px] px-[24px] flex justify-end">
              <PaginationTab
                pageIndex={salesPage}
                pageCount={salesRecords?.data?.totalPages}
                gotoPage={handleSalesTabPageChange}
                // pageSize={10}
                // canPreviousPage={""}
                // canNextPage={""}
                // pageOptions,
                setPageSize={""}
                // useAlternatePagination={false}
              />
            </div>
          </div>
        </div>
      </div>
      {showProfile && (
        <Modal
          variant="default"
          refProp={wrapperRef}
          closeModal={() => setShowProfile(false)}
        >
          <HouseholdModal
            closeModal={() => setShowProfile(false)}
            useerData={householdDetail?.data}
            requestType={"edit"}
            getHouseholds={getHouseholdDetail}
          />
        </Modal>
      )}
    </div>
  );
};

export default HouseholdDetail;
