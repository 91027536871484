import fetcher from "../api/fetacher";

export const getState = async (program) => {
  try {
    const accessToken = localStorage.getItem("accessToken");
    const res = await fetcher("/state", {
      method: "GET",
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });
    return res;
  } catch (error) {
    throw error;
  }
};
export const getLga = async (program) => {
  try {
    const accessToken = localStorage.getItem("accessToken");

    const res = await fetcher("/lga", {
      method: "GET",
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });
    return res;
  } catch (error) {
    throw error;
  }
};

export const getLgaByState = async (id) => {
  try {
    const accessToken = localStorage.getItem("accessToken");

    const res = await fetcher(`/state/${id}/localgovernment`, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });
    return res;
  } catch (error) {
    throw error;
  }
};
export const getAllProducts = async () => {
  try {
    const accessToken = localStorage.getItem("accessToken");
    const res = await fetcher(`/product`, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });
    return res;
  } catch (error) {
    throw error;
  }
};
export const getAllProductVariants = async () => {
  try {
    const accessToken = localStorage.getItem("accessToken");
    const res = await fetcher(`/product/variant`, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });
    return res;
  } catch (error) {
    throw error;
  }
};
