import React from "react";

const TableTopCard = ({
  title,
  subtitle,
  buttonTitle,
  className,
  icon: Icon,
  setShowModal = () => {},
}) => {
  return (
    <div className="flex flex-col lg:flex-row justify-between items-center w-full mb-[20px] gap-[16px] px-[24px] pt-[20px] rounded-[8px]">
      <div className="flex flex-col gap-[4px] w-full">
        <h1 className="font-bold capitalize text-2xl">{title}</h1>
        <p className="text-sm">{subtitle}</p>
      </div>
      <div className="flex">
        {buttonTitle ? (
          <button
            className={`${
              className ? className : ""
            } flex justify-center items-center h-[40px] min-w-[200px] py-[12px] px-[12px] gap-2 rounded-md`}
            onClick={() => setShowModal()}
          >
            {Icon ? <Icon /> : ""}
            {buttonTitle}
          </button>
        ) : (
          ""
        )}
      </div>
    </div>
  );
};

export default TableTopCard;
