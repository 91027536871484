import React, { useCallback, useEffect, useState } from "react";
import CustomTable from "../../components/table/CustomTable";
import InputSearch from "../../components/input/InputSearch";
import SearchableDropdown from "../../components/input/SearchableDropdown";
import TableTopCard from "../../components/card/TableTopCard";
import TopCard from "../../components/card/TopCard";
import { AiOutlineExport } from "react-icons/ai";
import PaginationTab from "../../components/pagination/PaginationTab";
import useHousehold from "../../hooks/useHousehold";
import { useNavigate } from "react-router-dom";
import { HiOutlineDotsVertical } from "react-icons/hi";
import { FaSliders } from "react-icons/fa6";
import { getLgaByState } from "../../ds/resource";
import useResource from "../../hooks/useResource";
import useTabTitle from "../../hooks/useTabTitle";

const headers = [
  "Household",
  "Retailer",
  // "Field Officer",
  "Phone Number",
  "State",
  "Lga",
  "Date & Time",
];

const HouseHolds = () => {
  const [page, setPage] = useState(1);
  const [households, setHouseholds] = useState(1);
  const [showMore, setShowMore] = useState(false);
  const [activeHouseholdIndex, setActiveHouseholdIndex] = useState();
  const [showFilter, setShowFilter] = useState(false);
  const [selectedState, setSelectedState] = useState(null);
  const [selectedStateId, setSelectedStateId] = useState();
  const [selectedLga, setSelectedLga] = useState(null);
  const [query, setQuery] = useState("");
  const [allLgas, setAllLgas] = useState([]);
  const [allStates, setAllstates] = useState([]);
  const [totalPages, setTotalPages] = useState(0);

  const navigate = useNavigate();
  const { loading, gatAllHouseHolds, householdDownload } = useHousehold();
  const { getAllStates } = useResource();
  const { title } = useTabTitle({ title: "Households" });

  const limit = 25;

  const getAllHousehold = useCallback(
    async (newQuery, selState, selLga) => {
      const queryData = query || newQuery;
      const newState = selectedState?.value || selState?.value;
      const newlga = selectedLga?.value || selLga?.value;
      const res = await gatAllHouseHolds({
        page: page,
        size: limit,
        query: queryData,
        selectedState: newState,
        lgaId: newlga,
        retailerId: "",
        communityId: "",
        fieldOfficerId: "",
      });
      setHouseholds(res);
      setTotalPages(res?.data?.totalPages);
    },
    [page, query, selectedLga, selectedState]
  );
  useEffect(() => {
    getAllHousehold();
  }, [getAllHousehold, page]);

  useEffect(() => {
    const getAllState = async () => {
      const res = await getAllStates();
      const list = res.data.map((item) => {
        return {
          label: item.name,
          value: item.id,
        };
      });
      setAllstates([...list]);
    };
    getAllState();
  }, []);

  useEffect(() => {
    if (selectedStateId) {
      const getAllLga = async () => {
        const res = await getLgaByState(selectedStateId);
        const list = res?.data?.map((item) => {
          return {
            label: item.name,
            value: item.id,
          };
        });
        if (list) {
          setAllLgas([...list]);
        }
      };
      getAllLga();
    }
  }, [selectedStateId]);

  const handlePageChange = (data) => {
    if (data === 0) {
      setPage(1);
    }
    setPage(data + 1);
  };

  const reset = () => {
    setQuery("");
    setSelectedLga(null);
    setSelectedState(null);
    setSelectedStateId();
    getAllHousehold();
  };

  const nextPage = () => {
    // if (page === totalPages) {
    //   setPage(page);
    // }
    if (page < totalPages) {
      setPage((prev) => (page >= 1 && page !== totalPages ? prev + 1 : prev));
    }
    getAllHousehold(query, selectedState, selectedLga);
  };
  const prevPage = () => {
    if (page === totalPages) {
      setPage(1);
    }
    if (page !== 0) {
      setPage((prev) => (page >= 1 || page === totalPages ? prev - 1 : prev));
    }
    getAllHousehold(query, selectedState, selectedLga);
  };
  return (
    <div className="p-4">
      <div className="rounded-[8px] border border-gray-300">
        <div className="border-b border-gray-300">
          <TableTopCard
            title={"Households"}
            subtitle={"keep track of households"}
            buttonTitle={"Export"}
            className={"bg-white text-black border border-gray-300"}
            icon={AiOutlineExport}
            setShowModal={() =>
              householdDownload({
                nameOrPhoneNumber: query,
                stateId: selectedState?.value,
                lgaId: selectedLga?.value,
                communityId: "",
                fieldOfficerId: "",
              })
            }
          />
        </div>
        <div className="flex flex-col justify-between items-center md:flex-row lg:flex-row relative h-max p-[10px]">
          <InputSearch
            placeholder={"search by name, email or phone number"}
            css={"w-[481px] h-[44px] "}
            setInputValue={setQuery}
          />
          <div className="flex gap-[12px] items-center">
            {/* <div className="text-green-900">Clear filter</div> */}
            <div
              className="w-[83px] h-[44px] flex items-center text-green-900 border border-green-900 gap-[8px] p-[12px] rounded-[8px] cursor-pointer"
              onClick={() => setShowFilter((prev) => !prev)}
            >
              <FaSliders /> <span>filter</span>
            </div>
          </div>
          {showFilter ? (
            <div className="p-[14px] flex flex-col gap-[8px] absolute top-[60px] right-[5px] w-[358px] height-max bg-white rounded-[8px] z-50">
              <div className="flex justify-between items-center">
                <h2 className="text-xl font-semibold">Filters</h2>
                <button
                  className="p-[12px] rounded-[8px] text-green-900"
                  onClick={() => setShowFilter(false)}
                >
                  Close
                </button>
              </div>
              <p>Location</p>
              <div className="flex flex-wrap gap-[12px]">
                <SearchableDropdown
                  options={allStates}
                  selectedOption={selectedState}
                  // defaultValue={null}
                  placeholder="state"
                  className={"w-[159px]"}
                  handleChange={(e) => {
                    setSelectedStateId(e?.value);
                    setSelectedState(e);
                  }}
                />
                <SearchableDropdown
                  options={allLgas}
                  selectedOption={selectedLga}
                  // defaultValue={null}
                  placeholder="lga"
                  className={"w-[159px]"}
                  handleChange={(e) => {
                    setSelectedLga(e);
                  }}
                />
              </div>
              <div className="flex pt-[16px] gap-[16px]">
                <button
                  className="w-[157px] border border-gray-300 text-center rounded-[8px] h-[44px]"
                  onClick={() => reset()}
                >
                  Clear Filter
                </button>
                <button
                  className="w-[157px] text-center bg-green-900 text-white rounded-[8px] h-[44px]"
                  onClick={() => {
                    getAllHousehold(query, selectedState, selectedLga);
                  }}
                >
                  Apply
                </button>
              </div>
            </div>
          ) : (
            ""
          )}
        </div>
        {/* <div className="flex flex-col justify-between md:flex-row lg:flex-row ">
          <div className="py-[12px] px-[16px] flex gap-[16px]">
            <InputSearch
              placeholder={"search by name, email or phone number"}
              css={"w-[481px] h-[44px] "}
            />
            <SearchableDropdown
              options={[]}
              selectedOption={""}
              defaultValue={null}
              placeholder="state"
              handleChange={(e) => {}}
            />
            <SearchableDropdown
              options={[]}
              selectedOption={""}
              defaultValue={null}
              placeholder="lga"
              handleChange={(value) => {}}
            />
          </div>
        </div> */}

        {loading ? (
          <div className="flex justify-center">
            <p className="text-center">Loading...</p>
          </div>
        ) : households?.data?.content?.length === 0 ? (
          <div className="flex justify-center">
            <p className="text-center">No data.</p>
          </div>
        ) : (
          <CustomTable
            headers={headers}
            rows={households?.data?.content?.map((data, index) => {
              return {
                household: data.household ? data.household : "N/A",
                retailer: data.retailer ? data.retailer : "N/A",
                // fieldofficer: data.fieldOfficer ? data.fieldOfficer : "N/A",
                phonenumber: data.phoneNumber ? data.phoneNumber : "N/A",
                state: data.state ? data.state : "N/A",
                lga: data.localGovernment ? data.localGovernment : "N/A",
                "date & time": data.createdAt ? data.createdAt : "N/A",
                "settings button": (
                  <div className="relative">
                    <HiOutlineDotsVertical
                      onClick={() => {
                        setShowMore((prev) => !prev);
                        setActiveHouseholdIndex(index);
                      }}
                    />
                    {showMore && index === activeHouseholdIndex ? (
                      <div className="absolute bottom-[15px] right-[25px] bg-white border border-gray-300 h-max w-max p-[8px] z-50">
                        <button
                          onClick={() => navigate(`/households/${data.id}`)}
                        >
                          view more
                        </button>
                      </div>
                    ) : (
                      ""
                    )}
                  </div>
                ),
              };
            })}
          />
        )}
      </div>

      <div className="pt-[12px] pb-[16px] px-[24px] flex justify-end">
        <PaginationTab
          pageIndex={page}
          pageCount={households?.data?.totalPages}
          gotoPage={handlePageChange}
          // pageSize={10}
          canPreviousPage={""}
          canNextPage={""}
          handleNext={() => nextPage()}
          handlePrevious={() => prevPage()}
          // pageOptions,
          setPageSize={""}
          // useAlternatePagination={false}
        />
      </div>
    </div>
  );
};

export default HouseHolds;
