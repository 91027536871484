import api from "../api/axios";
import fetcher from "../api/fetacher";

export const createCommunity = async (data) => {
  try {
    const accessToken = localStorage.getItem("accessToken");

    const res = await fetcher("/community", {
      method: "POST",
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
      body: JSON.stringify(data),
      cache: "no-store",
    });
    return res;
  } catch (error) {
    throw error;
  }
};

export const updateCommunity = async (data) => {
  try {
    const res = await fetcher("/community/update", {
      method: "POST",
      body: JSON.stringify(data),
      cache: "no-store",
    });
    return res;
  } catch (error) {
    throw error;
  }
};
export const getAllCommunity = async ({
  page,
  size,
  nameOrEmailOrPhoneNumber,
  state,
  location,
}) => {
  try {
    const accessToken = localStorage.getItem("accessToken");
    const params = {
      page,
      size,
    };
    if (nameOrEmailOrPhoneNumber) {
      params.name = nameOrEmailOrPhoneNumber;
    }
    if (!!state && state !== "Filter by state") {
      params.stateId = state;
    }
    if (!!location && location !== "Filter by lga") {
      params.lgaId = location;
    }
    const res = await fetcher(
      "/community",
      {
        method: "GET",
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      },
      params,
      true
    );
    return res;
  } catch (error) {
    throw error;
  }
};
export const getCommunityByLga = async (id) => {
  try {
    const accessToken = localStorage.getItem("accessToken");
    const res = await fetcher(`/community/lga/${id}`, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });
    return res;
  } catch (error) {
    throw error;
  }
};

export const downloadCommunity = async ({
  program,
  query,
  state,
  location,
  aggregatorId,
  collectorId,
}) => {
  try {
    const accessToken = localStorage.getItem("accessToken");
    const params = {
      programId: program,
    };
    if (state !== "All States") {
      params.state = state;
    }
    if (!!location && location !== "All LGAs") {
      params.location = location;
    }
    if (aggregatorId !== "All Aggregators") {
      params.aggregatorId = aggregatorId;
    }

    if (collectorId !== "All Collectors") {
      params.collectorId = collectorId;
    }
    const res = await fetcher(
      "/collector/download",
      {
        method: "GET",
        // responseType: "blob",
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      },
      params,
      true
    );

    return res;
  } catch (error) {
    throw error;
  }
};
