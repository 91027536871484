import React, { useEffect, useRef, useState } from "react";
import CustomTable from "../../components/table/CustomTable";
import SearchableDropdown from "../../components/input/SearchableDropdown";
import InputSearch from "../../components/input/InputSearch";
import TableTopCard from "../../components/card/TableTopCard";
import PaginationTab from "../../components/pagination/PaginationTab";
import useHousehold from "../../hooks/useHousehold";
import useFieldOfficer from "../../hooks/useFieldOfficer";
import { useNavigate } from "react-router-dom";
import { HiOutlineDotsVertical } from "react-icons/hi";
import SignupRequestModal from "../../components/modals/SignupRequestModal";
import Modal from "../../components/Modal";
import useOutsideClick from "../../hooks/useOutsideClick";
import { IoCloseOutline } from "react-icons/io5";

const headers = [
  "Officer",
  "Email",
  "Phone Number",
  "State",
  "No of LGAs",
  "Date & Time",
];
const ApprovalRequest = () => {
  const wrapperRef = useRef(null);
  const [showProfile, setShowProfile] = useOutsideClick(wrapperRef);
  const [showApprove, setShowApprove] = useOutsideClick(wrapperRef);
  const [showApprovalModal, setShowApprovalModal] = useOutsideClick(wrapperRef);
  const [declineModal, setDeclineModal] = useOutsideClick(wrapperRef);
  const [page, setPage] = useState(1);
  const [pendingFo, setPendingFo] = useState([]);
  const [acttiveFoIndex, setActiveFoIndex] = useState();
  const [acttiveFoId, setActiveFoId] = useState();
  const [acttiveFoDetail, setActiveFoDetail] = useState({});
  const [showMore, setShowMore] = useState(false);
  const { loading, gatAllPendingFo, activateFO, getFoDetails } =
    useFieldOfficer();
  const navigate = useNavigate();

  const getAllPendingFo = async () => {
    const res = await gatAllPendingFo();
    setPendingFo(res);
  };
  const getFoDetail = async () => {
    const res = await getFoDetails(acttiveFoId);
    setActiveFoDetail(res?.data);
  };

  useEffect(() => {
    getAllPendingFo();
  }, []);
  useEffect(() => {
    getFoDetail();
  }, [acttiveFoId]);

  const handlePageChange = (id) => {
    setShowApprove(true);
    setShowProfile(false);
  };
  const handleApproval = () => {
    activateFO(acttiveFoDetail?.id);
    getAllPendingFo();
    setShowApprovalModal(false);
  };
  const declineApproval = () => {
    setDeclineModal(true);
    setShowProfile(false);
  };
  const approve = () => {
    setShowApprovalModal(true);
    setShowProfile(false);
  };
  const decline = () => {
    setDeclineModal(true);
    setShowProfile(false);
  };
  return (
    <div className="p-4">
      <div className="rounded-[8px] border border-gray-300">
        <div className="border-b border-gray-300">
          <TableTopCard
            title={"Sign up requests"}
            subtitle={"View sign-up requests and take action"}
            // buttonTitle={"Export"}
            className={"bg-white text-black border border-gray-300"}
            // icon={AiOutlineExport}
          />
        </div>
        <div className="flex flex-col justify-between md:flex-row lg:flex-row ">
          <div className="py-[12px] px-[16px] flex gap-[16px]">
            <InputSearch
              placeholder={"search by name, email or phone number"}
              css={"w-[481px] h-[44px] "}
            />
            <SearchableDropdown
              options={[]}
              selectedOption={""}
              defaultValue={null}
              placeholder="state"
              handleChange={(e) => {}}
            />
            <SearchableDropdown
              options={[]}
              selectedOption={""}
              defaultValue={null}
              placeholder="lga"
              handleChange={(value) => {}}
            />
          </div>
        </div>

        {loading ? (
          <div className="flex justify-center">
            <p className="text-center">Loading...</p>
          </div>
        ) : pendingFo?.data?.content?.length === 0 ? (
          <div className="flex justify-center">
            <p className="text-center">No data.</p>
          </div>
        ) : (
          <CustomTable
            headers={headers}
            rows={pendingFo?.data?.content?.map((data, index) => {
              return {
                officer: data.officer ? data.officer : "N/A",
                email: data.email ? data.email : "N/A",
                phoneNumber: data.phoneNumber ? data.phoneNumber : "N/A",
                state: data.state ? data.state : "N/A",
                "no of lga's": data.noOfLga ? data.noOfLga : "N/A",
                "date & time": data.createdAt ? data.createdAt : "N/A",
                "settings button": (
                  <div className="relative">
                    <HiOutlineDotsVertical
                      onClick={() => {
                        setShowMore((prev) => !prev);
                        setActiveFoIndex(index);
                      }}
                    />
                    {showMore && index === acttiveFoIndex ? (
                      <div className="absolute bottom-[15px] right-[25px] bg-white border border-gray-300 h-max w-max p-[8px] z-50">
                        <button
                          onClick={() => {
                            setShowProfile(true);
                            setActiveFoId(data?.id);
                            setShowMore(false);
                          }}
                        >
                          view more
                        </button>
                      </div>
                    ) : (
                      ""
                    )}
                  </div>
                ),
              };
            })}
          />
        )}
      </div>

      <div className="pt-[12px] pb-[16px] px-[24px] flex justify-end">
        <PaginationTab
          pageIndex={page}
          pageCount={pendingFo?.data?.totalPages}
          gotoPage={handlePageChange}
          // pageSize={10}
          canPreviousPage={""}
          canNextPage={""}
          // pageOptions,
          setPageSize={""}
          // useAlternatePagination={false}
        />
      </div>
      {showProfile && (
        <Modal
          variant="default"
          refProp={wrapperRef}
          closeModal={() => setShowProfile(false)}
        >
          <SignupRequestModal
            closeModal={() => setShowProfile(false)}
            useerData={acttiveFoDetail}
            handleApproval={() => approve()}
            declineApproval={() => decline(true)}
          />{" "}
        </Modal>
      )}
      {showApprovalModal && (
        <Modal
          variant="default"
          refProp={wrapperRef}
          closeModal={() => setShowApprovalModal(false)}
        >
          <div className="bg-white w-[486px] overflow-auto">
            {" "}
            <div className="flex justify-between pb-[24px] px-[24px] pt-[16px]">
              <div className="">
                <h1 className="capitalize font-bold text-sm">
                  Approve Sign-up Request
                </h1>
              </div>
              <IoCloseOutline
                style={{ width: 26, height: 26, cursor: "pointer" }}
                onClick={() => setShowApprovalModal(false)}
              />
            </div>
            <div className="p-[24px]">
              <h1 className="text-center font-semibold">
                Are you sure you want to approve this request?
              </h1>
              <p className="text-center text-sm">
                The potential user{" "}
                <span className="text-green-400">
                  "{acttiveFoDetail?.firstName} {acttiveFoDetail.lastName}"
                </span>{" "}
                will be granted access to this platform
              </p>
            </div>
            <div className="pt-[12px] px-[24px] pb-[24px] flex gap-[12px]">
              <button
                className="bg-white w-[261px] h-[48px] rounded-md border border-gray-300"
                onClick={() => setShowApprovalModal(false)}
              >
                Cancel
              </button>
              <button
                className=" bg-green-900 text-white w-[261px] h-[48px] rounded-md"
                onClick={() => handleApproval()}
              >
                Approve
              </button>
            </div>
          </div>
        </Modal>
      )}
      {declineModal && (
        <Modal
          variant="default"
          refProp={wrapperRef}
          closeModal={() => setDeclineModal(false)}
        >
          <div className="bg-white w-[486px] overflow-auto">
            {" "}
            <div className="flex justify-between pb-[24px] px-[24px] pt-[16px]">
              <div className="">
                <h1 className="capitalize font-bold text-sm">
                  Decline Sign-up Request
                </h1>
              </div>
              <IoCloseOutline
                style={{ width: 26, height: 26, cursor: "pointer" }}
                onClick={() => setDeclineModal(false)}
              />
            </div>
            <div className="p-[24px]">
              <h1 className="text-center font-semibold">
                Are you sure you want to decline this request?
              </h1>
              <p className="text-center text-sm">
                The potential user{" "}
                <span className="text-green-400">
                  {" "}
                  "{acttiveFoDetail?.firstName} {acttiveFoDetail.lastName}"
                </span>{" "}
                will not be granted access to this platform
              </p>
            </div>
            <div className="pt-[12px] px-[24px] pb-[24px] flex gap-[12px]">
              <button
                className="bg-white w-[261px] h-[48px] rounded-md border border-gray-300"
                onClick={() => setDeclineModal(false)}
              >
                Cancel
              </button>
              <button
                className="bg-[#FEF3F2] text-[#F04438] w-[261px] h-[48px] rounded-md"
                onClick={() => declineApproval(acttiveFoDetail?.id)}
              >
                Decline
              </button>
            </div>
          </div>
        </Modal>
      )}
    </div>
  );
};

export default ApprovalRequest;
