import React, { useEffect, useRef, useState } from "react";
import CustomTable from "../../components/table/CustomTable";
import InputSearch from "../../components/input/InputSearch";
import TableTopCard from "../../components/card/TableTopCard";
import TopCard from "../../components/card/TopCard";
import useOutsideClick from "../../hooks/useOutsideClick";
import Modal from "../../components/Modal";
import { AdminModal } from "../../components/modals/AdminModal";
import { CommunityModal } from "../../components/modals/CommunityModal";
import useCommunity from "../../hooks/useCommunity";
import { HiOutlineDotsVertical } from "react-icons/hi";
import useResource from "../../hooks/useResource";
import PaginationTab from "../../components/pagination/PaginationTab";
import SearchableDropdown from "../../components/input/SearchableDropdown";
import { FaSliders } from "react-icons/fa6";
import useTabTitle from "../../hooks/useTabTitle";

const headers = ["Community", "Admin", "State", "Lga", "Date & Time"];
const Community = () => {
  const wrapperRef = useRef(null);
  const [showModal, setShowModal] = useOutsideClick(wrapperRef);
  const [editModal, setEditModal] = useOutsideClick(wrapperRef);
  const [communities, setCommunities] = useState();
  const [communityDetail, setCommunityDetail] = useState();
  const [showMore, setShowMore] = useState(false);
  const [showFilter, setShowFilter] = useState(false);
  const [activeCommunityIndex, setActiveCommunityIndex] = useState();
  const [states, setStates] = useState();
  const [lgas, setLgas] = useState();
  const [page, setPage] = useState(1);
  const [query, setQuery] = useState("");
  const [totalPages, setTotalPages] = useState(0);

  const limit = 25;

  const { loading, gatAllCommunities } = useCommunity();
  const { getAllStates, getAllLgas } = useResource();
  const { title } = useTabTitle({ title: "Community" });

  const getAllCommunity = async () => {
    const res = await gatAllCommunities(page, limit, query);
    setCommunities(res);
  };
  const getAllState = async () => {
    const res = await getAllStates();
    const newData = res.data.map((data) => {
      return {
        label: data.name,
        value: data.id,
      };
    });
    setStates(newData);
  };
  const getAllLga = async () => {
    const res = await getAllLgas();
    const newData = res?.data?.map((data) => {
      return {
        label: data.name,
        value: data.id,
      };
    });
    setLgas(newData);
  };
  useEffect(() => {
    getAllCommunity();
    getAllState();
    getAllLga();
  }, [query]);
  const handlePageChange = (data) => {
    if (data === 0) {
      setPage(1);
    }
    setPage((prev) => (prev === totalPages ? prev : data + 1));
  };

  const nextPage = () => {
    // if (page === totalPages) {
    //   setPage(page);
    // }
    if (page < totalPages) {
      setPage((prev) => (page >= 1 && page !== totalPages ? prev + 1 : prev));
    }
    getAllCommunity(query);
  };
  const prevPage = () => {
    if (page === totalPages) {
      setPage(1);
    }
    if (page !== 0) {
      setPage((prev) => (page >= 1 || page === totalPages ? prev - 1 : prev));
    }
    getAllCommunity(query);
  };
  return (
    <div className="p-4">
      <div className="mb-10">
        <TopCard
          title={"Total Communities"}
          subtitle={"Communities"}
          userCount={
            communities?.data?.totalElements
              ? communities?.data?.totalElements
              : "0"
          }
        />
      </div>
      <div className="rounded-[8px] border border-gray-300">
        <div className="border-b border-gray-300">
          <TableTopCard
            title={"Community"}
            subtitle={"keep track of all communities"}
            buttonTitle={"Create Community"}
            className={"bg-green-900 text-white w-max"}
            setShowModal={() => setShowModal(true)}
          />
        </div>
        <div className="flex flex-col justify-between items-center md:flex-row lg:flex-row relative h-max p-[10px]">
          <InputSearch
            placeholder={"search by name, email or phone number"}
            css={"w-[481px] h-[44px] "}
            setInputValue={setQuery}
          />
          <div className="flex gap-[12px] items-center">
            {/* <div className="text-green-900">Clear filter</div> */}
            <div
              className="w-[83px] h-[44px] flex items-center text-green-900 border border-green-900 gap-[8px] p-[12px] rounded-[8px] cursor-pointer"
              onClick={() => setShowFilter((prev) => !prev)}
            >
              <FaSliders /> <span>filter</span>
            </div>
          </div>
          {showFilter ? (
            <div className="p-[14px] flex flex-col gap-[8px] absolute top-[60px] right-[5px] w-[358px] height-max bg-white rounded-[8px] z-50">
              <div className="flex justify-between items-center">
                <h2 className="text-xl font-semibold">Filters</h2>
                <button
                  className="p-[12px] rounded-[8px] text-green-900"
                  onClick={() => setShowFilter(false)}
                >
                  close
                </button>
              </div>
              <p>Location</p>
              <div className="flex flex-wrap gap-[12px]">
                <SearchableDropdown
                  options={[]}
                  selectedOption={""}
                  defaultValue={null}
                  placeholder="state"
                  className={"w-[159px]"}
                  handleChange={(e) => {}}
                />
                <SearchableDropdown
                  options={[]}
                  selectedOption={""}
                  defaultValue={null}
                  placeholder="lga"
                  className={"w-[159px]"}
                  handleChange={(value) => {}}
                />
              </div>
              <div className="flex pt-[16px] gap-[16px]">
                <button className="w-[157px] border border-gray-300 text-center rounded-[8px] h-[44px]">
                  Clear Filter
                </button>
                <button className="w-[157px] text-center bg-green-900 text-white rounded-[8px] h-[44px]">
                  Apply
                </button>
              </div>
            </div>
          ) : (
            ""
          )}
        </div>
        {/* <div className="flex flex-col justify-between md:flex-row lg:flex-row">
          <div className="py-[12px] px-[16px]">
            <InputSearch
              placeholder={"search by name, email or phone number"}
              css={"w-[481px] h-[44px]"}
            />
          </div>
        </div> */}

        {loading ? (
          <div className="flex justify-center">
            <p className="text-center">Loading...</p>
          </div>
        ) : communities?.data?.content?.length === 0 ? (
          <div className="flex justify-center">
            <p className="text-center">No data.</p>
          </div>
        ) : (
          <CustomTable
            headers={headers}
            rows={communities?.data?.content?.map((data, index) => {
              return {
                community: data.name ? data.name : "",
                admin: data.admin ? data.admin : "",
                state: data.state ? data.state : "",
                lga: data.localGovernment ? data.localGovernment : "",
                "date & time": data.createdAt ? data.createdAt : "",
                "settings button": (
                  <div className="relative">
                    <HiOutlineDotsVertical
                      onClick={() => {
                        setShowMore((prev) => !prev);
                        setActiveCommunityIndex(index);
                      }}
                    />
                    {showMore && index === activeCommunityIndex ? (
                      <div className="absolute bottom-[15px] right-[25px] bg-white border border-gray-300 h-max w-max p-[8px] z-50">
                        <button
                          onClick={() => {
                            setEditModal(true);
                            setCommunityDetail(data);
                            setShowMore(false);
                          }}
                        >
                          view more
                        </button>
                      </div>
                    ) : (
                      ""
                    )}
                  </div>
                ),
              };
            })}
          />
        )}
      </div>
      <div className="pt-[12px] pb-[16px] px-[24px] flex justify-end">
        <PaginationTab
          pageIndex={page}
          pageCount={communities?.data?.totalPages}
          gotoPage={handlePageChange}
          // pageSize={10}
          canPreviousPage={""}
          canNextPage={""}
          handleNext={() => nextPage()}
          handlePrevious={() => prevPage()}
          // pageOptions,
          setPageSize={""}
          // useAlternatePagination={false}
        />
      </div>
      {showModal && (
        <Modal
          variant="default"
          refProp={wrapperRef}
          closeModal={() => setShowModal(false)}
        >
          <CommunityModal
            closeModal={() => setShowModal(false)}
            states={states}
            getAllCommunity={getAllCommunity}
            lgas={lgas}
          />{" "}
        </Modal>
      )}
      {editModal && (
        <Modal
          variant="default"
          refProp={wrapperRef}
          closeModal={() => setEditModal(false)}
        >
          <CommunityModal
            closeModal={() => setEditModal(false)}
            states={states}
            getAllCommunity={getAllCommunity}
            lgas={lgas}
            useerData={communityDetail}
            requestType={"edit"}
          />{" "}
        </Modal>
      )}
    </div>
  );
};

export default Community;
